// OrderContext.jsx
import React, { createContext, useState, useContext } from 'react';

const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);
  const [orderPlaced, setOrderPlaced] = useState(false);

  const handleOrderView = () => {
    setOrderPlaced(false);
  };

  const handlePlaceOrder = () => {
    setOrderPlaced(true);
  };

  return (
    <OrderContext.Provider value={{ orders, setOrders, orderPlaced, handleOrderView, handlePlaceOrder }}>
      {children}
    </OrderContext.Provider>
  );
};

export const useOrderContext = () => useContext(OrderContext);