import React, { useState } from 'react';
import Swal from 'sweetalert2';
import checked from '../../Assets/All pics/checked_169780 1.png'
const CleaningService = ({ service, onSave }) => {
    const [selectedHour, setSelectedHour] = useState('');
   
  
    const handleSave = () => {
      // Show confirmation prompt
      Swal.fire({
        title: 'Confirm Selection',
        text: `Are you sure you want to select ${selectedHour}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, Confirm',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button'
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          // Proceed with the selection
          onSave(service.id, selectedHour);
  
          // Show success message
          Swal.fire({
            title: 'Selection Confirmed',
            html: `<div>Your selection of ${selectedHour} has been confirmed!</div>`,
            confirmButtonText: 'Close',
            iconHtml: `<img src="${checked}" style="width: 60px; height: 60px; border: none; box-shadow: none; border-radius: 0;"/>`,
            customClass: {
              icon: 'custom-icon',
              confirmButton: 'custom-confirm-button'
            },
            buttonsStyling: false,
          }).then(() => {
            setSelectedHour('');
          });
        }
      });
    };
  
    const isDisabledOptionSelected = service.hours.some(hour => hour.value === selectedHour && hour.disabled);
    const isButtonDisabled = selectedHour === '' || isDisabledOptionSelected;

    return (
      <div className='lan' >
        <h3 className='title-box'>{service.title}</h3>
        <div className='lan'>
          <div className='laundry'>
        <select value={selectedHour} onChange={(e) => setSelectedHour(e.target.value)} className='bed-con'>
          
          {service.hours.map((hour, index) => (
            <option key={index} value={hour.value}  disabled={hour.disabled}>{hour.label}</option>
          ))}
        </select>
      

      </div>
      </div>
        <button
  className={`bed-button ${isButtonDisabled ? '' : 'active'}`}
  disabled={isButtonDisabled}
  onClick={handleSave}
>
  Place Order
</button>
      </div>
    );
  };
  
export default CleaningService;