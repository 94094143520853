import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Order.css';
import { FaRegQuestionCircle } from "react-icons/fa";
import Arrow from '../ArrowB/Arrow';
import { IoClose } from "react-icons/io5";
import Overlay from './Overlay';
import Swal from 'sweetalert2';
import ContentLoader from "react-content-loader";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [isOverlayOpen, setOverlayOpen] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [startY, setStartY] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const overlayRef = useRef(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [page, setPage] = useState(0);
  const [size] = useState(50); // Количество заказов на странице
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Проверка на наличие новых данных
  const observer = useRef();
  const statusColors = {
    Pending: '#CC6C17',
    Preparing: '#233D7C',
    Approve: '#949F13',
    Ready: '#1A7242',
    Declined: '#973647'
  };

  const fetchOrders = useCallback(async (page) => {
    const token = localStorage.getItem('guestToken');
    const hotelId = localStorage.getItem('hotelId');
    const guestId = localStorage.getItem('id');

    try {
      setLoading(true);
      const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/orders/list`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'hotelId': hotelId,
          'guestId': guestId
        },
        params: {
          tag: 'guest', // Замените на нужный тег
          page: page,
          size: size
        }
      });
      if (response.status === 200) {
        if (response.data.length < size) {
          setHasMore(false); // Если количество заказов меньше размера страницы, значит данных больше нет
        }
        setOrders(prevOrders => page === 0 ? response.data : [...prevOrders, ...response.data]);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setLoading(false);
      Swal.fire('Error!', 'There was an error fetching your orders.', 'error');
    }
  }, [size]);

  const handleTouchStart = (e) => {
    e.preventDefault(); // Prevent default scrolling
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    e.preventDefault(); // Prevent default scrolling
    const currentY = e.touches[0].clientY;
    const deltaY = currentY - startY;
    if (deltaY > 0) {
      setTranslateY(deltaY);
    }
  };
  const handleTouchEnd = () => {
    if (translateY > 100) {
      setOverlayOpen(false);
    }
    setTranslateY(0);
  };


  const toggleOverlay = (order) => {
    setSelectedOrder(order);
    setOverlayOpen(!isOverlayOpen);
    setTranslateY(0);
  };

  const handleIconClick = () => {
    setShowOverlay(prev => !prev);
    console.log('Overlay state:', showOverlay); // This should toggle between true and false
  };




  useEffect(() => {
    const handleClickOutside = (event) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setOverlayOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [overlayRef]);

  useEffect(() => {
    fetchOrders(page);
  }, [page, fetchOrders]);

  const lastOrderElementRef = useCallback(node => {
    if (loading) return;
    
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  if (loading && page === 0) { // Show shimmer only on initial load
    return (
      <div className="orders">
        <Link to="/home" style={{ textDecoration: 'none', color: 'var(--primaryColor)' }}>
          <Arrow />
        </Link>
        <div className='order-box'>
          <h2>Orders</h2>
          <FaRegQuestionCircle className="question-icon" onClick={handleIconClick} />
        </div>
        {[...Array(5)].map((_, index) => (
          <ContentLoader key={index} viewBox="0 0 380 70" height={70} width={380} backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
            <rect x="10" y="10" rx="8" ry="8" width="360" height="15" />
            <rect x="10" y="35" rx="4" ry="4" width="150" height="10" />
            <rect x="200" y="35" rx="4" ry="4" width="80" height="10" />
          </ContentLoader>
        ))}
        <div className='extra'></div> 
        {showOverlay && <Overlay setShowOverlay={setShowOverlay} />}
      </div>
    );
  }

  return (
    <div className="orders">
      <Link to="/home" style={{ textDecoration: 'none', color: 'var(--primaryColor)' }}>
        <Arrow />
      </Link>
      <div className='order-box'>
        <h2>Orders</h2>
        <FaRegQuestionCircle className="question-icon" onClick={handleIconClick} />
      </div>
      {orders.map((order, index) => (
        <div
          className='item-details'
          key={index}
          onClick={() => toggleOverlay(order)}
          ref={index === orders.length - 1 ? lastOrderElementRef : null}
        >
          <span className='item-title'>{order.title}</span>
          <div className='item-col'>
            <span className='item-quantity'>
              {order.totalQuantity} {order.totalQuantity > 1 ? 'items' : 'item'}
            </span>
            <span className='item-price'>
              ${isNaN(Number(order.totalAmount)) ? '0' : Number(order.totalAmount).toFixed(2)}
            </span>
          </div>
          <span
            className='item-status'
            style={{ color: statusColors[order.orderStatus] }}
          >
            {order.orderStatus}
          </span>
        </div>
      ))}

      {isOverlayOpen && selectedOrder && (
        <div className={`overlay-box ${isOverlayOpen ? 'visible' : ''}`} onClick={toggleOverlay}>
          <div
            ref={overlayRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            style={{ transform: `translateY(${translateY}px)` }}
            className={`overlay1 ${isOverlayOpen ? 'visible' : ''}`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className='ov-con'>
              <div className='line'></div>
              <button className="close-button" onClick={toggleOverlay}><IoClose /></button>
              <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
                <p className='date'>{formatDate(selectedOrder.created)}</p>
                <p className='location-type'>{selectedOrder.locationType}</p>
                <ul>
                  <h4>Order Summary</h4>
                  {selectedOrder.orderDetailsDto.map((item, index) => (
                    <li key={index}>
                      <span>{item.itemQuantity} x </span><span>{item.title} </span>
                      <span>
                        ${isNaN(item.amount) ? '0' : item.amount}
                      </span>
                    </li>
                  ))}
                  <hr />
                  <li>
                    Total
                    <span>
                      ${isNaN(Number(selectedOrder.totalAmount)) ? '0' : Number(selectedOrder.totalAmount).toFixed(2)}
                    </span>
                  </li>
                  <h4 className='ready' style={{ color: statusColors[selectedOrder.orderStatus] }}>
                    {selectedOrder.orderStatus} <FaRegQuestionCircle className="question-icon" onClick={handleIconClick} />
                  </h4>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='extra'></div>
      {showOverlay && <Overlay setShowOverlay={setShowOverlay} />}
    </div>
  );
};

export default Orders;
