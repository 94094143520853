import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IoSearchOutline } from 'react-icons/io5';

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPlaceholder, setCurrentPlaceholder] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(150);
  const [isTypingStopped, setIsTypingStopped] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const placeholders = ["Cappuccino", "Beef Stake"];

    let typingTimeout;

    const handleTouchOrScroll = () => {
      setScrolling(true);
      setCurrentPlaceholder('');
      setIsTypingStopped(true);
      clearTimeout(typingTimeout);
    };

    if (!isTypingStopped) {
      if (isDeleting) {
        typingTimeout = setTimeout(() => {
          setCurrentPlaceholder((prev) => prev.substring(0, prev.length - 1));
          setTypingSpeed(30);
        }, typingSpeed);
      } else {
        typingTimeout = setTimeout(() => {
          setCurrentPlaceholder((prev) => placeholders[loopNum].substring(0, prev.length + 1));
          setTypingSpeed(150);
        }, typingSpeed);
      }

      if (!isDeleting && currentPlaceholder === placeholders[loopNum]) {
        setTimeout(() => setIsDeleting(true), 500); // for deleting
      } else if (isDeleting && currentPlaceholder === '') {
        setIsDeleting(false);
        setLoopNum((prev) => {
          const nextLoopNum = (prev + 1) % placeholders.length;
          if (nextLoopNum === 0) {
            setIsTypingStopped(true);
            return prev;
          }
          return nextLoopNum;
        });
      }
    }

    document.addEventListener('touchstart', handleTouchOrScroll);
    document.addEventListener('scroll', handleTouchOrScroll);

    return () => {
      clearTimeout(typingTimeout);
      document.removeEventListener('touchstart', handleTouchOrScroll);
      document.removeEventListener('scroll', handleTouchOrScroll);
    };
  }, [currentPlaceholder, isDeleting, loopNum, typingSpeed, isTypingStopped]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className='center-container'>
      <Link to={`/search?q=${searchQuery}`} style={{ textDecoration: 'none', color: 'var(--blackColor)' }}>
        <div className='search-br'>
          <p><IoSearchOutline /></p>
          <input
            className='in-box'
            type="text"
            placeholder={isTypingStopped ? 'What would you like to eat?' : currentPlaceholder}
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </Link>
    </div>
  );
};

export default SearchBar;
