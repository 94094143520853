import food1 from '../Assets/menu/beef/be.jpg'
import food2 from '../Assets/menu/beef/be1.jpg'
import food3 from '../Assets/menu/beef/be2.jpg'
import food4 from '../Assets/menu/beef/be3.jpg'
import food5 from '../Assets/menu/beef/be4.jpg'
import food6 from '../Assets/menu/beef/be5.jpg'
import food7 from '../Assets/menu/Appetizer/app.jpg'
import food8 from '../Assets/menu/Appetizer/app1.jpg'
import food9 from '../Assets/menu/Appetizer/app2.jpg'
import food10 from '../Assets/menu/Appetizer/app3.jpg'
import food11 from '../Assets/menu/Appetizer/app4.jpg'
import food12 from '../Assets/menu/Appetizer/app5.jpg'
import food13 from '../Assets/menu/Appetizer/app6.jpg'
import food14 from '../Assets/menu/Appetizer/app7.jpg'
import food15 from '../Assets/menu/Appetizer/app8.jpg'
import food16 from '../Assets/menu/burger/burger.jpg'
import food17 from '../Assets/menu/Cocktail/co.jpg'
import food18 from '../Assets/menu/Cocktail/co1.jpg'
import food19 from '../Assets/menu/Cocktail/co2.jpg'
import food20 from '../Assets/menu/Cocktail/co3.jpg'
import food21 from '../Assets/menu/Cocktail/co4.jpg'
import food22 from '../Assets/menu/Cocktail/co5.jpg'
import food23 from '../Assets/menu/Cocktail/co6.jpg'
import food24 from '../Assets/menu/Coffee/coffee.jpg'
import food25 from '../Assets/menu/fruits/fr.jpg'
import food26 from '../Assets/menu/wine/w1.jpg'
import food27 from '../Assets/menu/wine/w2.jpg'
import food28 from '../Assets/menu/wine/w3.jpg'
import food29 from '../Assets/menu/wine/w4.jpg'
import food30 from '../Assets/menu/wine/w5.jpg'
import food31 from '../Assets/menu/wine/w6.jpg'
import food32 from '../Assets/menu/wine/w7.jpg'
import food33 from '../Assets/menu/wine/w8.jpg'
import food34 from '../Assets/menu/wine/w9.jpg'
import food35 from '../Assets/menu/wine/w10.jpg'
import food36 from '../Assets/menu/wine/w11.jpg'
import food37 from '../Assets/menu/Spagatti/sp1.jpg'
import food38 from '../Assets/menu/Spagatti/sp2.jpg'
import food39 from '../Assets/menu/Spagatti/sp3.jpg'
import food40 from '../Assets/menu/Spagatti/sp4.jpg'
import food41 from '../Assets/menu/Spagatti/sp5.jpg'
import food42 from '../Assets/menu/softdrink/s.jpg'
import food43 from '../Assets/menu/softdrink/s1.jpg'
import food44 from '../Assets/menu/softdrink/s2.jpg'
import food45 from '../Assets/menu/softdrink/s3.jpg'
import food46 from '../Assets/menu/More/mo9.jpg'
import food47 from '../Assets/menu/More/mo7.jpg'
import food48 from '../Assets/menu/More/mo6.jpg'
import food49 from '../Assets/menu/More/mo5.jpg'
import food50 from '../Assets/menu/More/mo4.jpg'
import food51 from '../Assets/menu/More/mo1.jpg'
import food52 from '../Assets/menu/Coffee/coffee.jpg'
import food53 from '../Assets/menu/Coffee/coffee1.jpg'
import food54 from '../Assets/menu/More/mo2.jpg'
import food55 from '../Assets/menu/More/mo22.jpg'
import food56 from '../Assets/menu/Spagatti/sp1.jpg'
import food57 from '../Assets/menu/Spagatti/sp2.jpg'
import food58 from '../Assets/menu/Spagatti/sp3.jpg'
import food59 from '../Assets/menu/Spagatti/sp4.jpg'
import food60 from '../Assets/menu/Spagatti/sp5.jpg'
import food61 from '../Assets/menu/Spagatti/sp2.jpg'
import food62 from '../Assets/menu/More/more.jpg'
import food63 from '../Assets/menu/Cocktail/co7.jpg'
import food64 from '../Assets/menu/Cocktail/co8.jpg'
import food65 from '../Assets/menu/fruits/fr1.jpg'
const Food=[
    {
        id: 1,
        name: "Beef Stake with potato",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        category: "beef",
        image: food1,
        price: 3.99,
        recom: 'Recommend',
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 2,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food2,
        category: "beef",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 3,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food3,
        category: "beef",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 4,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food4,
        category: "beef",
        price: 9.0,
        recom: 'Recommend',
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 5,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food5,
        category: "beef",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 6,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food6,
        category: "beef",
        price: 9.0,
        recom: 'Recommend',
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 7,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food7,
        category: "appetizer",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 8,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food8,
        category: "appetizer",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 9,
        name: "Chocolate",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food9,
        category: "appetizer",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 10,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food10,
        category: "appetizer",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 11,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food11,
        category: "appetizer",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 12,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food12,
        category: "appetizer",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 13,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food13,
        category: "appetizer",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 14,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food14,
        category: "appetizer",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },{
        id: 15,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food15,
        category: "appetizer",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 16,
        name: "Burger",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food16,
        category: "burger",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 17,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food17,
        category: "cocktail",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 18,
        name: "Pineapple cocktail",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food18,
        category: "cocktail",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 19,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food19,
        category: "cocktail",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 20,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food20,
        category: "cocktail",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 21,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food21,
        category: "cocktail",
        price: 9.0,
        recom: 'Recommend',
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 22,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food22,
        category: "cocktail",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 23,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food23,
        category: "cocktail",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 24,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food24,
        category: "coffee",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 25,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food25,
        category: "fruit",
        price: 9.0,
        recom: 'Recommend',
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 26,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food26,
        category: "alcohol",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 27,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food27,
        category: "alcohol",
        price: 9.0, availability:'availability',
        currency: 'USD',

      },
      {
        id: 28,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food28,
        category: "alcohol",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 29,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food29,
        category: "alcohol",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 30,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food30,
        category: "alcohol",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 31,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food31,
        category: "alcohol",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 32,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food32,
        category: "alcohol",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 33,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food33,
        category: "alcohol",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 34,
        name: "Alcohol",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food34,
        category: "alcohol",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 35,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food35,
        category: "alcohol",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 36,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food36,
        category: "alcohol",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 37,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food37,
        category: "spagatti",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 38,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food38,
        category: "spagatti",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 39,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food39,
        category: "spagatti",
        price: 9.0,
        recom: 'Recommend',
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 40,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food40,
        category: "spagatti",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 41,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food41,
        category: "spagatti",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 42,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food42,
        category: "softdrink",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 43,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food43,
        category: "softdrink",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 44,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food44,
        category: "softdrink",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 45,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food45,
        category: "softdrink",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 46,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food46,
        category: "more",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 47,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food47,
        category: "more",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 48,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food48,
        category: "more",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 49,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food49,
        category: "more",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 50,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food50,
        category: "more",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 51,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food51,
        category: "more",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 52,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food52,
        category: "coffee",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 53,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food53,
        category: "coffee",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 54,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food54,
        category: "more",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 55,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food55,
        category: "more",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 56,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food56,
        category: "spagatti",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 57,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food57,
        category: "spagatti",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 58,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food58,
        category: "spagatti",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 59,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food59,
        category: "spagatti",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 60,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food60,
        category: "spagatti",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 61,
        name: "Spagatti with beef",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food61,
        category: "spagatti",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 62,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food62,
        category: "more",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 63,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food63,
        category: "cocktail",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 64,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food64,
        category: "cocktail",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },
      {
        id: 65,
        name: "Beef Stake",
        description: " Premium cuts, expertly seasoned and grilled to perfection." ,
        image: food65,
        category: "fruit",
        price: 9.0,
        availability:'availability',
        currency: 'USD',
      },

]
export default Food
