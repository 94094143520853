import React, { useState, useEffect } from 'react';
import './Box.css';
import Arrow from '../ArrowB/Arrow';
import { Link } from 'react-router-dom';
import { FaStar } from "react-icons/fa";
import axios from 'axios';

const Box = () => {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    const fetchActivities = async () => {
      const hotelId = localStorage.getItem('hotelId');
      const token = localStorage.getItem('guestToken');

      if (hotelId && token) {
        try {
          const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/activity/list`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              hotelId: hotelId,
            },
            params: {
              tag: 'hotel',
              page: 0,
              size: 10
            }
          });

          setActivities(response.data);
        } catch (error) {
          console.error('Error fetching activities:', error);
        }
      }
    };

    fetchActivities();
  }, []);

  const categoriesOrder = [
    "Tours",
    "Dining",
    "Cruises",
    "Outdoor",
    "Sport",
    "Theme Park",
    "Museum",
    "Cutural Experience",
    "Others"
  ];

  const getMinPrice = (additionalOptions) => {
    const prices = [];
    if (additionalOptions?.price?.private) {
      prices.push(parseFloat(additionalOptions.price.private));
    }
    if (additionalOptions?.price?.pickup) {
      prices.push(parseFloat(additionalOptions.price.pickup));
    }
    return prices.length > 0 ? Math.min(...prices) : 'N/A';
  };

  const sortedActivities = activities.sort((a, b) => {
    const categoryAIndex = categoriesOrder.indexOf(a.category);
    const categoryBIndex = categoriesOrder.indexOf(b.category);
    return categoryAIndex - categoryBIndex;
  });

  const categories = [...new Set(sortedActivities.map(item => item.category))];

  return (
    <div className='box-con'>
      <Link to='/home' style={{ textDecoration: 'none', color: 'var(--primaryColor)' }}><Arrow /></Link>
      <div className='acti-container'>
        {categories.map(category => {
          const categoryActivities = sortedActivities.filter(item => item.category === category);
          return (
            <div key={category}>
              <h2 className='titlet'>{category}</h2>
              <div className='scr-main'>
                {categoryActivities.map((item) => (
                  <Link
                    to={`/activities/${item.id}`}
                    key={item.id}
                    className='scr-con'
                    style={{ textDecoration: 'none', color: 'var(--primaryColor)' }}
                    state={{ activity: item }}
                  >
                    <img src={item.images[0].url} alt="Activity" />
                    <div className='text-container1'>
                      <h2>{item.title}</h2>
                      <p className='des-acti'>{item.description}</p>
                      <p className='pr'>
                        Start From ${getMinPrice(item.additionalOptions)}
                      </p>
                      <p className='pr'><FaStar className='star' />{item.rating}</p>
                      <div className='tour-types'>
                        {item.additionalOptions?.price?.private && (
                          <span className='tour-type'>Private Tour</span>
                        )}
                        {item.additionalOptions?.price?.pickup && (
                          <span className='tour-type'>Join-In</span>
                        )}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Box;