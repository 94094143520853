import React from 'react';
import { IoClose } from "react-icons/io5";

const Overlay = ({ setShowOverlay }) => {
  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };

  return (
    <div className="overlay" onClick={handleCloseOverlay}>
      <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
        <div className="overlay-header">
          <button className="close-btn" onClick={handleCloseOverlay}><IoClose /></button>
        </div>
        <div className="overlay-body">
          <p><span style={{ color: '#CC6C17' }}>Pending</span> - waiting for confirmation of your order from the hotel staff</p>
          <p><span style={{ color: '#233D7C' }}>Preparing</span> - the order has been confirmed and is being prepared</p>
          <p><span style={{ color: '#949F13' }}>Approved</span> - the order has been confirmed by the hotel staff</p>
          <p><span style={{ color: '#1A7242' }}>Ready</span> - the order is completed</p>
          <p><span style={{ color: '#973647' }}>Declined</span> - we don't have this order item(s) at the moment</p>
        </div>
      </div>
    </div>
  );
};

export default Overlay;