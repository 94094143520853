import React, { useState} from 'react';
import { useCart } from './CartContext';
import Swal from 'sweetalert2';
import Arrow from '../ArrowB/Arrow.jsx';
import { Link, useLocation } from 'react-router-dom';
import './Cart.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import checked from '../Assets/All pics/checked_169780 1.png';
import { useOrderContext } from './OrderContext';
import axios from 'axios';

const Cart = () => {
  const token = localStorage.getItem('guestToken');
  const { cart, getTotalItems, handleQuantityChange, removeFromCart, setCount, getTotalAmount, clearCart } = useCart();
  const location = useLocation();
  const { handlePlaceOrderBox } = useOrderContext();
  const [deliveryOption, setDeliveryOption] = useState('Room Delivery'); 
  const cartId = localStorage.getItem('cartId');
  const { handlePlaceOrder: updateOrderPlaced } = useOrderContext();

  const handleOptionChange = (e) => {
    setDeliveryOption(e.target.value);
  };

  const handleIncrement = (id) => {
    handleQuantityChange(id, 1);
  };

  const handleDecrement = async (id) => {
    const item = cart.find((item) => item.foodDto.id === id);
    if (item) {
      const updatedQuantity = item.itemQuantity - 1;
      if (updatedQuantity <= 0) {
        try {
          await handleQuantityChange(id, -1); 
          removeFromCart(id); 
        } catch (error) {
          console.error('Error decrementing item:', error);
        }
      } else {
        setCount(updatedQuantity);
        handleQuantityChange(id, -1);
      }
    }
  };

  const handleClearCart = () => {
  
    if (!cartId) {
      Swal.fire('Error!', 'Cart ID not found. Please try adding items to your cart first.', 'error');
      return;
    }
  
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will remove all items from your cart!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, clear it!',
      cancelButtonText: 'No, keep it'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(process.env.REACT_APP_API_BASE_URL + `/cart/delete/${cartId}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          });
  
          if (response.status === 200) { // Check for 200 OK status
            clearCart(); // Assuming this function is defined in your CartContext
            localStorage.removeItem('cartId');
            Swal.fire('Cleared!', 'Your cart has been cleared.', 'success');
          } else if (response.status === 400) {
            Swal.fire('Error!', 'Cart not found.', 'error');
          } else {
            console.error('Error clearing cart:', response.status, response.statusText);
            Swal.fire('Error!', 'There was an error clearing your cart.', 'error');
          }
        } catch (error) {
          console.error('Error clearing cart:', error);
          Swal.fire('Error!', 'There was an error clearing your cart.', 'error');
        }
      }
    });
  };

  const handlePlaceOrder = async () => {
    const hotelId = localStorage.getItem('hotelId');
    const guestId = localStorage.getItem('id');

    if (!token || !hotelId || !guestId) {
      Swal.fire('Error!', 'Required information missing. Please try again.', 'error');
      return;
    }

    Swal.fire({
      title: 'Confirm Order',
      text: 'Are you sure you want to place the order?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Place Order',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button'
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const orderDetails = {
          totalAmount: getTotalAmount(),
          totalQuantity: getTotalItems(),
          orderStatus: 'Pending',
          title: 'Restaurant',
          locationType: deliveryOption,
          orderDetailsDto: cart.map(item => ({
            title: item.foodDto.title,
            itemQuantity: item.itemQuantity,
            amount: (item.itemQuantity * item.foodDto.price).toFixed(2),
          })),
        };

        try {
          const response = await axios.post(process.env.REACT_APP_API_BASE_URL + `/order/place-food`, orderDetails, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'hotelId': hotelId,
              'guestId': guestId,
            }
          });

          if (response.status === 200) {
            const responseDelete = await axios.delete(process.env.REACT_APP_API_BASE_URL + `/cart/delete/${cartId}`, {
              headers: {
                'Authorization': `Bearer ${token}`,
              }
            });
    
            if (responseDelete.status === 200) { // Check for 200 OK status
              clearCart(); // Assuming this function is defined in your CartContext
              updateOrderPlaced();
            Swal.fire({
              title: 'Order placed',
              html: `<div>You can track the status of your order in the orders section</div>`,
              confirmButtonText: 'Close',
              iconHtml: `<img src='${checked}' style="width: 60px; height: 60px; border: none; box-shadow: none; border-radius: 0;"/>`,
              customClass: {
                icon: 'custom-icon',
                confirmButton: 'custom-confirm-button'
              },
              buttonsStyling: false,
            });
          } else {
            console.error('Error placing order:', response.status, response.statusText);
            Swal.fire('Error!', 'There was an error placing your order.', 'error');
          }
            }
        } catch (error) {
          console.error('Error placing order:', error);
          Swal.fire('Error!', 'There was an error placing your order.', 'error');
        }
      }
    });
  };

  return (
    <div className='cart'>
      {location.pathname === '/cart' ? (
        <Link to="/basket" style={{ textDecoration: 'none', color: 'var(--primaryColor)' }}>
          <Arrow />
        </Link>
      ) : null}
      <div className='cart-container'>
        <div></div>
        {cart.length > 0 && (
          <div className='trash-btn'>
            <div className="delivery-container">
              <select id="deliverySelect" value={deliveryOption} onChange={handleOptionChange}>
                <option value="Dine In Restaurant">Dine In Restaurant</option>
                <option value="Room Delivery">Room Delivery</option>
              </select>
            </div>
            <div className='trash'>
              <FontAwesomeIcon icon={faTrashCan} onClick={handleClearCart}  className='trash-con'/>
              <p>Delete All</p>
            </div>
          </div>
        )}
        {cart.length === 0 ? (
          <div className='cart-box'>
            <h2>Your cart is empty <Link to="/appetizer"> Order Here</Link></h2>
          </div>
        ) : (
          <div className='all-cart'>
            {cart.map((item) => (
              <div key={item.id}>
                <div key={item.id} className="cart-item">
                  <img src={item.foodDto.imageLink || 'https://via.placeholder.com/150'} alt={item.foodDto.title} />
                  <div>
                    <p className='title'>{item.foodDto.title}</p>
                  </div>
                  <div className="cart-item-details">
                    <p className='price'>${(item.itemQuantity * item.foodDto.price).toFixed(2)}</p>
                  </div>
                  <div className="cart-item-quantity">
                    <button className='minus' onClick={() => handleDecrement(item.foodDto.id)}>-</button>
                    <p>{item.itemQuantity}</p>
                    <button onClick={() => handleIncrement(item.foodDto.id)}>+</button>
                  </div>
                </div>
                <hr></hr>
              </div>
            ))}
            <div className='cart-order'>
              <div className='cart-total'>
                <p className='cart-total-t'>Total</p>
                <p className='cart-total-p'>${getTotalAmount()}</p>
              </div>
              <button className='order' onClick={handlePlaceOrder}>Place Order</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
