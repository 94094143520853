import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const AddService = ({ onSubmit, onClose }) => {
  const [service, setService] = useState({
    id: uuidv4(),
    status: 'ON',
    title: '',
    category: '',
    description: '',
    type: '',
    image: null,
    newServiceType: '',
    input1: '',
    input2: '',
  });

  const handleServiceChange = (e) => {
    const { name, value } = e.target;
    setService((prevService) => ({
      ...prevService,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setService((prevService) => ({
      ...prevService,
      image: file,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(service);
  };

  return (
    <div className='main-room'>
      <div className="add-room-options">
        <div className='added-box'>
          <h3>Add New Service</h3>
          <button className="close" onClick={onClose}>×</button>
        </div>
        <div className="room-form edit">
        <form onSubmit={handleSubmit}>
        <div className="room-input-container cap1">
        <input
          type="text"
          name="title"
          value={service.title}
          onChange={handleServiceChange}
          required
          style={{ width: '100%' }}
        />
        <label className={service.title ? 'floating' : ''}>
          Title
        </label>
      </div>

          <div className="room-input-container cap1">
        <input
          type="text"
          name="category"
          value={service.category}
          onChange={handleServiceChange}
          required
          style={{ width: '100%' }}
        />
        <label className={service.category ? 'floating' : ''}>
          Category
        </label>
      </div>

          <div className="room-input-container cap1">
            <textarea
              style={{ width: '97%' }}
              name="description"
              value={service.description}
              onChange={handleServiceChange}
            ></textarea>
            <label className={service.description ? 'floating' : ''}>Description</label>
          </div>

          <div className="room-input-container ">
            <select
              name="status"
              value={service.status}
              onChange={handleServiceChange}
              required
            >
              <option value="ON">ON</option>
              <option value="OFF">OFF</option>
            </select>
            <label className="floating">Status</label>
          </div>
          <div className="room-input-container cap1">
        <input
          type="text"
          name="newServiceType"
          value={service.newServiceType}
          onChange={handleServiceChange}
          required
          style={{ width: '100%' }}
        />
        <label className={service.newServiceType ? 'floating' : ''}>
          New Service Type
        </label>
        </div>

          <div className="room-input-container cap1">
        <input
          type="text"
          name="input1"
          value={service.input1}
          onChange={handleServiceChange}
          required
          style={{ width: '100%' }}
        />
        <label className={service.input1 ? 'floating' : ''}>
          Input 1
        </label>
      </div>

          <div className="room-input-container cap1">
        <input
          type="text"
          name="input2"
          value={service.input2}
          onChange={handleServiceChange}
          required
          style={{ width: '100%' }}
        />
        <label className={service.input2 ? 'floating' : ''}>
          Input 2
        </label>
      </div>

          <div className="">
        {service.image && (
          <div className="itemcon">
            <img src={URL.createObjectURL(service.image)} alt="Current Photo" />
          </div>
        )}
        <label htmlFor="file-input-edit" className="file-input-label">+ Add Image</label>
        <input
          type="file"
          id='file-input-edit'
          style={{ display: 'none' }}
          onChange={handleImageChange}
          accept="image/*"
        />
      </div>

       
          <hr/>
          <div className="button-bar">
            <button type="submit" onClick={handleSubmit} className="save-btn">Save</button>
            <button type="button" onClick={onClose} className="delete-button">Cancel</button>
          </div>
        </form>
        </div>
      </div>
    </div>
  );
};

export default AddService;