// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bar-con h2{
  font-size: 28px;
  margin-top: 10px;
  background: var(--primaryColor);
  color: var(--whiteColor);
  width: 500px;
  text-align: center;
  border-radius: 50px;
}
.bar-con{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 0px;
  max-width: 600px;
}
.bar-con img{
  width: 140%;
  height: 180px;
  aspect-ratio: 1/1;
  object-fit: fill;
  border-radius: 4px;
}
/*.bara-con{
  overflow-y: auto;
  height: 890px;
  margin-top: 0px;
}
.barA{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 15px;
  max-width: 220px;
  margin: 10px 20px;
  font-size: 14px;
}
.barA title{
  font-size: 14px;
}*/
 @media (max-width:480px) {
  .bar-con h2{
    font-size: 18px;
    margin-top: 10px;
    background: linear-gradient(15deg, rgba(57, 172, 180, 0.4), rgba(0, 32, 154, 0.4));
    width: 295px;
    text-align: center;
    border-radius: 50px;
  }
  .bar-con{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 0px;
    max-width: 600px;
  }
  .bar-con img{
    width: 100%;
    height: 180px;
    aspect-ratio: 1/1;
    object-fit: contain;
    border-radius: 4px;
  }
  
}`, "",{"version":3,"sources":["webpack://./src/Component/Pages/BarA.css"],"names":[],"mappings":";AACA;EACE,eAAe;EACf,gBAAgB;EAChB,+BAA+B;EAC/B,wBAAwB;EACxB,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;AACpB;AACA;;;;;;;;;;;;;;;EAeE;CACD;EACC;IACE,eAAe;IACf,gBAAgB;IAChB,kFAAkF;IAClF,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;EACrB;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;EAClB;EACA;IACE,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;EACpB;;AAEF","sourcesContent":["\n.bar-con h2{\n  font-size: 28px;\n  margin-top: 10px;\n  background: var(--primaryColor);\n  color: var(--whiteColor);\n  width: 500px;\n  text-align: center;\n  border-radius: 50px;\n}\n.bar-con{\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  margin-top: 0px;\n  max-width: 600px;\n}\n.bar-con img{\n  width: 140%;\n  height: 180px;\n  aspect-ratio: 1/1;\n  object-fit: fill;\n  border-radius: 4px;\n}\n/*.bara-con{\n  overflow-y: auto;\n  height: 890px;\n  margin-top: 0px;\n}\n.barA{\n  display: grid;\n  grid-template-columns: repeat(3,1fr);\n  grid-gap: 15px;\n  max-width: 220px;\n  margin: 10px 20px;\n  font-size: 14px;\n}\n.barA title{\n  font-size: 14px;\n}*/\n @media (max-width:480px) {\n  .bar-con h2{\n    font-size: 18px;\n    margin-top: 10px;\n    background: linear-gradient(15deg, rgba(57, 172, 180, 0.4), rgba(0, 32, 154, 0.4));\n    width: 295px;\n    text-align: center;\n    border-radius: 50px;\n  }\n  .bar-con{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n    margin-top: 0px;\n    max-width: 600px;\n  }\n  .bar-con img{\n    width: 100%;\n    height: 180px;\n    aspect-ratio: 1/1;\n    object-fit: contain;\n    border-radius: 4px;\n  }\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
