import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import axios from 'axios';
import { IoSearchOutline } from "react-icons/io5";
import message from '../../All pics/message-square-lines-svgrepo-com (1).png';
import './AdminOrders.css';
import EditOrderModal from './EditOrderModal';
import { AuthContext } from '../../../Assets/MainAdminLAyout/Authorize/AuthContext';
import { useOrders } from './AdminOrderContext';
import { debounce } from 'lodash';

const fetchOrders = async (hotelId, page, size) => {
    const token = localStorage.getItem('hotelToken');
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    if (!token || !hotelId) {
        throw new Error('No token or hotelId found');
    }

    try {
        const response = await axios.get(`${baseUrl}/orders/list`, {
            headers: {
                Authorization: `Bearer ${token}`,
                hotelId: hotelId
            },
            params: {
                tag: 'hotel',
                page: page,
                size: size,
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching orders', error);
        throw error;
    }
};

const searchOrders = async (hotelId, query, page = 0, size = 50) => {
    const token = localStorage.getItem('hotelToken');
    const guestId = localStorage.getItem('guestId');
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    if (!token || !hotelId) {
        throw new Error('No token or hotelId found');
    }

    try {
        const response = await axios.get(`${baseUrl}/orders/search`, {
            headers: {
                Authorization: `Bearer ${token}`,
                hotelId: hotelId,
                ...(guestId && { guestId: guestId })
            },
            params: {
                tag: 'hotel',
                search: query,
                page: page,
                size: size
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error searching orders', error);
        throw error;
    }
};

const AdminOrders = () => {
    const { hotelId } = useContext(AuthContext);
    const { orders, setOrders, updateOrderStatus, resetNotifications, addNewOrders } = useOrders();
    const [searchQuery, setSearchQuery] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [editOrder, setEditOrder] = useState(null);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const observer = useRef();
    const lastOrderElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPage(prevPage => prevPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    const loadOrders = async (reset = false) => {
        if (loading) return;
        setLoading(true);
        try {
            const newPage = reset ? 0 : page;
            const ordersData = await fetchOrders(hotelId, newPage, 50);
            if (reset) {
                setOrders(ordersData);
            } else {
                addNewOrders(ordersData);
            }
            setHasMore(ordersData.length > 0);
        } catch (error) {
            console.error('Failed to fetch orders', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (hotelId) {
            loadOrders(true);
            resetNotifications();
        }
    }, [hotelId]);

    useEffect(() => {
        if (page > 0) {
            loadOrders();
        }
    }, [page]);

    const handleEditOrderSubmit = (updatedOrder) => {
        setOrders(prevOrders => prevOrders.map(order =>
            order.orderId === updatedOrder.orderId ? updatedOrder : order
        ));
        setShowEditModal(false);
    };

    const handleEditClick = (order) => {
        setEditOrder(order);
        setShowEditModal(true);
    };

    const handleStatusChange = (e, orderId) => {
        e.stopPropagation();
        const newStatus = e.target.value;
        updateOrderStatus(orderId, newStatus);
    };

    const debouncedSearch = useCallback(debounce(async (query) => {
        if (query) {
            setLoading(true);
            try {
                const searchResults = await searchOrders(hotelId, query, 0, 50);
                setOrders(searchResults);
                setHasMore(false);
            } catch (error) {
                console.error('Failed to search orders', error);
            }
            setLoading(false);
        } else {
            loadOrders(true);
        }
    }, 500), [hotelId]);

    const handleSearchChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        debouncedSearch(query);
    };

    return (
        <div className='rooms-container order-con'>
            <div className='bar2'>
                <h1>Orders</h1>
                <div className="room-controls">
                    <div className='search-br1'>
                        <p> <IoSearchOutline /></p>
                        <input
                            type="text"
                            placeholder="Guest Name or Room"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>
            </div>
            <div className='order-list'>
                {orders.map((order, index) => {
                    const orderContent = (
                        <div key={order.orderId} className="order-row" onClick={() => handleEditClick(order)}>
                            <div className="order-info">
                                <div className='row-box'>
                                    <span>{order.title}</span>
                                    <span className="smaller-text">{order.locationType}</span>
                                </div>
                                <span>{formatDate(order.created)}</span>
                                <div className='row-box'>
                                    <span>{order.guestName}</span>
                                    <span>Room {order.roomNumber}</span>
                                </div>
                                <div className='row-box'>
                                    <span>{order.totalQuantity} items</span>
                                    <span>${order.totalAmount}</span>
                                </div>
                            </div>
                            <div className="order-actions" onClick={(e) => e.stopPropagation()}>
                                <select
                                    value={order.orderStatus}
                                    onChange={(e) => handleStatusChange(e, order.orderId)}
                                >
                                    <option value="Pending">Pending</option>
                                    <option value="Preparing">Preparing</option>
                                    <option value="Approve" style={{ color: '#1A7242' }}>Approve</option>
                                    <option value="Ready" style={{ color: '#1A7242' }}>Ready</option>
                                    <option value="Declined" style={{ color: '#ff0000' }}>Declined</option>
                                </select>
                            </div>
                            <img src={message} alt='message' onClick={(e) => e.stopPropagation()} />
                        </div>
                    );

                    if (index === orders.length - 1) {
                        return <div ref={lastOrderElementRef} key={order.orderId}>{orderContent}</div>;
                    } else {
                        return <div key={order.orderId}>{orderContent}</div>;
                    }
                })}
            </div>
            {showEditModal && editOrder && (
                <EditOrderModal
                    order={editOrder}
                    onClose={() => setShowEditModal(false)}
                    onEditOrder={handleEditOrderSubmit}
                />
            )}
        </div>
    );
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
};

export default AdminOrders;