import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const EditOrderModal = ({ order, onClose, onEditOrder }) => {
    const [editedOrder, setEditedOrder] = useState({ ...order });
    const [, setIsSaving] = useState(false);
    const modalRef = useRef(null);
    const initialClickElement = useRef(null);
    const token = localStorage.getItem('hotelToken');

    useEffect(() => {
        setEditedOrder({ ...order });
    }, [order]);

    const handleStatusChange = (e) => {
        setEditedOrder({ ...editedOrder, orderStatus: e.target.value });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedOrder({ ...editedOrder, [name]: value });
    };

    const handleSave = async () => {
        setIsSaving(true);

        try {
            if (!token) throw new Error('Token not found');

            await axios.put(process.env.REACT_APP_API_BASE_URL + `/order/update`, editedOrder, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            onEditOrder(editedOrder);
            onClose();
        } catch (error) {
            console.error('Failed to update order:', error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleBackgroundMouseDown = (e) => {
        initialClickElement.current = e.target;
    };

    const handleBackgroundMouseUp = (e) => {
        if (e.target.className === 'main-room' && initialClickElement.current === e.target) {
            onClose();
        }
        initialClickElement.current = null;
    };

    return (
        <div className="main-room" ref={modalRef} onMouseDown={handleBackgroundMouseDown} onMouseUp={handleBackgroundMouseUp}>
            <div className="add-room-options">
                <div className='added-box'>
                    <h3>{order.title} Order</h3>
                    <button className="close" onClick={onClose}>×</button>
                </div>
                <div className="room-form edit">
                    <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
                        <div className="room-input-container cap1">
                            <input
                                type="text"
                                name="title"
                                value={editedOrder.title}
                                onChange={handleInputChange}
                                required
                            />
                            <label className={editedOrder.title ? 'floating' : ''}>
                                Order Type
                            </label>
                        </div>

                        {editedOrder.title !== 'Activity' && (
                            <>
                                <div className="room-input-container cap1">
                                    <input
                                        type="text"
                                        name="guestName"
                                        value={editedOrder.guestName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <label className={editedOrder.guestName ? 'floating' : ''}>
                                        Guest Name
                                    </label>
                                </div>
                                <div className="room-input-container cap1">
                                    <input
                                        type="text"
                                        name="roomNumber"
                                        value={editedOrder.roomNumber}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <label className={editedOrder.roomNumber ? 'floating' : ''}>
                                        Room Number
                                    </label>
                                </div>
                                <div className="room-input-container cap1">
                                    <input
                                        type="text"
                                        name="totalQuantity"
                                        value={editedOrder.totalQuantity}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <label className={editedOrder.totalQuantity ? 'floating' : ''}>
                                        Total Items
                                    </label>
                                </div>
                            </>
                        )}

                        {editedOrder.title === 'Activity' && (
                            <>
                                <div className="room-input-container cap1">
                                    <input
                                        type="date"
                                        name="date"
                                        value={editedOrder.date}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <label className={editedOrder.date ? 'floating' : ''}>
                                        Date
                                    </label>
                                </div>
                                <div className="room-input-container cap1">
                                    <input
                                        type="number"
                                        name="totalPeople"
                                        value={editedOrder.totalPeople}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <label className={editedOrder.totalPeople ? 'floating' : ''}>
                                        Total People
                                    </label>
                                </div>
                            </>
                        )}

                        <div className="room-input-container cap1">
                            <input
                                type="text"
                                name="totalAmount"
                                value={editedOrder.totalAmount}
                                onChange={handleInputChange}
                                required
                            />
                            <label className={editedOrder.totalAmount ? 'floating' : ''}>
                                Total Price
                            </label>
                        </div>

                        <div className="room-input-container">
                            <select
                                value={editedOrder.orderStatus}
                                onChange={handleStatusChange}
                            >
                                <option value="Pending">Pending</option>
                                <option value="Preparing">Preparing</option>
                                <option value="Approve" style={{ color: '#1A7242' }}>Approve</option>
                                <option value="Ready" style={{ color: '#1A7242' }}>Ready</option>
                                <option value="Declined" style={{ color: '#ff0000' }}>Declined</option>
                            </select>
                            <label className="floating">
                                Status
                            </label>
                        </div>

                        {(editedOrder.title === 'Breakfast in Bed' || editedOrder.title === 'Restaurant' || editedOrder.title === 'Wake Up Call'
                            || editedOrder.title === 'Room Cleaning' || editedOrder.title === 'Laundry') && (
                                <>
                                    <h3 style={{ margin: '0' }}>Order Details</h3>
                                    {editedOrder.title === 'Breakfast in Bed' ? (
                                        <div className='item-row'>
                                            {editedOrder.orderDetailsDto.map(item => (
                                                <div key={item.title} className='item-box'>
                                                    <div className="item-content">
                                                        <p
                                                            style={{ textAlign: 'left' }}
                                                            dangerouslySetInnerHTML={{ __html: `${item.itemQuantity}x ${item.title.split('. ').join('.<br />')}` }}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : editedOrder.title === 'Room Cleaning' ? (
                                        <div className='item-row'>
                                            {editedOrder.orderDetailsDto && editedOrder.orderDetailsDto[0] && editedOrder.orderDetailsDto[0].title ? (
                                                <div className='item-box'>
                                                    <div className="item-content">
                                                        <>
                                                            Time: <br />
                                                            {editedOrder.orderDetailsDto[0].title.split('Time: ')[1].split(' Services:')[0]}<br />
                                                            <br />
                                                            Services: <br />
                                                            {editedOrder.orderDetailsDto[0].title.split('Services: ')[1].split(' Additional Requests:')[0]}<br />
                                                            <br />
                                                            {editedOrder.orderDetailsDto[0].title.includes('Additional Requests:') && editedOrder.orderDetailsDto[0].title.split('Additional Requests: ')[1] !== '' ? (
                                                                <>
                                                                    Additional Requests: <br />
                                                                    {editedOrder.orderDetailsDto[0].title.split('Additional Requests: ')[1]}
                                                                </>
                                                            ) : null} 
                                                        </>
                                                    </div>
                                                </div>
                                            ) : (
                                                <span>Loading...</span>
                                            )}
                                        </div>
                                    ) : editedOrder.title === 'Laundry' ? (
                                        <div className='item-row'>
                                            {editedOrder.orderDetailsDto[0].title.split(', ').map((item, index) => {
                                                const [quantity, name] = item.split(' ');
                                                return (
                                                    <div key={index} className='item-box'>
                                                        <div className="item-content">
                                                            <p>{quantity}x {name}</p>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : ( // Исправлено здесь
                                        <div className='item-row'>
                                            {editedOrder.orderDetailsDto.map(item => (
                                                <div key={item.title} className='item-box'>
                                                    <div className="item-content">
                                                        <p>{item.itemQuantity}x {item.title}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </>
                            )}


                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditOrderModal;