import React, { useState } from 'react';
import AddAdminService from './AddAdminService'
import EditAdminService from './EditAdminService'
import { IoSearchOutline } from "react-icons/io5";
import Service from '../../Service'
const AdminService = () => {
  const [services, setServices] = useState(Service);
  const [searchQuery, setSearchQuery] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const openEditModal = (service) => {
    setSelectedService(service);
    setShowEditModal(true);
  };

  const handleAddService = (newService) => {
    setServices([...services, newService]);
    setShowAddModal(false);
  };
  const handleServiceChange = (name, value) => {
    setSelectedService({
      ...selectedService,
      [name]: value,
    });
  };
  const handleEditService = (updatedService) => {
    const updatedServices = services.map((service) =>
      service.id === updatedService.id ? updatedService : service
    );
    setServices(updatedServices);
    setShowEditModal(false);
  };

  const toggleServiceStatus = (id) => {
    const updatedServices = services.map((service) => {
      if (service.id === id) {
        return { ...service, status: service.status === 'ON' ? 'OFF' : 'ON' };
      }
      return service;
    });
    setServices(updatedServices);
  };
 
  const filteredActivities = services.filter(service =>
    service.title && service.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className='rooms-container'>
              <div className='room-container'>
      <div className='bar2'>
        <h1>Services</h1>
        <div className="room-controls">
          <div className='search-br1'>
            <p><IoSearchOutline /></p>
            <input
              type="text"
              placeholder="Find Customer order"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <button onClick={() => setShowAddModal(true)}>+</button>
        </div>
      </div>
      <ul className='room-list'>
        {filteredActivities.map((service) => (
          <li key={service.id} className='room-list1' onClick={() => openEditModal(service)}>
            <div className="room-info">
            <div className="room-column">
            <h4>{service.title}</h4>
            <div
              className={`activity-status ${service.status.toLowerCase()}`}
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering the parent click event
                toggleServiceStatus(service.id);
              }}
            >
              {service.status}
            </div>
            </div>
            <div className="room-description">
                              <p style={{ width: '100%',height: '80px' }}>{service.des}</p>
                            
                            </div>
                        </div>
                       
          </li>
        ))}
      </ul>
      {showAddModal && (
        <AddAdminService onClose={() => setShowAddModal(false)} onSubmit={handleAddService} />
      )}
      {showEditModal && selectedService && (
        <EditAdminService
          serviceData={selectedService}
          onClose={() => setShowEditModal(false)}
          handleSubmit={handleEditService}
          handleChange={handleServiceChange}
        />
      )}
    </div>
    </div>
  );
};

export default AdminService