import React from 'react'
import './Box.css'
import Arrow from '../ArrowB/Arrow';
import { Link } from 'react-router-dom';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { FaStar } from "react-icons/fa";
const Box = ({activities}) => {
   
  return (
    <div className='box-con'>
          <Link to='/home'  style={{textDecoration:'none',color: 'var(--primaryColor)'}} ><Arrow/></Link>
        <div className='acti-container'>
         
        <h2 className='titlet'>Tour</h2>
        <div className='scr-main'>
          {activities.map((item)=>(
            <Link to={`/activities/${item.id}`} key={item.id} className='scr-con' style={{textDecoration:'none',color: 'var(--primaryColor)'}}>
                <img src={item.image} alt="Activity"/>
         <div className='text-container1'>
            <h2>{item.title} </h2>
            <p className='des-acti'>{item.description}</p>
            <p className='pr'>Start From ${item.pickup.price}</p>
            <p className='pr'><FaStar  className='star'/>{item.star}</p>
            </div>
           
            </Link>
          ))}
       
          </div>
         
          <h2 className='titlet t1'>More Activities</h2>
          <div className='acti'>
        {activities.map((item,id)=>(
        <div key={id} className='detail-container' >
         <img src={item.image} alt="Activity"/>
         <div className='text-container'>
            <h2>{item.title} </h2>
            <p>{item.description}</p>
           <p className='pr'> <FaStar className='star'/>{item.star}</p>
            </div>
            <p className='checkb1'><MdKeyboardDoubleArrowRight />
      </p>
         </div>
         ))}
           <div className='extra'> </div>
         </div>
         
         </div>
    </div>
  );
}

export default Box