import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CustomInput from './CustomInput';
import Arrow from '../ArrowB/Arrow.jsx';
import './ActivityDetail.css';
import Swal from 'sweetalert2';
import pic from '../Assets/aat.png';
import Carousel from './Carousel.jsx';
import { useOrderContext } from '../Cart/OrderContext.jsx';

const ActivityDetail = () => {
  const { state } = useLocation();
  const activity = state.activity;
  const { addOrder } = useOrderContext();
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [numPeople, setNumPeople] = useState('');
  const [isBookingPlaced, setIsBookingPlaced] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const getTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    return tomorrow;
  };

  const availableOptions = useMemo(() => {
    const options = [];
    if (activity?.additionalOptions?.price?.private) options.push('private');
    if (activity?.additionalOptions?.price?.pickup) options.push('pickup');
    return options;
  }, [activity]);

  const findNextAvailableDay = (availableDays) => {
    const tomorrow = getTomorrowDate();
    const tomorrowDay = tomorrow.getDay();
    const daysMap = {
      'Sunday': 0, 'Monday': 1, 'Tuesday': 2, 'Wednesday': 3,
      'Thursday': 4, 'Friday': 5, 'Saturday': 6
    };

    const availableDayNumbers = availableDays.map(day => daysMap[day]);
    const nextAvailableDay = availableDayNumbers.find(day => day >= tomorrowDay) || availableDayNumbers[0];
    const daysToAdd = nextAvailableDay >= tomorrowDay ? nextAvailableDay - tomorrowDay : 7 - tomorrowDay + nextAvailableDay;
    const nextDate = new Date(tomorrow);
    nextDate.setDate(tomorrow.getDate() + daysToAdd);
    return nextDate;
  };

  const getOptionData = (option) => {
    if (!activity?.additionalOptions) return null;
    return {
      price: activity.additionalOptions.price[option],
      currency: activity.additionalOptions.currency[option],
      includes: activity.additionalOptions.includes[option],
      location: activity.additionalOptions.location[option],
      timeType: activity.additionalOptions.timeType[option],
      fixedTime: activity.additionalOptions.fixedTime[option],
      maxPeople: activity.additionalOptions.maxPeople[option],
      notIncludes: activity.additionalOptions.notIncludes[option],
      availableDays: activity.additionalOptions.availableDays[option],
      flexibleTimes: activity.additionalOptions.flexibleTimes[option],
      additional_info: activity.additionalOptions.additional_info[option]
    };
  };

  const optionData = useMemo(() => getOptionData(selectedOption), [selectedOption]);

  const filterAvailableDates = (date) => {
    if (!selectedOption) return false;
    if (!optionData?.availableDays) return false;
    const tomorrow = getTomorrowDate();
    if (date < tomorrow) return false;
    const dayName = date.toLocaleDateString('en-US', { weekday: 'long' });
    return optionData.availableDays.includes(dayName);
  };

  useEffect(() => {
    if (availableOptions.length > 0 && !selectedOption) {
      setSelectedOption(availableOptions[0]);
    }
  }, [availableOptions, selectedOption]);

  useEffect(() => {
    if (selectedOption) {
      const nextAvailableDate = optionData?.availableDays ? findNextAvailableDay(optionData.availableDays) : null;

      if (optionData?.timeType === 'fixed' && nextAvailableDate) {
        if (optionData.fixedTime?.startTime) {
          const startTime = new Date(optionData.fixedTime.startTime);
          const newDateTime = new Date(nextAvailableDate);
          newDateTime.setHours(startTime.getHours());
          newDateTime.setMinutes(startTime.getMinutes());
          setSelectedDateTime(newDateTime);
        } else if (optionData.flexibleTimes?.length > 0) {
          const earliestTimeISO = optionData.flexibleTimes.reduce((earliest, current) => {
            return new Date(current) < new Date(earliest) ? current : earliest;
          });

          const earliestTime = new Date(earliestTimeISO);
          const newDateTime = new Date(nextAvailableDate);
          newDateTime.setUTCHours(earliestTime.getUTCHours());
          newDateTime.setUTCMinutes(earliestTime.getUTCMinutes());
          setSelectedDateTime(newDateTime);
        } else {
          setSelectedDateTime(nextAvailableDate);
        }
      } else if (optionData?.timeType === 'flexible' && nextAvailableDate) {
        if (optionData.flexibleTimes?.length > 0) {
          const earliestTimeISO = optionData.flexibleTimes.reduce((earliest, current) => {
            return new Date(current) < new Date(earliest) ? current : earliest;
          });

          const earliestTime = new Date(earliestTimeISO);
          const newDateTime = new Date(nextAvailableDate);
          newDateTime.setUTCHours(earliestTime.getUTCHours());
          newDateTime.setUTCMinutes(earliestTime.getUTCMinutes());
          setSelectedDateTime(newDateTime);
        } else {
          setSelectedDateTime(nextAvailableDate);
        }
      }
    }
  }, [selectedOption, optionData]);


  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setSelectedDateTime(null);
  };

  const formatTime = (timeString) => {
    if (!timeString) return 'N/A';
    return new Date(timeString).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const renderTimeInfo = (optionData) => {
    if (!optionData) return null;
    if (optionData.fixedTime?.startTime) {
      const startTime = formatTime(optionData.fixedTime.startTime);
      const endTime = formatTime(optionData.fixedTime.endTime);
      return (
        <div className='text-container'>
          <p>Working Hours: {startTime} - {endTime}</p>
        </div>
      );
    }
    if (optionData.flexibleTimes?.private?.length) {
      return (
        <div className='text-container'>
          <p>Available Times: </p>
          {optionData.flexibleTimes.private.map((time, index) => (
            <p key={index}>{formatTime(time)}</p>
          ))}
        </div>
      );
    }
    return <div className='text-container'><p>No time slots available</p></div>;
  };

  const renderListItems = (listString, symbol) => {
    if (!listString) return null;
    return listString.split('\n').map((line, index) => (
      <p key={index}>{symbol} {line}</p>
    ));
  };

  const handlePlaceBooking = () => {
    Swal.fire({
      title: 'Confirm Booking',
      text: `Are you sure you want to book the ${selectedOption} option?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Confirm',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button'
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsBookingPlaced(true);
        Swal.fire({
          title: 'Booking Confirmed',
          html: `<div>Your booking for the ${selectedOption} option has been confirmed!</div>`,
          confirmButtonText: 'Close',
          iconHtml: `<img src="${activity.images[0].url}" style="width: 60px; height: 60px; border: none; box-shadow: none; border-radius: 0;"/>`,
          customClass: {
            icon: 'custom-icon',
            confirmButton: 'custom-confirm-button'
          },
          buttonsStyling: false,
        }).then(() => {
          const newOrder = {
            id: Date.now(),
            Name: activity.title,
            items: [{
              id: activity.id,
              title: activity.title,
              price: activity.additionalOptions[selectedOption].price,
              quantity: parseInt(numPeople)
            }],
            totalItems: parseInt(numPeople),
            totalAmount: activity.additionalOptions[selectedOption].price * parseInt(numPeople),
            orderStatus: 'Pending',
            startDate: selectedDateTime.toISOString(),
            selectedOption: selectedOption,
          };
          addOrder(newOrder);
          setSelectedDateTime(null);
          setNumPeople('');
          setSelectedOption(availableOptions[0]);
          setIsBookingPlaced(false);
        });
      }
    });
  };

  const isButtonDisabled = !selectedDateTime || numPeople === '';

  const handleFlexibleTimeChange = (time) => {
    if (selectedDateTime) {
      const [hours, minutes] = time.split(':');
      const newDateTime = new Date(selectedDateTime);
      newDateTime.setHours(parseInt(hours, 10));
      newDateTime.setMinutes(parseInt(minutes, 10));
      setSelectedDateTime(newDateTime);
    }
  }

  return (
    <div className='activity'>
      <Link to='/activities' style={{ textDecoration: 'none', color: 'var(--primaryColor)' }}>
        <Arrow />
      </Link>
      <div className='activity-detail'>
        <div className='activity-info'>
          <Carousel images={activity.images.map(image => image.url)} />
          <h2 className='titlea'>{activity.title}</h2>
          <p className='des'>{activity.description}</p>
        </div>

        <div className="option-buttons">
          {availableOptions.map(option => {
            const label = option === 'private' ? 'Private' : 'Join-In';
            return (
              <button
                key={option}
                onClick={() => handleOptionChange(option)}
                className={selectedOption === option ? 'selected' : ''}
              >
                <h3>{label}</h3>
                <p>Price: ${getOptionData(option).price}</p>
              </button>
            );
          })}
        </div>

        {selectedOption && (
          <>
            <div className='booking'>
              {optionData?.timeType === 'fixed' && (
                <DatePicker
                  selected={selectedDateTime}
                  onChange={(date) => setSelectedDateTime(date)}
                  showTimeSelect
                  filterDate={filterAvailableDates}
                  customInput={<CustomInput />}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  className="date-picker"
                  placeholderText="Choose Date & Time"
                  minDate={getTomorrowDate()}
                  timeIntervals={60}
                  minTime={optionData?.fixedTime?.startTime ? new Date(optionData.fixedTime.startTime) : null}
                  maxTime={optionData?.fixedTime?.endTime ? new Date(optionData.fixedTime.endTime) : null}
                />
              )}
              {optionData?.timeType === 'flexible' && (
                <>
                  {!selectedDateTime ? (
                    <div className="choose-time-placeholder">Choose Time</div>
                  ) : (
                    <DatePicker
                      selected={selectedDateTime}
                      onChange={(date) => setSelectedDateTime(date)}
                      showTimeSelect
                      filterDate={filterAvailableDates}
                      customInput={<CustomInput />}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      className="date-picker"
                      placeholderText="Choose Date & Time"
                      minDate={getTomorrowDate()}
                      timeIntervals={30}
                      includeTimes={optionData?.flexibleTimes.map(timeString => new Date(timeString))}
                      showTimeInput={false}
                    />
                  )}
                </>
              )}

              <select
                value={numPeople}
                onChange={(e) => setNumPeople(e.target.value)}
                className='people-select'
                disabled={isBookingPlaced}
              >
                <option value='' disabled>Number of people</option>
                {[...Array(parseInt(optionData?.maxPeople || 10)).keys()].map(num => (
                  <option key={num + 1} value={num + 1}>
                    {num + 1}
                  </option>
                ))}
              </select>
            </div>

            <div className="option-details">
              {selectedOption && optionData && (
                <div className='pri-box'>
                  <h4>Activity Info</h4>
                  <div className='main-include'>
                    <div className='near-box'>
                      <img src={pic} alt='leave' className='leave' />
                      <div className='text-container'>
                        <div className='no'>
                          <div>
                            <p>Location - {optionData.location}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='near-box'>
                      <img src={pic} alt='leave' className='leave' />
                      {renderTimeInfo(optionData)}
                    </div>
                  </div>

                  <div className='include-box'>
                    <h4>Includes</h4>
                    {renderListItems(optionData.includes, '•')}
                    <h4>Does Not Include</h4>
                    {renderListItems(optionData.notIncludes, '•')}
                  </div>
                  <div className='extra'></div>

                  <div className="additional-info">
                    {activity?.additionalOptions?.additional_info?.[selectedOption]}
                  </div>
                </div>
              )}
            </div>

            <div className="overlay-box">
              <div className="price-row">
                <p>Total Price: ${optionData?.price ? optionData.price * (numPeople || 1) : "N/A"}</p>
              </div>
              <div className="button-row">
                <button
                  onClick={handlePlaceBooking}
                  disabled={isButtonDisabled}
                  className={`booking-button ${isButtonDisabled ? 'disabled' : ''}`}
                >
                  {isBookingPlaced ? "Booking Placed" : "Place Booking"}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ActivityDetail;