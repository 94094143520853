import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Collapse } from 'react-collapse';

const ActivityForm = ({ onSubmit, onClose }) => {
    const [formData, setFormData] = useState({
        title: '',
        title2: '',
        star: '',
        description: '',
        longdes: '',
        status: 'ON',
        tourType: 'private',
        availableDates: {
            private: [],
            pickup: [],
            meeting: [],
        },
        timeType: {
            private: 'fixed',
            pickup: 'fixed',
            meeting: 'fixed',
        },
        fixedTime: {
            private: '08:00-16:00',
            pickup: '08:00-16:00',
            meeting: '08:00-16:00',
        },
        flexibleTimes: {
            private: [],
            pickup: [],
            meeting: [],
        },
        price: {
            private: '',
            pickup: '',
            meeting: '',
        },
        includes: {
            private: '',
            pickup: '',
            meeting: '',
        },
        notIncludes: {
            private: '',
            pickup: '',
            meeting: '',
        },
        leave: {
            private: {},
            pickup: {},
            meeting: {},
        },
        photos: [],
    });

    const [isLeaveOpen, setIsLeaveOpen] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleNestedChange = (category, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [category]: {
                ...prevData[category],
                [formData.tourType]: value,
            },
        }));
    };

    const handleTourTypeChange = (e) => {
        setFormData({ ...formData, tourType: e.target.value });
    };

    const handleDateChange = (tourType, date) => {
        if (!date) return;
        setFormData((prevData) => ({
            ...prevData,
            availableDates: {
                ...prevData.availableDates,
                [tourType]: [...prevData.availableDates[tourType], date].sort((a, b) => a - b)
            }
        }));
    };

    const removeDate = (tourType, dateToRemove) => {
        setFormData((prevData) => ({
            ...prevData,
            availableDates: {
                ...prevData.availableDates,
                [tourType]: prevData.availableDates[tourType].filter(
                    (date) => date.getTime() !== dateToRemove.getTime()
                )
            }
        }));
    };

    const handleFlexibleTimeChange = (tourType, index, value) => {
        const newFlexibleTimes = [...formData.flexibleTimes[tourType]];
        newFlexibleTimes[index] = value;
        setFormData((prevData) => ({
            ...prevData,
            flexibleTimes: {
                ...prevData.flexibleTimes,
                [tourType]: newFlexibleTimes,
            },
        }));
    };

    const addFlexibleTime = (tourType) => {
        setFormData((prevData) => ({
            ...prevData,
            flexibleTimes: {
                ...prevData.flexibleTimes,
                [tourType]: [...prevData.flexibleTimes[tourType], ''],
            },
        }));
    };

    const removeFlexibleTime = (tourType, index) => {
        const updatedTimes = [...formData.flexibleTimes[tourType]];
        updatedTimes.splice(index, 1);
        setFormData(prevState => ({
            ...prevState,
            flexibleTimes: {
                ...prevState.flexibleTimes,
                [tourType]: updatedTimes
            }
        }));
    };

    const handleLeaveChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            leave: {
                ...prevData.leave,
                [formData.tourType]: {
                    ...prevData.leave[formData.tourType],
                    [name]: value,
                },
            },
        }));
    };

    const handlePhotoChange = (e) => {
        const files = Array.from(e.target.files);
        const newPhotos = files.map((file) => URL.createObjectURL(file));
        setFormData((prevData) => ({
            ...prevData,
            photos: [...prevData.photos, ...newPhotos],
        }));
    };

    const removePhoto = (index) => {
        setFormData((prevData) => ({
            ...prevData,
            photos: prevData.photos.filter((_, i) => i !== index),
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <div className="main-room">
            <div className="add-room-options">
                <div className="added-box">
                    <h3>Add Activity</h3>
                    <button className="close" onClick={onClose}>×</button>
                </div>
                <div className="room-form edit">
                    <form onSubmit={handleSubmit}>
                        <div className='title-box'>
                            <div className="room-input-container">
                                <input
                                    type="text"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleChange}
                                    required
                                />
                                <label className={formData.title ? 'floating' : ''}>Title</label>
                            </div>
                            <div className="room-input-container">
                                <input
                                    type="text"
                                    name="title2"
                                    value={formData.title2}
                                    onChange={handleChange}
                                    required
                                />
                                <label className={formData.title2 ? 'floating' : ''}>Subtitle</label>
                            </div>
                            <div className="room-input-container">
                                <input
                                    type="number"
                                    name="star"
                                    value={formData.star}
                                    onChange={handleChange}
                                    min="1"
                                    max="5"
                                />
                                <label className={formData.star? 'floating' : ''}>Ranking</label>
                            </div>
                        </div>

                        <div className="room-input-container cap1">
                            <textarea
                                name="description"
                                style={{ width: '97%' }}
                                value={formData.description}
                                onChange={handleChange}
                            />
                            <label className={formData.description? 'floating' : ''}>Description</label>
                        </div>

                        <div className="room-input-container ">
                            <textarea
                                style={{ width: '97%' }}
                                name="longdes"
                                value={formData.longdes}
                                onChange={handleChange}
                            />
                            <label className={formData.longdes? 'floating' : ''}>Main Description</label>
                        </div>

                        <div className="room-input-container ">
                            <select
                                name="status"
                                style={{ width: '100%' }}
                                value={formData.status}
                                onChange={handleChange}
                            >
                                <option value="ON">ON</option>
                                <option value="OFF">OFF</option>
                            </select>
                            <label className={formData.status ? 'floating' : ''}>Status</label>
                        </div>

                        <div className="room-input-container" style={{ width: '100%' }}>
                            <select
                                name="tourType"
                                value={formData.tourType}
                                onChange={handleTourTypeChange}
                            >
                                <option value="private">Private Tour</option>
                                <option value="pickup">Pickup Tour</option>
                                <option value="meeting">Meeting Point Tour</option>
                            </select>
                            <label className={formData.tourType ? 'floating' : ''}>Tour Type</label>
                        </div>

                        <div className='scrollable-box'>
                            {formData.tourType && (
                                <>
                                    <div className='form-group-main'>
                                        <label>{formData.tourType.charAt(0).toUpperCase() + formData.tourType.slice(1)} Tour Dates</label>
                                        <div className="date-time-container">
                                            <div className="date-column">
                                                <DatePicker
                                                    selected={null}
                                                    onChange={(date) => handleDateChange(formData.tourType, date)}
                                                    highlightDates={formData.availableDates[formData.tourType]}
                                                    inline
                                                    monthsShown={1}
                                                />
                                                <div className="date-gallery">
                                                    {formData.availableDates[formData.tourType].map((date, index) => (
                                                        <div key={index} className="date-item">
                                                            <div className="date-container">
                                                                <span>{date.toLocaleDateString()}</span>
                                                                <div className="room-overlay">
                                                                    <button 
                                                                        type='button' 
                                                                        className="remove-btn" 
                                                                        onClick={() => removeDate(formData.tourType, date)}
                                                                    >
                                                                        &ndash;
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            
                                            <div className="time-column">
                                                <div className="form-group-row">
                                                    <div className="room-input-container" style={{ width: '100%' }}>
                                                        <label className={formData.timeType ? 'floating' : ''}>TimeType</label>
                                                        <select
                                                            value={formData.timeType[formData.tourType]}
                                                            onChange={(e) => handleNestedChange('timeType', e.target.value)}
                                                        >
                                                            <option value="fixed">Fixed Time</option>
                                                            <option value="flexible">Flexible Time</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                {formData.timeType[formData.tourType] === 'fixed' ? (
                                                    <div className="form-group-row">
                                                        <div className="room-input-container" >
                                                            <label className={formData.fixedTime ? 'floating' : ''}>Fixed Time</label>
                                                            <input
                                                                style={{ width: '100%' }}
                                                                type="text"
                                                                value={formData.fixedTime[formData.tourType]}
                                                                onChange={(e) => handleNestedChange('fixedTime', e.target.value)}
                                                                placeholder="HH:MM-HH:MM"
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="form-group-row">
                                                        <div className="flexible-times-header">
                                                            <label>Flexible Times</label>
                                                            <button
                                                                type="button"
                                                                className="add-time-btn"
                                                                onClick={() => addFlexibleTime(formData.tourType)}
                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                        <div className="flexible-times-container">
                                                            {formData.flexibleTimes[formData.tourType].map((time, index) => (
                                                                <div key={index} className="time-box">
                                                                    <input
                                                                        type="text"
                                                                        value={time}
                                                                        onChange={(e) =>
                                                                            handleFlexibleTimeChange(
                                                                                formData.tourType,
                                                                                index,
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        placeholder="HH:MM"
                                                                    />
                                                                    <div className="room-overlay">
                                                                        <button 
                                                                            type='button' 
                                                                            className="remove-btn" 
                                                                            onClick={() => removeFlexibleTime(formData.tourType, index)}
                                                                        >
                                                                            &ndash;
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}

                                                <div className='main-group-row'>
                                                    <button
                                                        type="button"
                                                        onClick={() => setIsLeaveOpen(!isLeaveOpen)}
                                                    >
                                                        {isLeaveOpen ? 'Hide Leave Information' : 'Show Leave Information'}
                                                    </button>
                                                    <Collapse isOpened={isLeaveOpen}>
                                                        <div className='leave-box'>
                                                            <input
                                                                type="text"
                                                                name="near"
                                                                value={formData.leave[formData.tourType].near || ''}
                                                                onChange={handleLeaveChange}
                                                                placeholder="Near location"
                                                            />
                                                            <input
                                                                type="text"
                                                                name="time"
                                                                value={formData.leave[formData.tourType].time || ''}
                                                                onChange={handleLeaveChange}
                                                                placeholder="Leave time"
                                                            />
                                                            <input
                                                                type="text"
                                                                name="place"
                                                                value={formData.leave[formData.tourType].place || ''}
                                                                onChange={handleLeaveChange}
                                                                placeholder="Leave place"
                                                            />
                                                            <input
                                                                type="text"
                                                                name="additional"
                                                                value={formData.leave[formData.tourType].additional || ''}
                                                                onChange={handleLeaveChange}
                                                                placeholder="Additional info"
                                                            />
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="room-input-container" style={{ marginTop: '15px' }}>
                                        <input
                                            type="text"
                                            style={{ width: '100%'}}
                                            value={formData.price[formData.tourType]}
                                            onChange={(e) => handleNestedChange('price', e.target.value)}
                                            required
                                        />
                                        <label className={formData.price[formData.tourType] ? 'floating' : ''}>Price</label>
                                    </div>
                                </>
                            )}
              
                        <div className="room-input-container" style={{  marginTop : '15px'}}>
                            
                            <textarea
                             style={{ width: '97%'}}
                                value={formData.includes[formData.tourType]}
                                onChange={(e) => handleNestedChange('includes', e.target.value)}
                               
                            />
                            <label className={formData.includes[formData.tourType] ? 'floating' : ''}>
                                Includes
                            </label>
                        </div>

                        <div className="room-input-container" style={{  marginTop : '15px'}}>
                            
                            <textarea
                           style={{ width: '97%'}}
                                value={formData.notIncludes[formData.tourType]}
                                onChange={(e) => handleNestedChange('notIncludes', e.target.value)}
                               
                            />
                            <label className={formData.notIncludes[formData.tourType] ? 'floating' : ''}>
                                Not Includes
                            </label>
                        </div>

                        <div className="form-group">
                            <label>Photos</label>
                            <div className="photo-gallery">
                                {formData.photos.map((photo, index) => (
                                    <div key={index} className="photo-item">
                                        <div className="image-container">
                                            <img src={photo} alt={`Activity ${index + 1}`} />
                                            <div className="room-overlay">
                                                <button type='button' className="remove-btn" onClick={() => removePhoto(index)}>
                                                    &ndash; 
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="file-input-container">
                                <label htmlFor="file-input" className="file-input-label">
                                    {formData.photos.length > 0 ? 'Add More Photos' : 'Choose Photos'}
                                </label>
                                <input
                                    type="file"
                                    name="photos"
                                    accept="image/*"
                                    id="file-input"
                                    className="file-input"
                                    onChange={handlePhotoChange}
                                    multiple
                                />
                            </div>
                        </div>
                        </div>
                        <hr />
                        <div className="button-bar">
                            <button type="submit" className="save-btn">Save</button>
                            <button type="button" onClick={onClose} className="delete-button">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ActivityForm;