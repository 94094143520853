import React, { useState, useEffect } from 'react';
import ChatItem from './ChatItem';
import { IoSearchOutline } from "react-icons/io5";
import './Chat.css'
import AddChat from './AddChat.jsx';
import axios from 'axios';
import { format, parseISO } from 'date-fns';

const ConversationList = ({ onChatSelect, selectedChatId }) => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [recentMessages, setRecentMessages] = useState([]);
    const [unreadMessageCounts, setUnreadMessageCounts] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(20);
    const [totalPages, setTotalPages] = useState(0);
    const token = localStorage.getItem('hotelToken');

    useEffect(() => {
        fetchChatOverview();
    }, [page, size]);

    useEffect(() => {
        if (selectedChatId && recentMessages.length > 0) {
            const chat = recentMessages.find(message => message.guestId.toString() === selectedChatId);
            if (chat) {
                onChatSelect({
                    id: chat.guestId,
                    name: chat.guestName,
                    roomNumber: chat.roomNumber,
                    lastMessage: chat.lastMessageContent,
                    lastMessageTime: chat.lastMessageTime,
                    unreadCount: unreadMessageCounts[chat.guestId] || 0
                });
            }
        }
    }, [selectedChatId, recentMessages, unreadMessageCounts, onChatSelect]);

    const fetchChatOverview = async () => {
        const hotelId = localStorage.getItem('hotelId');

        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/chat/overview`, {
                params: {
                    page,
                    size
                },
                headers: {
                    'Authorization': `Bearer ${token}`,
                    hotelId: hotelId,
                }
            });
            
            const chatOverview = response.data;
            setRecentMessages(chatOverview.recentMessages.content.map(message => ({
                ...message,
                lastMessageTime: formatTimestamp(message.lastMessageTime)
            })));
            setUnreadMessageCounts(chatOverview.unreadMessageCounts);
            setTotalPages(chatOverview.recentMessages.totalPages);
        } catch (error) {
            console.error('Error fetching chat overview:', error);
        }
    };

    const formatTimestamp = (isoTimestamp) => {
        try {
            const date = parseISO(isoTimestamp);
            return format(date, "MM.dd.yyyy hh:mm a");
        } catch (error) {
            console.error("Error parsing date:", error);
            return isoTimestamp;
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredMessages = recentMessages.filter(message =>
        message.guestName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const loadMoreMessages = () => {
        if (page < totalPages - 1) {
            setPage(prevPage => prevPage + 1);
        }
    };

    return (
        <div className="conversation-list">
            <div className="search-bar">
                <div className='search-br2'>
                    <p><IoSearchOutline /></p>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>
                <button onClick={() => setShowAddModal(true)}>+</button>
            </div>

            <div className='cartItem'>
                {filteredMessages.map((message) => (
                    <ChatItem
                        key={message.guestId}
                        conversation={{
                            id: message.guestId,
                            name: message.guestName,
                            roomNumber: message.roomNumber,
                            lastMessage: message.lastMessageContent,
                            lastMessageTime: message.lastMessageTime,
                            unreadCount: unreadMessageCounts[message.guestId] || 0
                        }}
                        onChatSelect={onChatSelect}
                        isActive={selectedChatId === message.guestId.toString()}
                    />
                ))}
                {page < totalPages - 1 && (
                    <button onClick={loadMoreMessages}>Load More</button>
                )}
            </div>
        
            {showAddModal && (
                <AddChat
                    onClose={() => setShowAddModal(false)}
                    onGuestAdded={fetchChatOverview}
                />
            )}
        </div>
    );
};

export default ConversationList;