import React, { useState, useEffect, useRef } from 'react';
import RoomTypeSelect from './RoomTypeSelect';
import axios from 'axios';

const EditRoomPage = ({ room, onClose, onEditRoom, onDeleteRoom }) => {
    const token = localStorage.getItem('hotelToken');
    const [formData, setFormData] = useState({
        roomNumber: room.roomNumber,
        type: room.type,
        wifiPass: room.wifiPass,
        breakfast: room.breakfast === 'y',
    });
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const initialClickElement = useRef(null);

    useEffect(() => {
        const fetchRoomData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/rooms/${room.id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const fetchedRoom = response.data;
                setFormData({
                    ...fetchedRoom,
                    breakfast: fetchedRoom.breakfast === 'y',
                });
            } catch (error) {
                console.error('Failed to fetch room data', error);
            }
        };

        fetchRoomData();
    }, [room.id]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updatedRoom = {
            ...room,
            ...formData,
            breakfast: formData.breakfast ? 'y' : 'n',
        };

        try {
            await axios.put(process.env.REACT_APP_API_BASE_URL + '/room/edit', updatedRoom, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            onEditRoom(updatedRoom);
            onClose();
        } catch (error) {
            console.error('Failed to update room data', error);
        }
    };

    const handleDelete = async () => {
        try {
            await axios.delete(process.env.REACT_APP_API_BASE_URL + `/room/${room.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            onDeleteRoom(room.id);
            onClose(); // Close the modal after deleting
        } catch (error) {
            console.error('Failed to delete room', error);
        }
    };

    const handleBackgroundMouseDown = (e) => {
        initialClickElement.current = e.target;
    };

    const handleBackgroundMouseUp = (e) => {
        if (e.target.className === 'main-room' && initialClickElement.current === e.target) {
            onClose();
        }
        initialClickElement.current = null;
    };

    const handleConfirmDelete = () => {
        setShowConfirmModal(true);
    };

    const handleCancelDelete = () => {
        setShowConfirmModal(false);
    };

    const handleConfirmYes = () => {
        handleDelete();
        setShowConfirmModal(false);
    };

    const handleClick = (inputName) => {
        document.getElementsByName(inputName)[0].focus();
    };

    return (
        <div className='main-room' onMouseDown={handleBackgroundMouseDown} onMouseUp={handleBackgroundMouseUp}>
            <div className="add-room-options">
                <div className="added-box">
                    <h3>Edit Room</h3>
                    <button className="close" onClick={onClose}>×</button>
                </div>
                <div className="room-form " style={{ paddingTop: '10px' }}>
                    <form onSubmit={handleSubmit}>
                        <div className="room-input-container cap1">
                            <input type="text" name="roomNumber" value={formData.roomNumber} onChange={handleChange} required />
                            <label
                                className={formData.roomNumber ? 'floating' : ''}
                                onClick={() => handleClick('roomNumber')}
                            >
                                Room Number
                            </label>
                        </div>
                        <div className="date-picker-container">
                            <RoomTypeSelect value={formData.type} onChange={handleChange} label="Room Type" />
                        </div>
                        <div className='row-box1'>
                            <div className='checkbox-container'>
                                <input
                                    type="checkbox"
                                    id="breakfast"
                                    name="breakfast"
                                    checked={formData.breakfast}
                                    onChange={handleChange}
                                />
                                <label htmlFor="breakfast">Breakfast</label>
                            </div>
                            <div className="room-input-container">
                                <input type="text" name="wifiPass" value={formData.wifiPass} onChange={handleChange} required />
                                <label
                                    className={formData.wifiPass ? 'floating' : ''}
                                    onClick={() => handleClick('wifiPass')}
                                >
                                    Wifi Password
                                </label>
                            </div>
                        </div>
                        <hr />
                        <div className='button-bar'>
                            <button type="button" className="delete-button" onClick={handleConfirmDelete}>Delete</button>
                            <button type="submit" className='save-btn'>Save</button>
                        </div>
                    </form>
                </div>
            </div>

            {showConfirmModal && (
                <div className="modal" onClick={handleCancelDelete}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <h3>Are you sure you want to delete?</h3>
                        <div className="button-bar">
                            <button className="delete-button" onClick={handleConfirmYes}>Yes</button>
                            <button className='save-btn' onClick={handleCancelDelete}>No</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EditRoomPage;
