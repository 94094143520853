import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { CartProvider } from './Component/Cart/CartContext';
import { OrderProvider } from './Component/Cart/OrderContext';
import { NotificationProvider } from './Component/Assets/MainAdminLAyout/Chat/NotificationContext';
import { AdminOrderProvider } from './Component/Assets/MainAdminLAyout/AdminOrders/AdminOrderContext';
import CartItemsBtn from './Component/Cart/CartItemsBtn';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
    <CartProvider>
    <OrderProvider>
    <NotificationProvider>
      <AdminOrderProvider>
    <App />
    </AdminOrderProvider>
    </NotificationProvider>
    </OrderProvider>
    <CartItemsBtn/>
    </CartProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
