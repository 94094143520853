import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CustomInput from './CustomInput';
import Arrow from '../ArrowB/Arrow.jsx';
import './ActivityDetail.css';
import Swal from 'sweetalert2';
import pic from '../Assets/aat.png'
import Carousel from './Carousel.jsx';
import { useOrderContext } from '../Cart/OrderContext.jsx';
const getStartOfWeek = (date) => {
  const today = new Date(date);
  const isAfter6PM = today.getHours() >= 18;
  if (isAfter6PM) {
    today.setDate(today.getDate() + 1);
  }
  const dayOfWeek = today.getDay();
  const diff = today.getDate() - dayOfWeek;
  return new Date(today.setDate(diff));
};

const getEndOfWeek = (date) => {
  const endOfWeek = new Date(date);
  endOfWeek.setDate(endOfWeek.getDate() + 6);
  return endOfWeek;
};
const ActivityDetail = ({ activities }) => {
  const { id } = useParams();
  const activity = activities.find((item) => item.id === parseInt(id));
  const { addOrder } = useOrderContext();
  const [startDate, setStartDate] = useState(null);
  const [numPeople, setNumPeople] = useState('');
  const [isBookingPlaced, setIsBookingPlaced] = useState(false);
  const [selectedOption, setSelectedOption] = useState('private'); // Default to 'private'
  const [price, setPrice] = useState(0); 
  const today = new Date();
  const isAfter6PM = today.getHours() >= 18;
  const minDate = isAfter6PM ? new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1) : today;
  const [weekStart, setWeekStart] = useState(getStartOfWeek(minDate));
  const [weekEnd, setWeekEnd] = useState(getEndOfWeek(weekStart));
  useEffect(() => {
    
    if (activity && activity[selectedOption]) {
      setPrice(activity[selectedOption].price);
    }
  }, [selectedOption, activity]);
  useEffect(() => {
    const selectedActivity = activity[selectedOption];
    if (selectedActivity) {
      const availableDates = selectedActivity.availableDates || [];
      const minAvailableDate = availableDates.length > 0 
        ? new Date(Math.min(...availableDates.map(d => new Date(d))))
        : minDate;
      
      setWeekStart(getStartOfWeek(minAvailableDate));
      setWeekEnd(getEndOfWeek(getStartOfWeek(minAvailableDate)));
    }
  }, [activity, selectedOption, minDate]);

  
  const filterAvailableDates = (date) => {
    const availableDates = activity[selectedOption].availableDates || [];
    return availableDates.some(availableDate => 
      new Date(availableDate).toDateString() === date.toDateString()
    ) && date >= weekStart && date <= weekEnd;
  };

  const handlePlaceBooking = () => {
    Swal.fire({
      title: 'Confirm Booking',
      text: `Are you sure you want to book the ${selectedOption} option?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Confirm',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button'
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsBookingPlaced(true);
  
        Swal.fire({
          title: 'Booking Confirmed',
          html: `<div>Your booking for the ${selectedOption} option has been confirmed!</div>`,
          confirmButtonText: 'Close',
          iconHtml: `<img src="${activity.image}" style="width: 60px; height: 60px; border: none; box-shadow: none; border-radius: 0;"/>`,
          customClass: {
            icon: 'custom-icon',
            confirmButton: 'custom-confirm-button'
          },
          buttonsStyling: false,
        }).then(() => {
          const newOrder = {
            id: Date.now(), // Generate a unique ID for the order
            Name: activity.title2,
            items: [{
              id: activity.id,
              title: activity.title2,
              price: activity[selectedOption].price,
              quantity: parseInt(numPeople)
            }],
            totalItems: parseInt(numPeople),
            totalAmount: activity[selectedOption].price * parseInt(numPeople),
            orderStatus: 'Pending',
            startDate: startDate.toISOString(),
            selectedOption: selectedOption,
          };
          addOrder(newOrder);
  
          // Reset form
          setStartDate(null);
          setNumPeople('');
          setSelectedOption('private');
          setPrice(activity.private.price);
          setIsBookingPlaced(false);
        });
      }
    });
  };
  

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setStartDate(null);
    if (activity && activity[option]) {
      setPrice(activity[option].price * (numPeople || 1));
    }
  };

  if (!activity) {
    return <div>Activity not found</div>;
  }

  const isButtonDisabled = !startDate || numPeople === '';

  return (
    <div className='activity'>
      <Link to='/activities' style={{ textDecoration: 'none', color: 'var(--primaryColor)' }}>
        <Arrow />
      </Link>
      <div className='activity-detail'>
        <div className='activity-info'>
        <Carousel images={activity.images} />
          <h2 className='titlea'>{activity.title2}</h2>
          <p className='des'>{activity.longdes}</p>
            </div>

            <div className="option-buttons">
          <button onClick={() => handleOptionChange('private')} className={selectedOption === 'private' ? 'selected' : ''}>
    <h3>Private Tour</h3>
    <p>Price: ${activity.private.price}</p>
  </button>
  <button onClick={() => handleOptionChange('pickup')} className={selectedOption === 'pickup' ? 'selected' : ''}>
    <h3>Hotel Pickup</h3>
    <p>Price: ${activity.pickup.price}</p>
  </button>
  <button onClick={() => handleOptionChange('meeting')} className={selectedOption === 'meeting' ? 'selected' : ''}>
    <h3>Meeting Point</h3>
    <p> Price: ${activity.meeting.price }</p>
  </button>
</div>
          <div className='booking'>
          <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    minDate={weekStart}
                    maxDate={weekEnd}
                    filterDate={filterAvailableDates}
                    customInput={<CustomInput />}
                    dateFormat="yyyy/MM/dd"
                    className="date-picker"
                    placeholderText="Choose Date"
                />
            <div
            ></div>
            <select
              value={numPeople}
              onChange={(e) => setNumPeople(e.target.value)}
              className='people-select'
              disabled={isBookingPlaced}
            >
              <option value='' disabled>Number of people</option>
              {[...Array(10).keys()].map(num => (
                <option key={num + 1} value={num + 1}>
                  {num + 1}
                </option>
              ))}
            </select>
          </div>



          <div className="option-details">
            {selectedOption === 'private' && (
              <div className='pri-box'>
                <h4>{activity.private.description}</h4>
                <div  className='main-include'>
               
               <div className='near-box'>
                 <img src={pic} alt='leave' className='leave'/>
                 <div className='text-container'>
                  <div className='no'>
                   <div>
                   <p>{activity.private.leave.near}</p>
                   <p>{activity.private.leave.near2}</p>
                   
                   </div>
                 <div>
                   <p>{activity.private.leave.near3}</p>
                   <p>{activity.private.leave.near4}</p>
                  </div>
                 </div>
                 </div>
                 </div>
                 <div className='near-box'>
                 <img src={pic} alt='leave' className='leave'/>
                 <div className='text-container'>
             
                 <p>{activity.private.leave.time}</p>
                 </div>
                 </div>
                 <div className='near-box'>
                 <img src={pic} alt='leave' className='leave'/>
                 <div className='text-container'>
                
                 <p>{activity.private.leave.place}</p>
                 </div>
                 </div>
                 <div className='near-box'>
                 <img src={pic} alt='leave' className='leave'/>
                 <div className='text-container'>
               
                
                <p> {activity.pickup.leave.cancel}</p> 
                <p> Group-{activity.private.leave.group}person</p> 
                </div>
               
                </div>
               </div>
               
               
               

                <div className='include-box'>
                  <h4>Includes</h4>
                  <p>✔ {activity.private.include.free1}</p>
                  <p>✔ {activity.private.include.free2}</p>
                  <p>✔ {activity.private.include.free3}</p>
                  <p>✔ {activity.private.include.gift}</p>
                  <h5>Does Not Include</h5>
                  <p>✖ {activity.private.notinclude.notinclude1}</p>
                  <p>✖ {activity.private.notinclude.notinclude2}</p>
                </div>
                <div className='extra'> </div>
              
             
              </div>
            )}
            {selectedOption === 'pickup' && (
                 <div className='pri-box'>
                <h4>{activity.pickup.description}</h4>
                <div  className='main-include'>
               
               <div className='near-box'>
                 <img src={pic} alt='leave' className='leave'/>
                 <div className='text-container'>
                  <div className='no'>
                   <div>
                   <p>{activity.private.leave.near}</p>
                   <p>{activity.private.leave.near2}</p>
                   
                   </div>
                 <div>
                   <p>{activity.private.leave.near3}</p>
                   <p>{activity.private.leave.near4}</p>
                  </div>
                 </div>
                 </div>
                 </div>
                 <div className='near-box'>
                 <img src={pic} alt='leave' className='leave'/>
                 <div className='text-container'>
             
                 <p>{activity.private.leave.time}</p>
                 </div>
                 </div>
                 <div className='near-box'>
                 <img src={pic} alt='leave' className='leave'/>
                 <div className='text-container'>
                
                 <p>{activity.private.leave.place}</p>
                 </div>
                 </div>
                 <div className='near-box'>
                 <img src={pic} alt='leave' className='leave'/>
                 <div className='text-container'>
               
                
                <p> {activity.pickup.leave.cancel}</p> 
                <p> Group-{activity.private.leave.group}person</p> 
                </div>
               
                </div>
               </div>
               
                <div className='include-box' >
                  <h4>Includes</h4>
                  <p>✔ {activity.pickup.include.free1}</p>
                  <p>✔ {activity.pickup.include.free2}</p>
                  <p>✔ {activity.pickup.include.gift}</p>
                  <h5>Does Not Include</h5>
                  <p>✖ {activity.pickup.notinclude.notinclude1}</p>
                  <p>✖ {activity.pickup.notinclude.notinclude2}</p>
                </div>
                <div className='extra'> </div>
                
              </div>
            )}
            {selectedOption === 'meeting' && (
                 <div className='pri-box'>
                <h4>{activity.meeting.description}</h4>
                <div  className='main-include'>
               
               <div className='near-box'>
                 <img src={pic} alt='leave' className='leave'/>
                 <div className='text-container'>
                  <div className='no'>
                   <div>
                   <p>{activity.private.leave.near}</p>
                   <p>{activity.private.leave.near2}</p>
                   
                   </div>
                 <div>
                   <p>{activity.private.leave.near3}</p>
                   <p>{activity.private.leave.near4}</p>
                  </div>
                 </div>
                 </div>
                 </div>
                 <div className='near-box'>
                 <img src={pic} alt='leave' className='leave'/>
                 <div className='text-container'>
             
                 <p>{activity.private.leave.time}</p>
                 </div>
                 </div>
                 <div className='near-box'>
                 <img src={pic} alt='leave' className='leave'/>
                 <div className='text-container'>
                
                 <p>{activity.private.leave.place}</p>
                 </div>
                 </div>
                 <div className='near-box'>
                 <img src={pic} alt='leave' className='leave'/>
                 <div className='text-container'>
               
                
                <p> {activity.pickup.leave.cancel}</p> 
                <p> Group-{activity.private.leave.group}person</p> 
                </div>
               
                </div>
               </div>
               
                <div className='include-box'>
                  <h4>Includes</h4>
                  <p>✔ {activity.meeting.include.free1}</p>
                  <p>✔ {activity.meeting.include.free2}</p>
                  <p>✔ {activity.meeting.include.gift}</p>
                  <h5>Does Not Include</h5>
                  <p>✖ {activity.meeting.notinclude.notinclude2}</p>
                  <p>✖ {activity.meeting.notinclude.notinclude2}</p>
                </div>
                  <div className='extra'> </div>
               
              </div>
            )}
          </div>

          
          <div className="overlay-box">
  <div className="price-row">
    <p>Total Price:</p> 
    <p> ${activity[selectedOption].price * (numPeople || 1)}</p>
  </div>
  <div className="button-row">
    <button
      onClick={handlePlaceBooking}
      disabled={isBookingPlaced || isButtonDisabled}
      className={`booking-button ${isButtonDisabled ? 'disabled' : ''}`}
    >
      {isBookingPlaced ? "Booking Placed" : "Place Booking"}
    </button>
  </div>
</div>

          
      
      
      
      
      </div>


     
    </div>
  );
};

export default ActivityDetail;
