import React, { useState, useEffect } from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import { IoCheckmarkDoneOutline } from 'react-icons/io5';

const ChatItem = ({ conversation, onChatSelect, isActive }) => {
    const [showNotification, setShowNotification] = useState(true);
    const [doubleCheck, setDoubleCheck] = useState(conversation.seen);

    useEffect(() => {
        setDoubleCheck(conversation.seen);
    }, [conversation.seen]);

    useEffect(() => {
        setShowNotification(conversation.unreadCount > 0);
    }, [conversation.unreadCount]);

    const handleNotificationClick = () => {
        setShowNotification(false);
        setDoubleCheck(true);
    };

    const handleClick = () => {
        handleNotificationClick();
        onChatSelect(conversation);
    };

    return (
        <div 
            className={`chat-item ${doubleCheck ? 'grey' : ''} ${isActive ? 'active' : ''}`} 
            onClick={handleClick}
        >
            <div className="chat-avatar">
                <div className="chat-avatar-circle">{conversation.roomNumber}</div>
            </div>
            <div className="chat-details">
                <div className="chat-top-row">
                    <p className="chat-name">
                    {conversation.name}, Room {conversation.roomNumber}
                    </p>
                    {conversation.unreadCount > 0 && showNotification && (
                        <div className='chat-noti-con'>
                            <p className="chat-noti">{conversation.unreadCount}</p>
                        </div>
                    )}
                </div>
                <p className="chat-last-message">{conversation.lastMessage}</p>
                <div className="chat-bottom-row">
                    <div className="chat-time">{conversation.lastMessageTime}</div>
                    {conversation.messageSender === 'HOTEL' && (
                        doubleCheck ? (
                            <IoCheckmarkDoneOutline className="double-check-icon" />
                        ) : (
                            <IoMdCheckmark className="single-check-icon" />
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChatItem;