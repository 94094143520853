import React, { useState } from 'react';
import Swal from 'sweetalert2';
import './SerShow.css';
import checked from '../../Assets/All pics/checked_169780 1.png'

const LaundryServices = ({ service, onSave }) => {
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedWeight, setSelectedWeight] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const handleTypeChange = (type) => {
    setSelectedTypes((prevSelectedTypes) => {
      if (prevSelectedTypes.includes(type)) {
        return prevSelectedTypes.filter(t => t !== type);
      } else if (prevSelectedTypes.length < 4) {
        return [...prevSelectedTypes, type];
      } else {
        return prevSelectedTypes;
      }
    });
  };

  const handleWeightChange = (weight) => {
    setSelectedWeight(prevWeight => {
      const newWeight = prevWeight === weight ? '' : weight;
      const newPrice = service.weights.find(w => w.value === newWeight)?.price || 0;
      setTotalPrice(newPrice);
      return newWeight;
    });
  };
  const handleSave = () => {
    if (selectedTypes.length === 0 || selectedWeight === '') {
      Swal.fire({
        title: 'Error',
        text: 'Please select up to 4 types of clothes and one weight.',
        icon: 'error',
        confirmButtonText: 'Close'
      });
      return;
    }

    Swal.fire({
      title: 'Confirm Selection',
      text: `Are you sure you want to select types: ${selectedTypes.join(', ')} and weight: ${selectedWeight} kg?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Confirm',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button'
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        onSave(service.id, { types: selectedTypes, weight: selectedWeight });

        Swal.fire({
          title: 'Selection Confirmed',
          html: `<div>Your selection of types: ${selectedTypes.join(', ')} clothes weighing ${selectedWeight} kg has been confirmed!</div>`,
          confirmButtonText: 'Close',
          iconHtml: `<img src="${checked}" style="width: 60px; height: 60px; border: none; box-shadow: none; border-radius: 0;"/>`,
          customClass: {
            icon: 'custom-icon',
            confirmButton: 'custom-confirm-button'
          },
          buttonsStyling: false,
        }).then(() => {
          setSelectedTypes([]);
          setSelectedWeight('');
          setTotalPrice(0);
        });
      }
    });
  };

  const isButtonDisabled = selectedTypes.length === 0 || selectedWeight === '';

  return (
    <div className='con-lan'>
      
      < div  >
      <h3  className='title-box' >{service.title}</h3>
      <p className='lan-p'> {service.des}</p>
      </div>
    
      <div className='breakfast-con'>
      <div className='lan-space'></div>
      <label className='br-title'>
            <h3>Type of Clothes:</h3>
            <p>Optional, Max 4</p>
            </label>
            <div className='laundry'>
   
          {service.types.map((type, index) => (
            <div key={index} >
              <div className='lan-check'  onClick={() => handleTypeChange(type.value)}>
              <input
                type="checkbox"
                checked={selectedTypes.includes(type.value)}
                onChange={() => handleTypeChange(type.value)}
                disabled={!selectedTypes.includes(type.value) && selectedTypes.length >= 4}
                onClick={(e) => e.stopPropagation()}
              />
           
              {type.label} 
              <p>0</p>
              </div>
              <hr/>
            </div>
          ))}
    
        </div>


  
        <div className='lan-space'></div>
        <label className='br-title'>
            
           <h3> Approximate Weight:</h3>
            <p>Optional, Max 1</p>
            </label>
        
            <div className='laundry'>
          {service.weights.map((weight, index) => (
            
            <div key={index}  >
               <div  className='lan-check'  onClick={() => handleWeightChange(weight.value)}>
              <input
                type="checkbox"
                checked={selectedWeight === weight.value}
                onChange={() => handleWeightChange(weight.value)}
                onClick={(e) => e.stopPropagation()}
              />
              {weight.label}
              <p>{weight.price}</p>
            </div>
            <hr/>
            </div>
          ))}
       
        </div>
        


         
    <div className='extra'> </div>
      </div>
    
      <div className='overlay-lan'>
      <div className="price-row">
        <p>Total: </p>
        <p>${totalPrice}</p>
       </div>
       <div className='button-row'>
        <button
          className={`bed-button ${isButtonDisabled ? '' : 'active'}`}
          disabled={isButtonDisabled}
          onClick={handleSave}
        >
          Place Order
        </button>
        </div>
        </div>
    </div>
  );
};

export default LaundryServices;
