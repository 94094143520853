import im1 from '../Assets/conference.jpg'
import im2 from '../Assets/golf.jpg'
import im3 from '../Assets/wedding.jpg'
import im4 from '../Assets/kid.jpg'
import im5 from '../Assets/family.jpg'
import im6 from '../Assets/activities.jpg'
 const Activities=[
    {
      id: 1,
      images: [im1, im2, im3, im4, im5],
      image: im1,
      status: 'ON',
      title: 'Day Tour',
      time: '7:00',
      title2: 'Day Tour: Explore Dubai',
      description: 'You can check group tour and tour here to connect',
      star: '4.8',
      longdes: 'Embark on a mesmerizing journey through the heart of Dubai, where modern marvels meet ancient wonders.',
      private: {
        description: 'Private Tour with Hotel Pick-up',
        time: '08:00-16:00',
        price: 100,
        availableDates: ['2024-06-30', '2024-07-01', '2024-07-02'],
        include: {
          free1: 'Entrance ticket for museum',
          free2: 'Entrance Ticket for National Park',
          gift: 'Free flow of Water bottles and juice and ice-cream',
          free3: 'Meals for breakfast and Lunch',
        },
        notinclude: {
          notinclude1: 'Meals for breakfast and Lunch',
          notinclude2: 'Activities fees for Personal Expenses',
        },
        leave: {
          near: 'Around Dubai City',
          near2: 'Market',
          near3: 'Museum',
          near4: 'National Park',
          time: '08:00-16:00',
          place: 'At Hotel Lobby or your Place',
          cancel: 'Free Cancellation(24-hours notice)',
          group: '1 to 6',
        },
      },
      pickup: {
        description: 'Hotel Pickup Description',
        time: '08:00-16:00',
        availableDates: ['2024-06-30', '2024-07-05', '2024-07-08'],
        price: '50.99',
        include: {
          free1: 'Entrance ticket for museum',
          free2: 'Entrance Ticket for National Park',
          gift: 'Free flow of Water bottles and juice and ice-cream',
        },
        notinclude: {
          notinclude1: 'Meals for breakfast and Lunch',
          notinclude2: 'Activities fees for Personal Expenses',
        },
        leave: {
          near: 'Around Dubai City',
          near2: 'Market',
          near3: 'Museum',
          near4: 'National Park',
          time: '08:00-16:00',
          place: 'At Hotel Lobby or your Place',
          cancel: 'Free Cancellation(24-hours notice)',
          group: '1 to 12',
        },
      },
      meeting: {
        description: 'Meeting Point Description',
        time: '08:00-16:00',
        price: 75,
        availableDates: ['2024-06-30', '2024-07-01', '2024-07-02'],
        include: {
          free1: 'Entrance ticket for museum',
          free2: 'Entrance Ticket for National Park',
          gift: 'Free flow of Water bottles and juice and ice-cream',
        },
        notinclude: {
          notinclude1: 'Meals for breakfast and Lunch',
          notinclude2: 'Activities fees for Personal Expenses',
        },
        leave: {
          near: 'Around Dubai City',
          near2: 'Market',
          near3: 'Museum',
          near4: 'National Park',
          time: '08:00,16:00',
          place: 'At Hotel Lobby or your Place',
          cancel: 'Free Cancellation(24-hours notice)',
          group: '1 to 12',
        },
      },
    },
    {
      id: 2,
      images: [im2, im3, im5],
      image: im2,
     
      status: 'ON',
      title: 'Day Tour',
      title2: 'Day Tour: Explore Dubai',
      description: 'You can check group tour and tour here to connect',
      star: '4.2',
      longdes: 'Embark on a mesmerizing journey through the heart of Dubai, where modern marvels meet ancient wonders.',
      private: {
        description: 'Private Tour with Hotel Pick-up',
        availableDates: ['2024-06-30', '2024-07-01', '2024-07-02'],
        time: '08:00-16:00',
        price: 100,
        include: {
          free1: 'Entrance ticket for museum',
          free2: 'Entrance Ticket for National Park',
          gift: 'Free flow of Water bottles and juice and ice-cream',
          free3: 'Meals for breakfast and Lunch',
        },
        notinclude: {
          notinclude1: 'Meals for breakfast and Lunch',
          notinclude2: 'Activities fees for Personal Expenses',
        },
        leave: {
          near: 'Around Dubai City',
          near2: 'Market',
          near3: 'Museum',
          near4: 'National Park',
          time: '08:00-16:00',
          place: 'At Hotel Lobby or your Place',
          cancel: 'Free Cancellation(24-hours notice)',
          group: '1 to 6',
        },
      },
      pickup: {
        description: 'Hotel Pickup Description',
        availableDates: ['2024-06-30', '2024-07-01', '2024-07-02'],
        time: '08:00-16:00',
        price: '35.99',
        include: {
          free1: 'Entrance ticket for museum',
          free2: 'Entrance Ticket for National Park',
          gift: 'Free flow of Water bottles and juice and ice-cream',
        },
        notinclude: {
          notinclude1: 'Meals for breakfast and Lunch',
          notinclude2: 'Activities fees for Personal Expenses',
        },
        leave: {
          near: 'Around Dubai City',
          near2: 'Market',
          near3: 'Museum',
          near4: 'National Park',
          time: '08:00-16:00',
          place: 'At Hotel Lobby or your Place',
          cancel: 'Free Cancellation(24-hours notice)',
          group: '1 to 12',
        },
      },
      meeting: {
        description: 'Meeting Point Description',
        availableDates: ['2024-06-30', '2024-07-01', '2024-07-02'],
        time: '08:00-16:00',
        price: 37,
        include: {
          free1: 'Entrance ticket for museum',
          free2: 'Entrance Ticket for National Park',
          gift: 'Free flow of Water bottles and juice and ice-cream',
        },
        notinclude: {
          notinclude1: 'Meals for breakfast and Lunch',
          notinclude2: 'Activities fees for Personal Expenses',
        },
        leave: {
          near: 'Around Dubai City',
          near2: 'Market',
          near3: 'Museum',
          near4: 'National Park',
          time: '08:00-16:00',
          place: 'At Hotel Lobby or your Place',
          cancel: 'Free Cancellation(24-hours notice)',
          group: '1 to 12',
        },
      },
    },
    {
      id: 3,
      images: [im3, im2, im3, im4, im5],
      image: im3,
    
      status: 'ON',
      title: 'Day Tour',
      title2: 'Day Tour: Explore Dubai',
      description: 'You can check group tour and tour here to connect',
      star: '4.1',
      longdes: 'Embark on a mesmerizing journey through the heart of Dubai, where modern marvels meet ancient wonders.',
      private: {
        description: 'Private Tour with Hotel Pick-up',
        availableDates: ['2024-06-30', '2024-07-01', '2024-07-02'],
        time: '08:00-16:00',
        price: 100,
        include: {
          free1: 'Entrance ticket for museum',
          free2: 'Entrance Ticket for National Park',
          gift: 'Free flow of Water bottles and juice and ice-cream',
          free3: 'Meals for breakfast and Lunch',
        },
        notinclude: {
          notinclude1: 'Meals for breakfast and Lunch',
          notinclude2: 'Activities fees for Personal Expenses',
        },
        leave: {
          near: 'Around Dubai City',
          near2: 'Market',
          near3: 'Museum',
          near4: 'National Park',
          time: '08:00-16:00',
          place: 'At Hotel Lobby or your Place',
          cancel: 'Free Cancellation(24-hours notice)',
          group: '1 to 6',
        },
      },
      pickup: {
        description: 'Hotel Pickup Description',
        availableDates: ['2024-06-30', '2024-07-01', '2024-07-02'],
        time: '08:00-16:00',
        price: '35.99',
        include: {
          free1: 'Entrance ticket for museum',
          free2: 'Entrance Ticket for National Park',
          gift: 'Free flow of Water bottles and juice and ice-cream',
        },
        notinclude: {
          notinclude1: 'Meals for breakfast and Lunch',
          notinclude2: 'Activities fees for Personal Expenses',
        },
        leave: {
          near: 'Around Dubai City',
          near2: 'Market',
          near3: 'Museum',
          near4: 'National Park',
          time: '08:00-16:00',
          place: 'At Hotel Lobby or your Place',
          cancel: 'Free Cancellation(24-hours notice)',
          group: '1 to 12',
        },
      
     },
     meeting: {
      description: 'Meeting Point Description',
      availableDates: ['2024-06-30', '2024-07-01', '2024-07-02'],
      time: '08:00-16:00',
      price: 35,
      include: {
        free1: 'Entrance ticket for museum',
        free2: 'Entrance Ticket for National Park',
        gift: 'Free flow of Water bottles and juice and ice-cream',
      },
      notinclude: {
        notinclude1: 'Meals for breakfast and Lunch',
        notinclude2: 'Activities fees for Personal Expenses',
      },
      leave: {
        near: 'Around Dubai City',
        near2: 'Market',
        near3: 'Museum',
        near4: 'National Park',
        time: '08:00-16:00',
        place: 'At Hotel Lobby or your Place',
        cancel: 'Free Cancellation(24-hours notice)',
        group: '1 to 12',
      },
    },
  },
  {
    id: 4,
    images: [im2, im3, im5],
    image: im2,
    status: 'ON',
    title: 'Day Tour',
    title2: 'Day Tour: Explore Dubai',
    description: 'You can check group tour and tour here to connect',
    star: '4.2',
    longdes: 'Embark on a mesmerizing journey through the heart of Dubai, where modern marvels meet ancient wonders.',
    private: {
      description: 'Private Tour with Hotel Pick-up',
      availableDates: ['2024-06-30', '2024-07-01', '2024-07-02'],
      time: '08:00-16:00',
      price: 100,
      include: {
        free1: 'Entrance ticket for museum',
        free2: 'Entrance Ticket for National Park',
        gift: 'Free flow of Water bottles and juice and ice-cream',
        free3: 'Meals for breakfast and Lunch',
      },
      notinclude: {
        notinclude1: 'Meals for breakfast and Lunch',
        notinclude2: 'Activities fees for Personal Expenses',
      },
      leave: {
        near: 'Around Dubai City',
        near2: 'Market',
        near3: 'Museum',
        near4: 'National Park',
        time: '08:00-16:00',
        place: 'At Hotel Lobby or your Place',
        cancel: 'Free Cancellation(24-hours notice)',
        group: '1 to 6',
      },
    },
    pickup: {
      description: 'Hotel Pickup Description',
      availableDates: ['2024-06-30', '2024-07-01', '2024-07-02'],
      time: '08:00-16:00',
      price: 50,
      include: {
        free1: 'Entrance ticket for museum',
        free2: 'Entrance Ticket for National Park',
        gift: 'Free flow of Water bottles and juice and ice-cream',
      },
      notinclude: {
        notinclude1: 'Meals for breakfast and Lunch',
        notinclude2: 'Activities fees for Personal Expenses',
      },
      leave: {
        near: 'Around Dubai City',
        near2: 'Market',
        near3: 'Museum',
        near4: 'National Park',
        time: '08:00-16:00',
        place: 'At Hotel Lobby or your Place',
        cancel: 'Free Cancellation(24-hours notice)',
        group: '1 to 12',
      },
    },
    meeting: {
      description: 'Meeting Point Description',
      availableDates: ['2024-06-30', '2024-07-01', '2024-07-02'],
      time: '08:00-16:00',
      price: 75,
      include: {
        free1: 'Entrance ticket for museum',
        free2: 'Entrance Ticket for National Park',
        gift: 'Free flow of Water bottles and juice and ice-cream',
      },
      notinclude: {
        notinclude1: 'Meals for breakfast and Lunch',
        notinclude2: 'Activities fees for Personal Expenses',
      },
      leave: {
        near: 'Around Dubai City',
        near2: 'Market',
        near3: 'Museum',
        near4: 'National Park',
        time: '08:00-16:00',
        place: 'At Hotel Lobby or your Place',
        cancel: 'Free Cancellation(24-hours notice)',
        group: '1 to 12',
      },
    },
  },
  {
    id: 5,
    images: [im2, im3, im5],
    image: im2,
    price: '35.99',
    status: 'ON',
    title: 'Day Tour',
    title2: 'Day Tour: Explore Dubai',
    description: 'You can check group tour and tour here to connect',
    star: '4.2',
    longdes: 'Embark on a mesmerizing journey through the heart of Dubai, where modern marvels meet ancient wonders.',
    private: {
      description: 'Private Tour with Hotel Pick-up',
      availableDates: ['2024-06-30', '2024-07-01', '2024-07-02'],
      time: '08:00-16:00',
      price: 100,
      include: {
        free1: 'Entrance ticket for museum',
        free2: 'Entrance Ticket for National Park',
        gift: 'Free flow of Water bottles and juice and ice-cream',
        free3: 'Meals for breakfast and Lunch',
      },
      notinclude: {
        notinclude1: 'Meals for breakfast and Lunch',
        notinclude2: 'Activities fees for Personal Expenses',
      },
      leave: {
        near: 'Around Dubai City',
        near2: 'Market',
        near3: 'Museum',
        near4: 'National Park',
        time: '08:00-16:00',
        place: 'At Hotel Lobby or your Place',
        cancel: 'Free Cancellation(24-hours notice)',
        group: '1 to 6',
      },
    },
    pickup: {
      description: 'Hotel Pickup Description',
      availableDates: ['2024-06-30', '2024-07-01', '2024-07-02'],
      time: '08:00-16:00',
      price: 50,
      include: {
        free1: 'Entrance ticket for museum',
        free2: 'Entrance Ticket for National Park',
        gift: 'Free flow of Water bottles and juice and ice-cream',
      },
      notinclude: {
        notinclude1: 'Meals for breakfast and Lunch',
        notinclude2: 'Activities fees for Personal Expenses',
      },
      leave: {
        near: 'Around Dubai City',
        near2: 'Market',
        near3: 'Museum',
        near4: 'National Park',
        time: '08:00-16:00',
        place: 'At Hotel Lobby or your Place',
        cancel: 'Free Cancellation(24-hours notice)',
        group: '1 to 12',
      },
    },
    meeting: {
      description: 'Meeting Point Description',
      availableDates: ['2024-06-30', '2024-07-01', '2024-07-02'],
      time: '08:00-16:00',
      price: 75,
      include: {
        free1: 'Entrance ticket for museum',
        free2: 'Entrance Ticket for National Park',
        gift: 'Free flow of Water bottles and juice and ice-cream',
      },
      notinclude: {
        notinclude1: 'Meals for breakfast and Lunch',
        notinclude2: 'Activities fees for Personal Expenses',
      },
      leave: {
        near: 'Around Dubai City',
        near2: 'Market',
        near3: 'Museum',
        near4: 'National Park',
        time: '08:00-16:00',
        place: 'At Hotel Lobby or your Place',
        cancel: 'Free Cancellation(24-hours notice)',
        group: '1 to 12',
      },
    },
  },
  {
    id: 6,
    images: [im2, im3, im5],
    image: im2,
    price: '35.99',
    status: 'ON',
    title: 'Day Tour',
    title2: 'Day Tour: Explore Dubai',
    description: 'You can check group tour and tour here to connect',
    star: '4.2',
    longdes: 'Embark on a mesmerizing journey through the heart of Dubai, where modern marvels meet ancient wonders.',
    private: {
      description: 'Private Tour with Hotel Pick-up',
      availableDates: ['2024-06-30', '2024-07-01', '2024-07-02'],
      time: '08:00-16:00',
      price: 100,
      include: {
        free1: 'Entrance ticket for museum',
        free2: 'Entrance Ticket for National Park',
        gift: 'Free flow of Water bottles and juice and ice-cream',
        free3: 'Meals for breakfast and Lunch',
      },
      notinclude: {
        notinclude1: 'Meals for breakfast and Lunch',
        notinclude2: 'Activities fees for Personal Expenses',
      },
      leave: {
        near: 'Around Dubai City',
        near2: 'Market',
        near3: 'Museum',
        near4: 'National Park',
        time: '08:00-16:00',
        place: 'At Hotel Lobby or your Place',
        cancel: 'Free Cancellation(24-hours notice)',
        group: '1 to 6',
      },
    },
    pickup: {
      description: 'Hotel Pickup Description',
      availableDates: ['2024-06-30', '2024-07-01', '2024-07-02'],
      time: '08:00-16:00',
      price: 50,
      include: {
        free1: 'Entrance ticket for museum',
        free2: 'Entrance Ticket for National Park',
        gift: 'Free flow of Water bottles and juice and ice-cream',
      },
      notinclude: {
        notinclude1: 'Meals for breakfast and Lunch',
        notinclude2: 'Activities fees for Personal Expenses',
      },
      leave: {
        near: 'Around Dubai City',
        near2: 'Market',
        near3: 'Museum',
        near4: 'National Park',
        time: '08:00-16:00',
        place: 'At Hotel Lobby or your Place',
        cancel: 'Free Cancellation(24-hours notice)',
        group: '1 to 12',
      },
    },
    meeting: {
      description: 'Meeting Point Description',
      availableDates: ['2024-06-30', '2024-07-01', '2024-07-02'],
      time: '08:00-16:00',
      price: 75,
      include: {
        free1: 'Entrance ticket for museum',
        free2: 'Entrance Ticket for National Park',
        gift: 'Free flow of Water bottles and juice and ice-cream',
      },
      notinclude: {
        notinclude1: 'Meals for breakfast and Lunch',
        notinclude2: 'Activities fees for Personal Expenses',
      },
      leave: {
        near: 'Around Dubai City',
        near2: 'Market',
        near3: 'Museum',
        near4: 'National Park',
        time: '08:00-16:00',
        place: 'At Hotel Lobby or your Place',
        cancel: 'Free Cancellation(24-hours notice)',
        group: '1 to 12',
      },
    },
  },
    
 ]
 export default Activities;