import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

const placeholderImage = 'https://sansara-restaurant.s3.ap-southeast-1.amazonaws.com/hotel7/d3386c14-069d-4f0a-b65e-59ff6e20380e_dish-svgrepo-com.png';
const token = localStorage.getItem('hotelToken');

const EditAmenity = ({ amenity, onEditAmenity, onClose, onDeleteAmenity }) => {
  const [formData, setFormData] = useState({
    id: amenity.id,
    title: amenity.title,
    description: amenity.description,
    imageLink: amenity.imageLink || placeholderImage,
    available: amenity.available === 'ON' ? 'Available' : 'Not Available',
    newPhotoFile: null,
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const initialClickElement = useRef(null);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    setFormData({
      id: amenity.id,
      title: amenity.title,
      description: amenity.description,
      imageLink: amenity.imageLink || placeholderImage,
      available: amenity.available === 'ON' ? 'Available' : 'Not Available',
      newPhotoFile: null,
    });
  }, [amenity]);

  const compressImage = (file, maxWidth, maxHeight, quality) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            resolve(new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            }));
          }, 'image/jpeg', quality);
        };
      };
    });
  };

  const handleChange = async (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      const file = files[0];
      if (file) {
        try {
          const compressedFile = await compressImage(file, 1200, 1200, 0.7);
          setFormData((prevData) => ({
            ...prevData,
            imageLink: URL.createObjectURL(compressedFile),
            newPhotoFile: compressedFile,
          }));
        } catch (error) {
          console.error('Error compressing image:', error);
          // Fallback to original file if compression fails
          setFormData((prevData) => ({
            ...prevData,
            imageLink: URL.createObjectURL(file),
            newPhotoFile: file,
          }));
        }
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleDeleteImage = () => {
    setFormData((prevData) => ({
      ...prevData,
      imageLink: null,
      newPhotoFile: null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const hotelId = localStorage.getItem('hotelId');

    const amenityDto = {
      id: formData.id,
      title: formData.title,
      description: formData.description,
      hotelId: hotelId,
      available: formData.available === 'Available' ? 1 : 0,
      imageLink: formData.imageLink === null ? null : amenity.imageLink,
    };

    const formDataToSend = new FormData();
    formDataToSend.append('amenityDto', JSON.stringify(amenityDto));
    if (formData.newPhotoFile) {
      formDataToSend.append('file', formData.newPhotoFile);
    }

    try {
      await axios.put(process.env.REACT_APP_API_BASE_URL + '/amenity/update', formDataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          hotelId: hotelId,
        },
      });

      const updatedImageLink = formData.imageLink === null ? placeholderImage : formData.imageLink;
      onEditAmenity({ ...amenityDto, imageLink: updatedImageLink, available: amenityDto.available === 1 ? 'ON' : 'OFF' });
      onClose();
    } catch (error) {
      console.error('Failed to update amenity', error);
    }
  };

  const handleDelete = async () => {
    if (isDeleting) return;
    setIsDeleting(true);

    try {
      await axios.delete(process.env.REACT_APP_API_BASE_URL + `/amenity/${formData.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      onDeleteAmenity(formData.id);
      onClose();
    } catch (error) {
      console.error('Failed to delete amenity', error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleConfirmDelete = () => {
    setShowConfirmModal(true);
  };

  const handleCancelDelete = () => {
    setShowConfirmModal(false);
  };

  const handleConfirmYes = () => {
    handleDelete();
    setShowConfirmModal(false);
  };

  const handleBackgroundMouseDown = (e) => {
    initialClickElement.current = e.target;
  };

  const handleBackgroundMouseUp = (e) => {
    if (e.target.className === 'main-room' && initialClickElement.current === e.target) {
      onClose();
    }
    initialClickElement.current = null;
  };

  return (
    <div className='main-room' onMouseDown={handleBackgroundMouseDown} onMouseUp={handleBackgroundMouseUp}>
      <div className="add-room-options">
        <div className='added-box'>
          <h3>Edit Amenity</h3>
          <button className="close" onClick={onClose}>×</button>
        </div>
        <div className="room-form m2 edit">
          <form onSubmit={handleSubmit}>
            <div className="room-input-container cap1">
              <input type="text" name="title" value={formData.title} onChange={handleChange} required />
              <label className={formData.title ? 'floating' : ''}>Title</label>
            </div>
            <div className="room-input-container cap1">
              <input type="text" name="description" value={formData.description} onChange={handleChange} required />
              <label className={formData.description ? 'floating' : ''}>Description</label>
            </div>
            <div className="room-input-container cap1">
              <select name="available" value={formData.available} onChange={handleChange} required>
                <option value="Available">Available</option>
                <option value="Not Available">Not Available</option>
              </select>
              <label className="floating">Availability</label>
            </div>
            <div className="">
              {formData.imageLink && (
                <div className="itemcon" style={{ position: 'relative' }}>
                  <img src={formData.imageLink} alt="Current Photo" className="photo-preview" />
                  <button
                    type="button"
                    className="delete-image-button"
                    onClick={handleDeleteImage}
                  >
                    &ndash;
                  </button>
                </div>
              )}
              <label htmlFor="file-input" className="file-input-label">
                {formData.imageLink ? 'Change Image' : 'Add Image'}
              </label>
              <input
                type="file"
                name="imageLink"
                accept="image/*"
                id="file-input"
                className="file-input"
                onChange={handleChange}
              />
            </div>
            <hr />
            <div className="button-bar">
              <button type="button" className="delete-button" onClick={handleConfirmDelete}>Delete</button>
              <button type="submit" className="save-btn">Save</button>
            </div>
          </form>
        </div>
      </div>

      {showConfirmModal && (
        <div className="modal" onClick={handleCancelDelete}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>Are you sure you want to delete this amenity?</h3>
            <div className="button-bar">
              <button onClick={handleConfirmYes} className="delete-button">Yes</button>
              <button onClick={handleCancelDelete} className="save-btn">No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditAmenity;