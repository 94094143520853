import React, { useState } from 'react';

const AdminLaundry = ({ typesWeightsPricesData, onTypesWeightsPricesChange, onClose }) => {
  const [formData, setFormData] = useState(typesWeightsPricesData || { types: [], weights: [] });

  const handleTypesChange = (e) => {
    const { value } = e.target;
    const newTypes = value.split('\n').map(line => ({ label: line.trim() }));
    setFormData({ ...formData, types: newTypes });
    onTypesWeightsPricesChange({ ...formData, types: newTypes });
  };

  const handleWeightsPricesChange = (e) => {
    const { value } = e.target;
    const newWeights = value.split('\n').map(line => {
      const [label, price] = line.split(':').map(part => part.trim());
      return { label, price };
    });
    setFormData({ ...formData, weights: newWeights });
    onTypesWeightsPricesChange({ ...formData, weights: newWeights });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onTypesWeightsPricesChange(formData);
  };

  return (
    <form onSubmit={handleFormSubmit} style={{ width: '100%' }}>
      <div className="room-input-container cap1">
        <textarea
         style={{ width: '97%' }}
          name="types"
          value={formData.types.length > 0 ? formData.types.map(type => type.label).join('\n') : ''}
          onChange={handleTypesChange}
        />
        <label className={formData.types.length > 0 ? 'floating' : ''}>
          Types of clothes
        </label>
      </div>

      <div className="room-input-container cap1">
        <textarea
         style={{ width: '97%' }}
          name="weightsPrices"
          value={formData.weights.length > 0 ? formData.weights.map(weight => `${weight.label}:${weight.price}`).join('\n') : ''}
          onChange={handleWeightsPricesChange}
        />
        <label className={formData.weights.length > 0 ? 'floating' : ''}>
          Weights and Prices
        </label>
      </div>
      <hr />
      <div className="button-bar">
        <button type="submit" className="save-btn">Save</button>
        <button type="button" onClick={onClose} className="delete-button">Cancel</button>
      </div>
    </form>
  );
};

export default AdminLaundry;
