import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = ({ setShowThankYou }) => {
  const [formData, setFormData] = useState({
    email: '',
    hotelInput: ''
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.email.trim()) errors.email = 'Email is required.';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) errors.email = 'Please enter a valid email address.';
    if (!formData.hotelInput.trim()) errors.hotelInput = 'Hotel Name is required.';
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      emailjs.sendForm('service_f5rg6vw', 'template_bgkxl28', e.target, 'c7FJ1DtBlrMqah-95')
        .then((result) => {
          console.log('Email sent:', result.text);
          setShowThankYou(true);
        }, (error) => {
          console.error('Error sending email:', error);
          alert('An error occurred while sending the email. Please try again later.');
        });
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <form id="myForm" onSubmit={handleSubmit}>
      <h1>Free use until December 31, 2024</h1>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} placeholder="Enter Your Email" required />
        {errors.email && <small className="error-msg" style={{color: 'red'}}>{errors.email}</small>}
      </div>
      <div className="form-group">
        <label htmlFor="hotelInput">Hotel Name</label>
        <input type="text" id="hotelInput" name="hotelInput" value={formData.hotelInput} onChange={handleChange} placeholder="Enter Your Hotel Name" required />
        {errors.hotelInput && <small className="error-msg" style={{color: 'red'}}>{errors.hotelInput}</small>}
      </div>
      <button type="submit" className="get" id="submitBtn">Sign up</button>
    </form>
  );
};

export default ContactForm;