import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './Form.css';

const BreakfastInBedForm = ({ serviceId, onSubmit, onClose, isEditing, initialFormData, onDeleteService }) => {
    const [formData, setFormData] = useState({
        name: 'Breakfast in Bed',
        description: '',
        available: 'Available',
        price: 0,
        currency: 'USD',
        additionalOptions: {
            serviceStartTime: '07:00 AM',
            serviceEndTime: '10:00 AM',
        },
    });

    const [activeBreakfastTypeIndex, setActiveBreakfastTypeIndex] = useState(0);
    const [activeCategoryIndex, setActiveCategoryIndex] = useState(0);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [errors, setErrors] = useState({});
    const initialClickElement = useRef(null);
    const token = localStorage.getItem('hotelToken');
    const hotelId = localStorage.getItem('hotelId');

    useEffect(() => {
        if (isEditing && initialFormData) {
            setFormData({
                ...initialFormData,
                available: initialFormData.available === 1 ? 'Available' : 'Not Available',
                additionalOptions: initialFormData.additionalOptions || {
                    serviceStartTime: '07:00 AM',
                    serviceEndTime: '10:00 AM',
                },
            });
        }
    }, [isEditing, initialFormData]);

    const addBreakfastType = () => {
        const newBreakfastTypeName = `Breakfast Type ${Object.keys(formData.additionalOptions).length - 1}`;
        setFormData((prevData) => ({
            ...prevData,
            additionalOptions: {
                ...prevData.additionalOptions,
                [newBreakfastTypeName]: { name: newBreakfastTypeName, price: 0, currency: 'USD', categories: {} },
            },
        }));
        // Устанавливаем индекс активной вкладки на вновь созданный тип завтрака
        setActiveBreakfastTypeIndex(breakfastTypeNames.length);
    };

    const addCategory = (breakfastTypeName) => {
        const newCategoryName = `Category ${Object.keys(formData.additionalOptions[breakfastTypeName].categories).length + 1}`;
        setFormData((prevData) => ({
            ...prevData,
            additionalOptions: {
                ...prevData.additionalOptions,
                [breakfastTypeName]: {
                    ...prevData.additionalOptions[breakfastTypeName],
                    categories: {
                        ...prevData.additionalOptions[breakfastTypeName].categories,
                        [newCategoryName]: { name: newCategoryName, items: [], optional_max: 1 },
                    },
                },
            },
        }));
        setActiveCategoryIndex(categoryNames.length);
    };

    const addItem = (breakfastTypeName, categoryName) => {
        setFormData((prevData) => ({
            ...prevData,
            additionalOptions: {
                ...prevData.additionalOptions,
                [breakfastTypeName]: {
                    ...prevData.additionalOptions[breakfastTypeName],
                    categories: {
                        ...prevData.additionalOptions[breakfastTypeName].categories,
                        [categoryName]: {
                            ...prevData.additionalOptions[breakfastTypeName].categories[categoryName],
                            items: [...prevData.additionalOptions[breakfastTypeName].categories[categoryName].items, { name: '' }],
                        },
                    },
                },
            },
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'serviceStartTime' || name === 'serviceEndTime') {
            setFormData((prevData) => ({
                ...prevData,
                additionalOptions: {
                    ...prevData.additionalOptions,
                    [name]: value,
                },
            }));
        } else if (name.startsWith('breakfastType.')) {
            const parts = name.split('.');
            const breakfastTypeName = parts[1];
            if (parts[2] === 'name') {
                setFormData((prevData) => {
                    const updatedAdditionalOptions = { ...prevData.additionalOptions };
                    updatedAdditionalOptions[breakfastTypeName].name = value;
                    return { ...prevData, additionalOptions: updatedAdditionalOptions };
                });
            } else if (parts[2] === 'price') {
                setFormData((prevData) => {
                    const updatedAdditionalOptions = { ...prevData.additionalOptions };
                    updatedAdditionalOptions[breakfastTypeName].price = parseFloat(value);
                    return { ...prevData, additionalOptions: updatedAdditionalOptions };
                });
            } else if (parts[2] === 'currency') {
                setFormData((prevData) => {
                    const updatedAdditionalOptions = { ...prevData.additionalOptions };
                    updatedAdditionalOptions[breakfastTypeName].currency = value;
                    return { ...prevData, additionalOptions: updatedAdditionalOptions };
                });
            }
        } else if (name.startsWith('category.')) {
            const parts = name.split('.');
            const breakfastTypeName = parts[1];
            const categoryName = parts[2];

            if (parts[3] === 'name') {
                setFormData((prevData) => {
                    const updatedAdditionalOptions = { ...prevData.additionalOptions };
                    updatedAdditionalOptions[breakfastTypeName].categories[categoryName].name = value;
                    return { ...prevData, additionalOptions: updatedAdditionalOptions };
                });
            } else if (parts[3] === 'optional_max') {
                setFormData((prevData) => {
                    const updatedAdditionalOptions = { ...prevData.additionalOptions };
                    updatedAdditionalOptions[breakfastTypeName].categories[categoryName].optional_max = parseInt(value);
                    return { ...prevData, additionalOptions: updatedAdditionalOptions };
                });
            }
        } else if (name.startsWith('item.')) {
            const parts = name.split('.');
            const breakfastTypeName = parts[1];
            const categoryName = parts[2];
            const itemIndex = parseInt(parts[3]);
            const fieldName = parts[4];

            setFormData((prevData) => {
                const updatedAdditionalOptions = { ...prevData.additionalOptions };
                updatedAdditionalOptions[breakfastTypeName].categories[categoryName].items[itemIndex][fieldName] = value;
                return { ...prevData, additionalOptions: updatedAdditionalOptions };
            });
        } else if (name.startsWith('optional_max.')) {
            const parts = name.split('.');
            const breakfastTypeName = parts[1];
            const categoryName = parts[2];

            setFormData((prevData) => {
                const updatedAdditionalOptions = { ...prevData.additionalOptions };
                updatedAdditionalOptions[breakfastTypeName].categories[categoryName].optional_max = parseInt(value) || 0;
                return { ...prevData, additionalOptions: updatedAdditionalOptions };
            });
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }

        setErrors({ ...errors, [name]: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isValidStartTime = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9] (AM|PM)$/.test(formData.additionalOptions.serviceStartTime);
        const isValidEndTime = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9] (AM|PM)$/.test(formData.additionalOptions.serviceEndTime);

        const newErrors = {};

        if (!isValidStartTime) {
            newErrors.serviceStartTime = 'Incorrect time format. Please use HH:MM AM/PM.';
        }

        if (!isValidEndTime) {
            newErrors.serviceEndTime = 'Incorrect time format. Please use HH:MM AM/PM.';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        const payload = {
            hotelId: hotelId,
            serviceId: serviceId,
            ...formData,
            available: formData.available === 'Available' ? 1 : 0,
        };

        try {
            let response;
            if (isEditing) {
                const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/hotel-service/update`;
                response = await axios.put(apiUrl, payload, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        hotelId: hotelId,
                    },
                });
            } else {
                const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/hotel-service/add`;
                response = await axios.post(apiUrl, payload, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        hotelId: hotelId,
                    },
                });
            }

            onSubmit(response.data);
            onClose();
        } catch (error) {
            console.error('Error adding/editing Breakfast in Bed service:', error);
        }
    };

    const handleBackgroundMouseDown = (e) => {
        initialClickElement.current = e.target;
    };

    const handleBackgroundMouseUp = (e) => {
        if (e.target.className === 'main-room' && initialClickElement.current === e.target) {
            onClose();
        }
        initialClickElement.current = null;
    };

    const handleDelete = async () => {
        if (isDeleting) return;
        setIsDeleting(true);

        try {
            await axios.delete(process.env.REACT_APP_API_BASE_URL + `/hotel-service/${formData.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            onDeleteService(formData.id);
            onClose();
        } catch (error) {
            console.error('Failed to delete amenity', error);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleConfirmDelete = () => {
        setShowConfirmModal(true);
    };

    const handleCancelDelete = () => {
        setShowConfirmModal(false);
    };

    const handleConfirmYes = () => {
        handleDelete();
        setShowConfirmModal(false);
    };

    const setSafeActiveBreakfastTypeIndex = (index) => {
        const maxIndex = breakfastTypeNames.length - 1;
        setActiveBreakfastTypeIndex(Math.max(0, Math.min(index, maxIndex)));
    };

    const setSafeActiveCategoryIndex = (index) => {
        const maxIndex = categoryNames.length - 1;
        setActiveCategoryIndex(Math.max(0, Math.min(index, maxIndex)));
    };

    const breakfastTypeNames = Object.keys(formData.additionalOptions).filter((key) => key !== 'serviceStartTime' && key !== 'serviceEndTime');
    const activeBreakfastTypeName = breakfastTypeNames[activeBreakfastTypeIndex] || '';
    const categoryNames = activeBreakfastTypeName ? Object.keys(formData.additionalOptions[activeBreakfastTypeName].categories) : [];
    const activeCategoryName = categoryNames[activeCategoryIndex] || '';


    return (
        <div className="main-room" onMouseDown={handleBackgroundMouseDown} onMouseUp={handleBackgroundMouseUp}>
            <div className="add-room-options">
                <div className="added-box">
                    <h3>{isEditing ? 'Edit Breakfast in Bed' : 'Add Breakfast in Bed'}</h3>
                    <button className="close" onClick={onClose}>
                        ×
                    </button>
                </div>
                <div className="room-form m2 edit">
                    <form onSubmit={handleSubmit}>
                        <div className="room-input-container cap1">
                            <input type="text" name="name" value={formData.name} readOnly />
                            <label className="floating">Name</label>
                        </div>
                        <div className="room-input-container cap1">
                            <select name="available" value={formData.available} onChange={handleChange} required>
                                <option value="Available">Available</option>
                                <option value="Not Available">Not Available</option>
                            </select>
                            <label className="floating">Availability</label>
                        </div>

                        <div className="room-input-container cap1">
                            <input type="text" name="serviceStartTime" value={formData.additionalOptions.serviceStartTime} onChange={handleChange} required />
                            <label className="floating">Service is provided from</label>
                            {errors.serviceStartTime && <span className="error-message">{errors.serviceStartTime}</span>}
                        </div>
                        <div className="room-input-container cap1">
                            <input type="text" name="serviceEndTime" value={formData.additionalOptions.serviceEndTime} onChange={handleChange} required />
                            <label className="floating">to</label>
                            {errors.serviceEndTime && <span className="error-message">{errors.serviceEndTime}</span>}
                        </div>

                        <h3>Breakfast Types</h3>
                        <button type="button" onClick={addBreakfastType}>
                            Add Breakfast Type
                        </button>

                        <div className="breakfast-type-tabs">
                            {breakfastTypeNames.map((breakfastTypeName, index) => (
                                <button key={index} type="button" className={`tab ${index === activeBreakfastTypeIndex ? 'active' : ''}`} onClick={() => setSafeActiveBreakfastTypeIndex(index)}>
                                    {formData.additionalOptions[breakfastTypeName].name}
                                </button>
                            ))}
                        </div>

                        {breakfastTypeNames.length > 0 && (
                            <div>
                                <div className="room-input-container cap1">
                                    <input type="text" name={`breakfastType.${activeBreakfastTypeName}.name`} value={formData.additionalOptions[activeBreakfastTypeName].name} onChange={handleChange} required />
                                    <label className={formData.additionalOptions[activeBreakfastTypeName].name ? 'floating' : ''}>Breakfast Type Name</label>
                                </div>

                                <div className="room-input-container cap1">
                                    <input type="number" name={`breakfastType.${activeBreakfastTypeName}.price`} value={formData.additionalOptions[activeBreakfastTypeName].price} onChange={handleChange} required />
                                    <label className="floating">Price</label>
                                </div>
                                <div className="room-input-container cap1">
                                    <select name={`breakfastType.${activeBreakfastTypeName}.currency`} value={formData.additionalOptions[activeBreakfastTypeName].currency} onChange={handleChange} required>
                                        <option value="USD">USD</option>
                                        <option value="EUR">EUR</option>
                                        <option value="AED">AED</option>
                                    </select>
                                    <label className="floating">Currency</label>
                                </div>

                                <h4>Categories</h4>
                                <button type="button" onClick={() => addCategory(activeBreakfastTypeName)}>
                                    Add Category
                                </button>

                                <div className="category-tabs">
                                    {categoryNames.map((categoryName, index) => (
                                        <button key={index} type="button" className={`tab ${index === activeCategoryIndex ? 'active' : ''}`} onClick={() => setSafeActiveCategoryIndex(index)}>
                                            {formData.additionalOptions[activeBreakfastTypeName].categories[categoryName].name}
                                        </button>
                                    ))}
                                </div>

                                {categoryNames.length > 0 && (
                                    <div>
                                        <div className="room-input-container cap1">
                                            <input
                                                type="text"
                                                name={`category.${activeBreakfastTypeName}.${activeCategoryName}.name`}
                                                value={formData.additionalOptions[activeBreakfastTypeName].categories[activeCategoryName].name}
                                                onChange={handleChange}
                                                required
                                            />
                                            <label className={formData.additionalOptions[activeBreakfastTypeName].categories[activeCategoryName].name ? 'floating' : ''}>Category Name</label>
                                        </div>

                                        <div className="room-input-container cap1">
                                            <input
                                                type="number"
                                                name={`optional_max.${activeBreakfastTypeName}.${activeCategoryName}`}
                                                value={formData.additionalOptions[activeBreakfastTypeName].categories[activeCategoryName].optional_max || ''}
                                                onChange={handleChange}
                                                required
                                            />
                                            <label className="floating">Optional Max (for all Items)</label>
                                        </div>

                                        <h5>Items</h5>
                                        <button type="button" onClick={() => addItem(activeBreakfastTypeName, activeCategoryName)}>
                                            Add Item
                                        </button>

                                        {formData.additionalOptions[activeBreakfastTypeName].categories[activeCategoryName].items.map((item, itemIndex) => (
                                            <div key={itemIndex}>
                                                <div className="room-input-container cap1">
                                                    <input type="text" name={`item.${activeBreakfastTypeName}.${activeCategoryName}.${itemIndex}.name`} value={item.name} onChange={handleChange} required />
                                                    <label className={item.name ? 'floating' : ''}>Item Name</label>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}

                        <hr />
                        <div className="button-bar">
                            {isEditing && (
                                <button type="button" className="delete-button" onClick={handleConfirmDelete}>
                                    Delete
                                </button>
                            )}
                            <button type="submit" className="save-btn">
                                {isEditing ? 'Save' : 'Add Service'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {showConfirmModal && (
                <div className="modal" onClick={handleCancelDelete}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <h3>Are you sure you want to delete this amenity?</h3>
                        <div className="button-bar">
                            <button onClick={handleConfirmYes} className="delete-button">
                                Yes
                            </button>
                            <button onClick={handleCancelDelete} className="save-btn">
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BreakfastInBedForm;