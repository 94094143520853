import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Arrow from '../../ArrowB/Arrow.jsx';
import { Link } from 'react-router-dom';
import './Facilities.css';
import ContentLoader from "react-content-loader"

const Facilities = () => {
  const [amenities, setAmenities] = useState([]);
  const [loading, setLoading] = useState(true);
  const hasFetched = useRef(false); // Ref to control the first fetch

  useEffect(() => {
    const fetchAmenities = async () => {
      const token = localStorage.getItem('guestToken');
      const hotelId = localStorage.getItem('hotelId');
      try {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/amenity/list`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'hotelId': hotelId
          },
          params: {
            tag: 'guest',
            page: 0,
            size: 50
          }
        });
        setAmenities(response.data);
      } catch (error) {
        console.error('Error fetching amenities', error);
      } finally {
        setLoading(false);
      }
    };

    if (!hasFetched.current) { // Check if fetch has already been done
      fetchAmenities();
      hasFetched.current = true; // Mark fetch as done
    }
  }, []); // Empty dependency array to run only once

  if (loading) {
    return (
      <div>
      <Link to='/home' style={{ textDecoration: 'none', color: 'var(--primaryColor)' }}>
        <Arrow />
      </Link>
      <div className='fac'>
        <div className='faci-grid'>
          {[...Array(6)].map((_, index) => (
            <ContentLoader key={index} viewBox="0 0 160 250" height={250} width={160} backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
              <rect x="0" y="0" rx="8" ry="8" width="100%" height="185" />
              <rect x="10" y="195" rx="4" ry="4" width="180" height="15" />
              <rect x="10" y="215" rx="3" ry="3" width="150" height="10" />
            </ContentLoader>
          ))}
          <div className='extra'></div> 
        </div>
      </div>
      </div>
    );
  }

  return (
    <div>
      <Link to='/home' style={{ textDecoration: 'none', color: 'var(--primaryColor)' }}>
        <Arrow />
      </Link>
      <div className='fac'>
        <div className='faci-grid'>
          {amenities.map((item) => (
            <div key={item.id} className='faci-item '>
              <div className='image-container'>
                <img src={item.imageLink} alt={item.title} />
              </div>
              <div className='faci'>
                <h5>{item.title}</h5>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
           <div className='extra'></div>
        </div>
      </div>
    </div>
  );
};

export default Facilities;
