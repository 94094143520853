import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';

const FAQ = () => {
    return (
        <div className='rooms-container' id='rooms-container'>
            <div className='bar2'>
                <h1>FAQ</h1>
            </div>
        </div>
    )};

export default FAQ;
