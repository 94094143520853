import img1 from '../../Assets/chatmessage.png'
import img2 from '../../Assets/orders.png'
import img3 from '../../Assets/sales.png'
import img4 from '../../Assets/guests.png'


const generateRandomData = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

// Helper function to get the number of days in a month
const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate();

const generateChartData = (dataKey, minDaily, maxDaily) => {
    const currentYear = new Date().getFullYear();
  
    // Get the number of days for each month in the current year
    const daysInMonths = Array.from({ length: 12 }, (_, i) => getDaysInMonth(currentYear, i + 1));
  
    return {
      daily: Array.from({ length: 31 }, (_, i) => ({
        date: (i + 1).toString().padStart(2, '0'), // Format days as "01", "02", ..., "31"
        [dataKey]: generateRandomData(minDaily, maxDaily),
      })),
      weekly: Array.from({ length: 7 }, (_, i) => ({
        date: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][i],
        [dataKey]: generateRandomData(minDaily * 7, maxDaily * 7),
      })),
      monthly: daysInMonths.map((daysInMonth, i) => ({
        date: (i + 1).toString().padStart(2, '0'), // Format months as "01", "02", ..., "12"
        [dataKey]: generateRandomData(minDaily * daysInMonth, maxDaily * daysInMonth),
      })),
      yearly: Array.from({ length: 5 }, (_, i) => ({
        date: (currentYear - i).toString(), // Format years as "2024", "2023", ..., "2020"
        [dataKey]: generateRandomData(minDaily * 365, maxDaily * 365),
      })),
      yearlySpecific: ['2022', '2023', '2024'].map(year => ({
        date: year, // Specific years
        [dataKey]: generateRandomData(minDaily * 365, maxDaily * 365),
      }))
    };
  };
  
// Example usage:
const mockChartData = generateChartData('sales', 100, 500);


console.log(mockChartData);

// Overview section with four boxes
export const overviewStats = {
  title: "Overview",
  boxes: [
    { title: "Total Sales", value: 5000,image :img3, change: 10, periodData: { monthly: 1500, weekly: 350, yesterday: 50 } },
    { title: "Total Guests", value: 300,image :img4, change: -3, periodData: { monthly: 100, weekly: 30, yesterday: 10 } },
    { title: "Total Activities", value: 200,image :img2,  change: -5, periodData: { monthly: 80, weekly: 20, yesterday: 5 } },
    { title: "Total Messages", value: 400, image :img1,  change: -7, periodData: { monthly: 120, weekly: 35, yesterday: 10 } }
  ],
  chartData: generateChartData('sales', 100, 500)
};

// Restaurant section with three boxes
export const restaurantStats = {
  title: "Restaurant",
  boxes: [
    { title: "Total Sales",image :img3, value: 2000, change: 8, periodData: { monthly: 600, weekly: 150, yesterday: 40 } },
    { title: "Total Guests",image :img4, value: 100, change: 5, periodData: { monthly: 50, weekly: 15, yesterday: 5 } }
  ],
  chartData: generateChartData('sales', 50, 200)
};

// Guest section with two boxes
export const guestStats = {
  title: "Guest",
  boxes: [
    { title: "Total Guests",image :img3, value: 300, change: -3, periodData: { monthly: 100, weekly: 30, yesterday: 10 } },
    { title: "Guest Satisfaction",image :img3, value: 90, change: 2 }
  ],
  chartData: generateChartData('sales', 5, 30)
};

// Service section with two boxes
export const serviceStats = {
  title: "Service",
  boxes: [
    { title: "Total Services", value: 150, change: 12, periodData: { monthly: 50, weekly: 15, yesterday: 5 } }
  ],
  chartData: generateChartData('sales', 3, 15)
};

// Message section with one box
export const messageStats = {
  title: "Messages",
  boxes: [
    { title: "Total Messages", value: 400, change: -7, periodData: { monthly: 120, weekly: 35, yesterday: 10 } }
  ],
  chartData: generateChartData('sales', 10, 50)
};

// Activity section with two boxes
export const activityStats = {
  title: "Activity",
  boxes: [
    { title: "Total Activities",  image :img1,value: 200, change: -5, periodData: { monthly: 80, weekly: 20, yesterday: 5 } }
  ],
  chartData: generateChartData('sales', 5, 25)
};

// Export the complete mock data object
const mockData = {
  overviewStats,
  restaurantStats,
  guestStats,
  serviceStats,
  messageStats,
  activityStats,
};

export default mockData;