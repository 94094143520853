// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hotel-details-container {
    padding: 30px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    display: inline-block;
    margin-left: 120px;
    background-color: white;
    cursor: pointer;
  }
  
  .hotel-details-item {
    border: none; /* Удалите border */
    padding: 0; /* Удалите padding */
    margin-bottom: 15px;
    display: flex; /* Используем flexbox */
    flex-direction: column; /* Располагаем элементы вертикально */
  }

.hotel-details-item strong {
    display: inline;
    margin-bottom: 10px;
}

.hotel-field {
    display: flex; /* Используем flexbox для каждого поля */
    align-items: baseline; /* Выравниваем элементы по базовой линии */
  }
  
  .hotel-field strong {
    margin-right: 5px; /* Добавляем отступ между полем и значением */
  }`, "",{"version":3,"sources":["webpack://./src/Component/Assets/MainAdminLAyout/HotelProfile/HotelProfile.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,qBAAqB;IACrB,kBAAkB;IAClB,uBAAuB;IACvB,eAAe;EACjB;;EAEA;IACE,YAAY,EAAE,mBAAmB;IACjC,UAAU,EAAE,oBAAoB;IAChC,mBAAmB;IACnB,aAAa,EAAE,uBAAuB;IACtC,sBAAsB,EAAE,qCAAqC;EAC/D;;AAEF;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,aAAa,EAAE,wCAAwC;IACvD,qBAAqB,EAAE,0CAA0C;EACnE;;EAEA;IACE,iBAAiB,EAAE,6CAA6C;EAClE","sourcesContent":[".hotel-details-container {\n    padding: 30px;\n    border: 1px solid #e0e0e0;\n    border-radius: 10px;\n    display: inline-block;\n    margin-left: 120px;\n    background-color: white;\n    cursor: pointer;\n  }\n  \n  .hotel-details-item {\n    border: none; /* Удалите border */\n    padding: 0; /* Удалите padding */\n    margin-bottom: 15px;\n    display: flex; /* Используем flexbox */\n    flex-direction: column; /* Располагаем элементы вертикально */\n  }\n\n.hotel-details-item strong {\n    display: inline;\n    margin-bottom: 10px;\n}\n\n.hotel-field {\n    display: flex; /* Используем flexbox для каждого поля */\n    align-items: baseline; /* Выравниваем элементы по базовой линии */\n  }\n  \n  .hotel-field strong {\n    margin-right: 5px; /* Добавляем отступ между полем и значением */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
