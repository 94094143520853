import React, { useState, useEffect } from 'react';
import './Authorize.css';
import { useNavigate } from 'react-router-dom';
import logo from '../../../Assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import ForgotPassword from '../../ForgotPassword/ForgotPassword';
const Authorize = ({ onLogin, onSignup }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPasswordText, setShowPasswordText] = useState(false);
  const [showConfirmPasswordText, setShowConfirmPasswordText] = useState(false);
  const navigate = useNavigate();
  const [hotelName, setHotelName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [hotelNameError, setHotelNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [hotelNameTouched, setHotelNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false); // New state for forgot password
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validatePassword = (password) => {
    const strongPasswordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return strongPasswordPattern.test(password);
  };

  const handleHotelNameChange = (e) => {
    setHotelName(e.target.value);
    if (hotelNameTouched && !e.target.value) {
      setHotelNameError('Hotel name is required.');
    } else {
      setHotelNameError('');
    }
  };

  const handleHotelNameBlur = () => {
    setHotelNameTouched(true);
    if (!hotelName) {
      setHotelNameError('Hotel name is required.');
    } else {
      setHotelNameError('');
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (emailTouched) {
      if (!validateEmail(e.target.value)) {
        setEmailError('Please enter a valid email address.');
      } else {
        setEmailError('');
      }
    }
  };

  const handleEmailBlur = () => {
    setEmailTouched(true);
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (passwordTouched) {
      if (!isLogin && !validatePassword(e.target.value)) {
        setPasswordError('Password must be at least 8 chars, include A-Z, a-z, 1-9, and @&.');
      } else {
        setPasswordError('');
      }
      if (confirmPassword && e.target.value !== confirmPassword) {
        setConfirmPasswordError('Passwords do not match.');
      } else {
        setConfirmPasswordError('');
      }
    }
  };

  const handlePasswordBlur = () => {
    setPasswordTouched(true);
    if (!isLogin && !validatePassword(password)) {
      setPasswordError('Password must be at least 8 chars, include A-Z, a-z, 1-9, and @&.');
    } else {
      setPasswordError('');
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (confirmPasswordTouched) {
      if (password !== e.target.value) {
        setConfirmPasswordError('Passwords do not match.');
      } else {
        setConfirmPasswordError('');
      }
    }
  };

  const handleConfirmPasswordBlur = () => {
    setConfirmPasswordTouched(true);
    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match.');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (showForgotPassword) return; // Prevent submission during Forgot Password
    setEmailTouched(true);
    setPasswordTouched(true);
    if (!isLogin) {
      setHotelNameTouched(true);
      setConfirmPasswordTouched(true);

      if (!hotelName) {
        setHotelNameError('Hotel name is required.');
        return;
      }

      if (!validatePassword(password)) {
        setPasswordError('Password must be at least 8 chars, include A-Z, a-z, 1-9, and @&.');
        return;
      }

      if (password !== confirmPassword) {
        setConfirmPasswordError('Passwords do not match.');
        return;
      }
    }

    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    if (isLogin) {
      onLogin(email, password);
    } else {
      onSignup(hotelName, email, password);
    }

    navigate('/mainadmin');
  };

  const handleClick = (inputId) => {
    const inputElement = document.getElementById(inputId);
    if (inputElement) {
      inputElement.focus();
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleIconMouseEnter = () => {
    setShowPasswordText(true);
  };

  const handleIconMouseLeave = () => {
    setShowPasswordText(false);
  };

  const handleConfirmIconMouseEnter = () => {
    setShowConfirmPasswordText(true);
  };

  const handleConfirmIconMouseLeave = () => {
    setShowConfirmPasswordText(false);
  };

  useEffect(() => {
    const emailInput = document.getElementById('email');
    const passwordInput = document.getElementById('password');
    if (emailInput.value) {
      setEmail(emailInput.value);
    }
    if (passwordInput.value) {
      setPassword(passwordInput.value);
    }
  }, []);
  const handleBackToLogin = () => {
    setShowForgotPassword(false);
  };
  
  return (
    <div className="container1">
      <div className="sidebar">
        <div className="san">
          <img src={logo} alt="logo" className="logos" />
          <strong>Sansara</strong>
        </div>
        <div className="all">
          <h1>All Hotel Services in One Place</h1>
        </div>
      </div>

      <div className="san1">
        <img src={logo} alt="logo" className="logos" />
        <strong>Sansara</strong>
      </div>
      <div className="content">
      {showForgotPassword ? (
    <div>

      <p className="reset-password-text">Your password will be reset, and a new password will be sent to your email</p> {/* Additional paragraph text */}
    </div>
  ) : (
    <h2>{isLogin ? 'Welcome Back' : 'Create Hotel Account'}</h2> // Login/Signup heading
  )}

        {showForgotPassword ? (
          <ForgotPassword  onBackToLogin={handleBackToLogin} /> // Render the ForgotPassword component here
        ) : (
        <form id="myForm" onSubmit={handleSubmit}>
          {!isLogin && (
            <div className="form-group ">
              <div className="password-input-container">
                <input
                  type="text"
                  id="hotelName"
                  name="hotelName"
                  onChange={handleHotelNameChange}
                  onBlur={handleHotelNameBlur}
                  required={!isLogin}
                />
                <label className={hotelName ? 'floating' : ''} onClick={() => handleClick('hotelName')}>Hotel Name</label>
                {hotelNameTouched && hotelNameError && <span className="error">{hotelNameError}</span>}
              </div>
            </div>
          )}
          <div className="form-group ">
            <div className="password-input-container">
              <input
                type="email"
                id="email"
                name="email"
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                required
              />
              <label className={email ? 'floating' : ''} onClick={() => handleClick('email')}>Enter Email</label>
              {emailTouched && emailError && <span className="error">{emailError}</span>}
            </div>
          </div>
          <div className="form-group ">
            <div className="password-input-container">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                onChange={handlePasswordChange}
                onBlur={handlePasswordBlur}
                autoComplete="off"
                required
              />
              <label className={password ? 'floating' : ''} onClick={() => handleClick('password')}>Enter Password</label>
              <span
                className="password-toggle-container"
                onMouseEnter={handleIconMouseEnter}
                onMouseLeave={handleIconMouseLeave}
                onClick={togglePasswordVisibility}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="password-toggle-icon" />
                {showPasswordText && (
                  <span className="password-toggle-text">
                    {showPassword ? 'Hide Password' : 'Show Password'}
                  </span>
                )}
              </span>
              {passwordTouched && passwordError && <span className="error">{passwordError}</span>}
            </div>
          </div>
          {!isLogin && (
            <div className="form-group  f2">
              <div className="password-input-container">
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  name="confirmPassword"
                  onChange={handleConfirmPasswordChange}
                  onBlur={handleConfirmPasswordBlur}
                  autoComplete="off"
                  required={!isLogin}
                />
                <label className={confirmPassword ? 'floating' : ''} onClick={() => handleClick('confirmPassword')}>Confirm Password</label>
                {confirmPasswordTouched && confirmPasswordError && <span className="error">{confirmPasswordError}</span>}
                <span
                  className="password-toggle-container"
                  onMouseEnter={handleConfirmIconMouseEnter}
                  onMouseLeave={handleConfirmIconMouseLeave}
                  onClick={toggleConfirmPasswordVisibility}
                >
                  <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} className="password-toggle-icon" />
                  {showConfirmPasswordText && (
                    <span className="password-toggle-text">
                      {showConfirmPassword ? 'Hide Password' : 'Show Password'}
                    </span>
                  )}
                </span>
              </div>
            </div>
          )}
          {isLogin && <a href="#" className="forgotPassword"  onClick={() => setShowForgotPassword(true)}>Forgot Password?</a>}
          <button type="submit" className="get" id="submitBtn">{isLogin ? 'Login' : 'Sign up'}</button>
        </form>
         )}
        {!showForgotPassword && (
          <span onClick={() => setIsLogin(!isLogin)}>
            {isLogin ? (
              <p>
                Don't have an account?{' '}
                <span className="toggleText" onClick={() => setIsLogin(false)}>
                  Sign Up
                </span>
              </p>
            ) : (
              <p>
                Already have an account?{' '}
                <span className="toggleText" onClick={() => setIsLogin(true)}>
                  Login
                </span>
              </p>
            )}
          </span>
        )}
      </div>
    </div>
  );
};

export default Authorize;

