import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import './Home.css';
import menu1 from '../Assets/All pics/Res.png';
import activities from '../Assets/All pics/activity.png';
import housek1 from '../Assets/All pics/Service.png';
import swimming from '../Assets/All pics/faci.png';
import MainBanner from '../Banner/MainBanner.jsx';
import drop from '../Assets/All pics/drop.png';
import flag1 from './flag.png';
import hand from './hand.png';

const Home = () => {
  const hotelName = localStorage.getItem('hotelName');
  const [guestData, setGuestData] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState({ name: 'English', flag: flag1 });
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const languages = [
    { name: 'English', flag: flag1 },
  ];

  useEffect(() => {
    const token = localStorage.getItem('guestToken');
    if (token) {
      axios.get(process.env.REACT_APP_API_BASE_URL + `/login-guest?token=${token}`)
        .then(response => setGuestData(response.data))
        .catch(error => {
          console.error('Error fetching guest data', error);
          if (error.response?.status === 401) {
            navigate('/login');
          }
        });
    } else {
      navigate('/login');
    }
  }, [process.env.REACT_APP_API_BASE_URL, navigate]);

  if (!guestData) {
    return <div>Loading...</div>;
  }

  const breakfastInfo = guestData.breakfast === 'y' ? 'Breakfast Included' : 'Breakfast Excluded';
  const wifiInfo = guestData.wifiPass ? `WIFI: ${guestData.wifiPass}` : '';

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    setIsOpen(false);
  };

  const formatDate = (dateString) => {
    const options = { hour: '2-digit', minute: '2-digit', hour12: true };
    return new Date(dateString).toLocaleTimeString([], options);
  };

  return (
    <div className='home-con'>
      <div className="home-page">
        <div className="main-content">
          <div className='drop-img'>
            <div className='language-dropdown'>
              <div className='main-flag' onClick={() => setIsOpen(!isOpen)}>
                <img src={selectedLanguage.flag} alt='flag' className='main-flg' />
                <img src={drop} alt='drop' className="arrow-flg" />
              </div>
              {isOpen && (
                <ul className="dropdown-list">
                  {languages.map((lang) => (
                    <li
                      key={lang.name}
                      onClick={() => handleLanguageChange(lang)}
                      className="dropdown-list-item"
                    >
                      <img src={lang.flag} alt={lang.name} className="flag-icon" />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <h2>{hotelName}</h2>
        </div>
        <div className='home'>
          <div className='home-room room1'>
            <h1> <span> Hi, {guestData.firstName} </span></h1>
            <img src={hand} alt='hand' />
          </div>
          <div className='home-room room-main'>
            <h2>Room Number: {guestData.room}</h2>
            <h2>Guests: {guestData.guestsNumber}</h2>
          </div>
        </div>
      </div>
      <div className='menu-container'>
        <div className='check-container'>
          <div className='check'>
            <div className='pp'>
              <p><span>Check-In: </span>{new Date(guestData.checkIn).toLocaleDateString()}</p>
              <p><span>Check-Out: </span>{new Date(guestData.checkOut).toLocaleDateString()}</p>
            </div>
            <div>
              <p><span className='space'>{formatDate(guestData.checkIn)}</span></p>
              <p><span className='space'>{formatDate(guestData.checkOut)}</span></p>
            </div>
          </div>

          <div className='check1'>
            <div className='pp1'>
              <p>{breakfastInfo}</p>
              {wifiInfo && <p>{wifiInfo}</p>}
            </div>
          </div>
        </div>

        <div className='sec-container'>
          <div className='menu '>
            <Link to='/basket'>
              <div className='click'>
                <img src={menu1} alt="res" />
                <button className='bar bar1'>Restaurant</button>
              </div>
            </Link>
          </div>
          <div className='menu2'>
            <Link to='/facilities'>
              <div className='click'>
                <img src={swimming} alt="swim" />
                <button className='bar'>Amenities</button>
              </div>
            </Link>
          </div>
          <div className='menu2'>
            <Link to='/activities'>
              <div className='click'>
                <img src={activities} alt="act" />
                <button className='bar'>Activities</button>
              </div>
            </Link>
          </div>
          <div className='menu'>
            <Link to='/service'>
              <div className='click'>
                <img src={housek1} alt="ser" />
                <button className='bar bar1'>Services</button>
              </div>
            </Link>
          </div>
        </div>
      
      <MainBanner className='banner-container'/>
      </div>
    </div>
  );
};

export default Home;
