// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainpage::-webkit-scrollbar {
  display: none;
} 
.menu-category{
  background: var(--greyColor);
  height: 100vh;
}
@media(min-width: 800px) {
  .mainpage{
    height: 990px; 
    overflow-y: auto;
    margin-top: 0px;
    margin: 0px 20px 4px 20px;
  }
.mainp{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 30px;
 
}
.extra{
  grid-column: span 3;
  height: 80px;

}
}
@media(max-width:780px) {
  .mainpage{
    margin: 10px ;
    margin-top: 5px;
    overflow-y: auto;
    height: 580px;
  }
  .mainp{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 10px;
  }
  .show-bar {
    grid-column: span 2; /* Span across all columns */
    margin-top: 0px; 
  }
  .extra{
    grid-column: span 2;
    height: 90px;
  }
}
@media (min-height: 740px) and (max-height: 840px) {
  .mainpage{
    margin-top: 5px;
    overflow-y: auto;
    min-height: 700px;
    padding: 0px;
    position: relative;
  }
  .extra{
    grid-column: span 2;
    height: 60px;
  }
}



@media (min-height: 841px) and (max-height: 950px) {
  .mainpage{
    min-height: 800px;
  }
}

`, "",{"version":3,"sources":["webpack://./src/Component/MenuCatgory/Menucategory.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,4BAA4B;EAC5B,aAAa;AACf;AACA;EACE;IACE,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,yBAAyB;EAC3B;AACF;EACE,aAAa;EACb,oCAAoC;EACpC,SAAS;;AAEX;AACA;EACE,mBAAmB;EACnB,YAAY;;AAEd;AACA;AACA;EACE;IACE,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,aAAa;EACf;EACA;IACE,aAAa;IACb,oCAAoC;IACpC,SAAS;EACX;EACA;IACE,mBAAmB,EAAE,4BAA4B;IACjD,eAAe;EACjB;EACA;IACE,mBAAmB;IACnB,YAAY;EACd;AACF;AACA;EACE;IACE,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;EACpB;EACA;IACE,mBAAmB;IACnB,YAAY;EACd;AACF;;;;AAIA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".mainpage::-webkit-scrollbar {\n  display: none;\n} \n.menu-category{\n  background: var(--greyColor);\n  height: 100vh;\n}\n@media(min-width: 800px) {\n  .mainpage{\n    height: 990px; \n    overflow-y: auto;\n    margin-top: 0px;\n    margin: 0px 20px 4px 20px;\n  }\n.mainp{\n  display: grid;\n  grid-template-columns: repeat(3,1fr);\n  gap: 30px;\n \n}\n.extra{\n  grid-column: span 3;\n  height: 80px;\n\n}\n}\n@media(max-width:780px) {\n  .mainpage{\n    margin: 10px ;\n    margin-top: 5px;\n    overflow-y: auto;\n    height: 580px;\n  }\n  .mainp{\n    display: grid;\n    grid-template-columns: repeat(2,1fr);\n    gap: 10px;\n  }\n  .show-bar {\n    grid-column: span 2; /* Span across all columns */\n    margin-top: 0px; \n  }\n  .extra{\n    grid-column: span 2;\n    height: 90px;\n  }\n}\n@media (min-height: 740px) and (max-height: 840px) {\n  .mainpage{\n    margin-top: 5px;\n    overflow-y: auto;\n    min-height: 700px;\n    padding: 0px;\n    position: relative;\n  }\n  .extra{\n    grid-column: span 2;\n    height: 60px;\n  }\n}\n\n\n\n@media (min-height: 841px) and (max-height: 950px) {\n  .mainpage{\n    min-height: 800px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
