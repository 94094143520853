import React, { useState } from 'react';
import Chart from './Chart';
import mockData from './mockData';
import './Dashboard.css';

// Import your custom image files
import upArrowImg from '../../Assets/arrowup.png';  // Adjust the path to where your image is
import downArrowImg from'../../Assets/arrowdown.png'; // Adjust the path to where your image is

const DashboardPage = () => {
  const [selectedCategory, setSelectedCategory] = useState('overviewStats');
  const [selectedPeriod, setSelectedPeriod] = useState('daily');

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSelectedPeriod('daily');
  };

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const stats = mockData[selectedCategory] || {};
  const boxes = stats.boxes || [];

  return (
    <div >
      <div className='bar2 dash'>
      <h1>Dashboard</h1>
      </div>
      <div  className="dashboard-container">
      <div className="title-row">
        {Object.keys(mockData).map(category => (
          <div
            key={category}
            className={`dashboard-box ${selectedCategory === category ? 'active' : ''}`}
            onClick={() => handleCategoryClick(category)}
          >
            <h3>{mockData[category].title}</h3>
          </div>
        ))}
      </div>

      {/* Second Row: Stats Boxes */}
      <div className="dashboard-row">
        {boxes.map((box, index) => (
          <div key={index} className="dashboard-box2">
            <div className="stats">
            <div className="value">
            <p>{box.title}</p>
                <strong>{box.value}</strong>
              </div>
              <div>
              <img src={box.image} alt=''/>
              </div>
              </div>
              <div className="change">
                {/* Use the custom images for arrows */}
                <img 
                  src={box.change >= 0 ? upArrowImg : downArrowImg} 
                  alt={box.change >= 0 ? "Increase" : "Decrease"}
                  className="arrow-icon"
                />
                <span className={box.change >= 0 ? "positive" : "negative"}>
                  {Math.abs(box.change)}%
                </span>
                <p className={box.change >= 0 ? "positive-text" : "negative-text"}>
                {box.change >= 0 ? "Up from yesterday" : "Down from yesterday"}
                 </p>
              </div>
          
            
          </div>
        ))}
      </div>

      {/* Third Row: Chart */}
      <div className="chart-container">
        <Chart data={stats.chartData} period={selectedPeriod} />
      </div>
    </div>
    </div>
  );
};

export default DashboardPage;
