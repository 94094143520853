import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { IoSearchOutline } from "react-icons/io5";
import CategoryDropdown from './CategoryDropdown.jsx';
import FoodItem from './FoodItem.jsx';
import AddDish from './AddDish.jsx';
import EditDish from './EditDish.jsx';
import TopScrolling from '../Room/TopScrolling.jsx';
import './Restaurant.css';
import axios from 'axios';
import { debounce } from 'lodash';
import { AuthContext } from '../../../Assets/MainAdminLAyout/Authorize/AuthContext';

const fetchFoodItems = async (hotelId, category, page, size) => {
  const token = localStorage.getItem('hotelToken');

  if (!token || !hotelId) {
    throw new Error('No token or hotelId found');
  }

  try {
    const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/restaurant/${category}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        hotelId: hotelId,
      },
      params: {
        tag: 'hotel',
        page: page,
        size: size,
      },
    });
    return response.data.sort((a, b) => a.orderNumber - b.orderNumber);
  } catch (error) {
    console.error('Error fetching food items', error);
    throw error;
  }
};

const searchFoodItems = async (hotelId, query, page = 0, size = 50) => {
  const token = localStorage.getItem('hotelToken');
  const guestId = localStorage.getItem('guestId');

  if (!token || !hotelId) {
    throw new Error('No token or hotelId found');
  }

  try {
    const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/restaurant/search', {
      headers: {
        Authorization: `Bearer ${token}`,
        hotelId: hotelId,
        ...(guestId && { guestId: guestId })
      },
      params: {
        tag: 'hotel',
        search: query,
        page: page,
        size: size
      },
    });
    return response.data.sort((a, b) => a.orderNumber - b.orderNumber);
  } catch (error) {
    console.error('Error searching food items', error);
    throw error;
  }
};

const Restaurant = () => {
  const { hotelId } = useContext(AuthContext);
  const [showAddModal, setShowAddModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [foodItems, setFoodItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(localStorage.getItem('selectedCategory') || 'All');
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const token = localStorage.getItem('hotelToken');
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const observer = useRef();
  const lastFoodItemElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  const loadFoodItems = async (reset = false) => {
    if (loading) return;
    setLoading(true);
    try {
      const newPage = reset ? 0 : page;
      const newFoodItems = await fetchFoodItems(hotelId, selectedCategory, newPage, 50);
      setFoodItems(prevItems => reset ? newFoodItems : [...prevItems, ...newFoodItems]);
      setHasMore(newFoodItems.length > 0);
    } catch (error) {
      console.error('Failed to fetch food items', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (hotelId) {
      loadFoodItems(true);
    }
  }, [selectedCategory, hotelId]);

  useEffect(() => {
    if (page > 0) {
      loadFoodItems();
    }
  }, [page]);

  useEffect(() => {
    localStorage.setItem('selectedCategory', selectedCategory);
  }, [selectedCategory]);

  const debouncedSearch = useCallback(debounce(async (query) => {
    if (query) {
      setLoading(true);
      try {
        const searchResults = await searchFoodItems(hotelId, query, 0, 50);
        setFoodItems(searchResults);
        setHasMore(false);
      } catch (error) {
        console.error('Failed to search food items', error);
      }
      setLoading(false);
    } else {
      loadFoodItems(true);
    }
  }, 500), [hotelId, selectedCategory]);

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchTerm(query);
    debouncedSearch(query);
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setShowEditModal(true);
  };

  const handleEditItem = (updatedItem) => {
    setFoodItems(prevItems =>
      prevItems.map(item =>
        item.id === updatedItem.id ? { ...item, ...updatedItem } : item
      )
    );
    setShowEditModal(false);
  };

  const handleAddItem = (newItem) => {
    setFoodItems((prevItems) => [newItem, ...prevItems]);
    setShowAddModal(false);
  };

  const handleAvailabilityChange = async (itemId, newAvailability, imageLink) => {
    const token = localStorage.getItem('hotelToken');
    const hotelId = localStorage.getItem('hotelId');

    const foodDto = {
      id: itemId,
      available: newAvailability,
      imageLink: imageLink
    };

    const formData = new FormData();
    formData.append('foodDto', JSON.stringify(foodDto));

    try {
      const response = await axios.put(process.env.REACT_APP_API_BASE_URL + '/restaurant/update', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          hotelId: hotelId
        },
      });
      console.log('Availability updated successfully', response);
    } catch (error) {
      console.error('Failed to update availability', error);
    }
  };

  const handleDeleteDish = async (dishId) => {
    try {
      await axios.delete(process.env.REACT_APP_API_BASE_URL + `/restaurant/${dishId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFoodItems((prevItems) => prevItems.filter(item => item.id !== dishId));
      setShowEditModal(false);
    } catch (error) {
      console.error('Failed to delete dish', error);
    }
  };

  const categories = ['All', 'Appetizer', 'Fast Food', 'Drinks', 'Alcohol', 'Main Courses', 'Salads', 'Desserts', 'Soups'];

  return (
    <div className='rooms-container' id='rooms-container'>
      <div className='bar2'>
        <h1>Restaurant</h1>
        <div className="room-controls">
          <CategoryDropdown
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
          <div className='search-br1'>
            <p><IoSearchOutline /></p>
            <input
              type="text"
              placeholder="Type Title or Description"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <button onClick={() => setShowAddModal(true)}>+</button>
        </div>
      </div>
      <div className='food-bar'>
        <div className="food-list">
          {foodItems.map((item, index) => {
            if (foodItems.length === index + 1) {
              return (
                <div ref={lastFoodItemElementRef} key={item.id}>
                  <FoodItem
                    item={item}
                    onEdit={() => handleEdit(item)}
                    onAvailabilityChange={handleAvailabilityChange}
                  />
                </div>
              );
            } else {
              return (
                <FoodItem
                  key={item.id}
                  item={item}
                  onEdit={() => handleEdit(item)}
                  onAvailabilityChange={handleAvailabilityChange}
                />
              );
            }
          })}
        </div>
      </div>
      {showAddModal && (
        <AddDish
          onClose={() => setShowAddModal(false)}
          onAddItem={handleAddItem}
        />
      )}
      {showEditModal && selectedItem && (
        <EditDish
          item={selectedItem}
          onClose={() => setShowEditModal(false)}
          onEditItem={handleEditItem}
          onDeleteItem={handleDeleteDish}
        />
      )}
      <TopScrolling scrollContainerId="rooms-container" />
    </div>
  );
};

export default Restaurant;