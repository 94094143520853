import React, { useState, useEffect } from 'react';

const TopScrolling = ({ scrollContainerId }) => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const scrollContainer = document.getElementById(scrollContainerId);

        if (!scrollContainer) {
            return;
        }

        const handleScroll = () => {
            setIsScrolled(scrollContainer.scrollTop > 150);
        };

        scrollContainer.addEventListener('scroll', handleScroll);
        return () => {
            scrollContainer.removeEventListener('scroll', handleScroll);
        };
    }, [scrollContainerId]);

    const scrollToTop = () => {
        const scrollContainer = document.getElementById(scrollContainerId);
        if (scrollContainer) {
            scrollContainer.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    return (
        <div className={`back-to-top ${isScrolled ? 'show' : ''}`} onClick={scrollToTop}>
            ↑
        </div>
    
  )
}

export default TopScrolling