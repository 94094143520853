// src/components/Assets/MainAdminLayout/Guests/CustomDatePicker.jsx
// CustomDatePicker.jsx
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const CustomDatePicker = ({ value, onChange, label, name }) => {
  const handleChange = (date) => {
    onChange(date, name);
  };

  return (
    <div className="room-input-container date-picker-container">
      <DatePicker
        selected={value}
        onChange={handleChange}
        showTimeSelect
        dateFormat="MM.dd.yyyy h:mm aa"
      />
      <label className={value ? 'floating' : ''}>{label}</label>
    </div>
  );
};

export default CustomDatePicker;











