import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
    const token = localStorage.getItem('hotelToken');
    const [conversations, setConversations] = useState([]);
    const [notificationCount, setNotificationCount] = useState(0);

    const fetchUnreadCount = async () => {
        const hotelId = localStorage.getItem('hotelId');

        if (!hotelId || !token) {
            console.error('Hotel ID or token not found in localStorage');
            return;
        }

        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/chat/unread-count`, {
                params: {
                    userType: 'HOTEL'
                },
                headers: {
                    'Authorization': `Bearer ${token}`,
                    hotelId: hotelId,
                }
            });
            setNotificationCount(response.data);
        } catch (error) {
            console.error('Error fetching unread message count:', error);
        }
    };

    useEffect(() => {
        fetchUnreadCount();

        // Устанавливаем интервал для периодического обновления
        const intervalId = setInterval(fetchUnreadCount, 60000); // Обновляем каждую минуту

        // Очищаем интервал при размонтировании компонента
        return () => clearInterval(intervalId);
    }, []);

    return (
        <NotificationContext.Provider value={{ notificationCount, conversations, setConversations, fetchUnreadCount }}>
            {children}
        </NotificationContext.Provider>
    );
};