import React from 'react';
import './Resbar.css'
import app  from '../Assets/appp.png'
import burger from '../Assets/bur.png'
import coffee from '../Assets/coffee.png'
import all from '../Assets/all.png'
import salads from '../Assets/salads.png'
import main_dishes from '../Assets/main dishes.png'
import soups from '../Assets/soups.png'
import wine from '../Assets/wine.png'
import desserts from '../Assets/desserts.png'
import { Link } from 'react-router-dom'


const Resbar = () => {
   
      
  return (
    <div className='center-container1'>
        
        <div className='resbar-container'>
       <div>
        <Link to="/appetizer"  style={{textDecoration:'none', color: 'var(--blackColor)'}}>
           <img src={app} alt="/"/>
           <p>Appetizer</p>
        </Link>
       </div>
       <div>
       <Link to="/fast-food"  style={{textDecoration:'none', color: 'var(--blackColor)'}}>
           <img src={burger} alt="/"/>
           <p>Fast food</p>
           </Link>
       </div>
       <div>
       <Link to="/drinks"  style={{textDecoration:'none', color: 'var(--blackColor)'}}>
           <img src={coffee} alt="/"/>
           <p className='tea'>Drinks</p>
        </Link>
       </div>
       <div>
       <Link to="/alcohol" style={{textDecoration:'none', color: 'var(--blackColor)'}}>
           <img src={wine} alt="/"/>
           <p>Alcohol</p>
        </Link>
       </div>
       <div>
       <Link to="/main-dishes"  style={{textDecoration:'none', color: 'var(--blackColor)'}}>
           <img src={main_dishes} alt="/"/>
           <p>Main Courses</p>
        </Link>
       </div>
       <div>
       <Link to="/salads"  style={{textDecoration:'none', color: 'var(--blackColor)'}}>
           <img src={salads} alt="/"/>
           <p>Salads</p>
        </Link>
       </div>
       <div>
       <Link to="/desserts"  style={{textDecoration:'none', color: 'var(--blackColor)'}}>
           <img src={desserts} alt="/"/>
           <p>Desserts</p>
        </Link>
       </div>
       <div>
       <Link to="/soups"  style={{textDecoration:'none', color: 'var(--blackColor)'}}>
           <img src={soups} alt="/"/>
           <p>Soups</p>
        </Link>
       </div>
       <div>
       <Link to="/all"  style={{textDecoration:'none', color: 'var(--blackColor)'}}>
           <img src={all} alt="/"/>
           <p>All</p>
        </Link>
       </div>
       
       </div>

    </div>
  )
}

export default Resbar