import React, { useState,useRef,useEffect } from 'react';
import { IoClose } from "react-icons/io5";
import './SerShow.css'
import Arrow from '../../ArrowB/Arrow';
import { Link } from 'react-router-dom';
import BedService from './BedService';
import CleaningService from './CleaningService';
import LaundryServices from './LaundryServices';
import BreakfastServices from './BreakfastServices';
import WakeupService from './WakeupService';
import SpaService from './SpaService';
const Services = ({ services }) => {
  const [selectedService, setSelectedService] = useState(null);
  const [savedSelections, setSavedSelections] = useState({});
  const [overlay, setOverlay] = useState(false);
  const overlayRef = useRef(null);
  const [startY, setStartY] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    const deltaY = e.touches[0].clientY - startY;
    if (deltaY > 0) {
      setTranslateY(deltaY);
    }
  };

  const handleTouchEnd = () => {
    if (translateY > 100) {
      setOverlay(false);
    }
    setTranslateY(0);
  };
  
  const toggleOverlay = () => {
    setOverlay(!overlay);
};

  const handleServiceClick = (service) => {
    setSelectedService(service);
    setOverlay(true);
  };


  const handleSave = (serviceId, selectedOption) => {
    setSavedSelections(prev => ({ ...prev, [serviceId]: selectedOption }));
     toggleOverlay();
  };

  const renderServiceDetails = () => {
    if (!selectedService) return null;

    const commonProps = { service: selectedService, onSave: handleSave };

    if (selectedService.category === 'bed') {
      return <BedService {...commonProps} />;
    }

    if (selectedService.category === 'clean') {
      return <CleaningService {...commonProps} />;
    }
     if(selectedService.category ==='laundry'){
      return<LaundryServices {...commonProps}/>
     }
     if(selectedService.category ==='breakfast'){
      return<BreakfastServices {...commonProps}/>
     }
     if(selectedService.category ==='spa'){
      return<SpaService {...commonProps}/>
     }
     if(selectedService.category ==='wake-up'){
      return<WakeupService {...commonProps}/>
     }
    return null;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setOverlay(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [overlayRef]);


  return (
    <div className='service'>
         <Link to="/home"  style={{textDecoration:'none',color: 'var(--primaryColor)'}} ><Arrow/></Link>
      <div className='sershow'>
            <div className='ser1'>
      {services.map((item) => (
        <div key={item.id} className='service-item'>
          <div className='inner' onClick={() => handleServiceClick(item)}>
            <img src={item.img} alt="change" />
            <p>{item.title}</p>
          </div>
        </div>
      ))}
</div>
</div>

<p className='request'> To help reduce water resources, we kindly ask that you consider reusing your towels and blankets during your stay. Your efforts support our sustainability goals.Thank you for your cooperation!!</p>
       <h3 className='assit'> We are here to assist you whenever you need our services.</h3>

      {/* Modal for displaying service details */}
      {overlay && (
          <div className='overlay'>
          <div  
          ref={overlayRef}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          style={{ transform: `translateY(${translateY}px)` }}
          className={`overlay1 ${overlay ? 'visible' : ''}`} onClick={toggleOverlay}>
        <div className='ov-con'>
                    <div className='line'></div>
                    <button className="close-button " onClick={toggleOverlay}><IoClose /></button>
                    <div  onClick={(e) => e.stopPropagation()} >
            {renderServiceDetails()}
          </div>
        </div>
        </div>
                </div>
      )}
      
    </div>
  );
};

export default Services;
