import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import axios from 'axios';
import { IoSearchOutline } from "react-icons/io5";
import AddRoom from '../AdminLayout/AddRoom';
import EditRoomPage from '../AdminLayout/EditRoomPage';
import { AuthContext } from '../../../Assets/MainAdminLAyout/Authorize/AuthContext';
import './Room.css';
import Qr from '../../All pics/qr-code-svgrepo-com.png';
import Message from '../../All pics/message-square-lines-svgrepo-com (1).png';
import { debounce } from 'lodash';
import TopScrolling from './TopScrolling';
const fetchHotelRooms = async (hotelId, page, size) => {
  const token = localStorage.getItem('hotelToken');
  
  if (!token || !hotelId) {
    throw new Error('No token или hotelId found');
  }

  try {
    const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/rooms', {
      headers: {
        Authorization: `Bearer ${token}`,
        hotelId: hotelId
      },
      params: {
        page: page,
        size: size,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching hotel rooms', error);
    throw error;
  }
};

const searchHotelRooms = async (hotelId, query) => {
  const token = localStorage.getItem('hotelToken');
  
  if (!token || !hotelId) {
    throw new Error('No token or hotelId found');
  }

  try {
    const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/room/search', {
      headers: {
        Authorization: `Bearer ${token}`,
        hotelId: hotelId
      },
      params: {
        search: query,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error searching hotel rooms', error);
    throw error;
  }
};

const Rooms = () => {
  const [selectedRoom, setSelectedRoom] = useState(null);
  const { hotelId } = useContext(AuthContext);
  const [rooms, setRooms] = useState([]);
  const [formData, setFormData] = useState({
    roomNumber: '',
    type: '',
    wifiPass: '',
    breakfast: '',
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showQrModal, setShowQrModal] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [editRoom, setEditRoom] = useState(null);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const observer = useRef();
  const lastRoomElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  const loadRooms = async (reset = false) => {
    if (loading) return;
    setLoading(true);
    try {
      const newPage = reset ? 0 : page;
      const roomsData = await fetchHotelRooms(hotelId, newPage, 50);
      setRooms(prevRooms => reset ? roomsData : [...prevRooms, ...roomsData]);
      setHasMore(roomsData.length > 0);
    } catch (error) {
      console.error('Failed to fetch rooms', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (hotelId) {
      loadRooms(true);
    }
  }, [hotelId]);

  useEffect(() => {
    if (page > 0) {
      loadRooms();
    }
  }, [page]);

  const handleAddRoomSubmit = () => {
    loadRooms(true); // Fetch the updated list of rooms
    setShowAddModal(false);
  };

  const handleAddMultipleRoomsSubmit = async (newRooms) => {
    await loadRooms(true);
    setShowAddModal(false);
  };

  const handleEditRoomSubmit = (updatedRoom) => {
    setRooms(rooms.map(room => (room.id === updatedRoom.id ? updatedRoom : room)));
    setShowEditModal(false);
  };

  const openEditModal = (room) => {
    setEditRoom(room);
    setShowEditModal(true);
    setFormData(room);
  };

  const openQrModal = (qrCode, e, room) => {
    e.stopPropagation(); // Остановить всплытие события, чтобы не сработал другой обработчик
    setQrCode(qrCode);
    setShowQrModal(true);
    setSelectedRoom(room);
  };

  const debouncedSearch = useCallback(debounce(async (query) => {
    if (query) {
      setLoading(true);
      try {
        const searchResults = await searchHotelRooms(hotelId, query);
        setRooms(searchResults);
        setHasMore(false); // Disable infinite scroll when searching
      } catch (error) {
        console.error('Failed to search rooms', error);
      }
      setLoading(false);
    } else {
      loadRooms(true); // Reset to full list when search is cleared
    }
  }, 500), [hotelId]);

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const handleDeleteRoom = (roomId) => {
    setRooms((prevRooms) => prevRooms.filter(room => room.id !== roomId));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const closeQrModal = (e) => {
    if (e.target.className === 'modal') {
      setShowQrModal(false);
    }
  };

  const handleDownloadQR = () => {
    const link = document.createElement('a');
    link.href = `data:image/png;base64,${qrCode}`;
    link.download = 'qr-code.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePrintQR = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`<html><head><title>Print QR Code</title></head><body>`);
    printWindow.document.write(`<img src="data:image/png;base64,${qrCode}" style="max-width: 100%;" />`);
    printWindow.document.write(`</body></html>`);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  return (
    <div className='rooms-container' id='rooms-container'>
     
        <div className='bar2'>
          <h1>Rooms</h1>
          <div className="room-controls">
            <div className='search-br1'>
              <p><IoSearchOutline /></p>
              <input
                type="text"
                placeholder="Type Room Number"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
            <button onClick={() => setShowAddModal(true)}>+</button>
          </div>
        </div>

        <ul className="room-list">
          {rooms.map((room, index) => {
            if (rooms.length === index + 1) {
              return (
                <li ref={lastRoomElementRef} key={room.id} className='room-list1' onClick={() => openEditModal(room)}>
                  <div className="room-info">
                    <div className="room-column">
                      <h3>{room.roomNumber} ({room.type})</h3>
                      <div className="room-column1">
                        <img src={Qr} alt='qr' onClick={(e) => openQrModal(room.qrCode, e, room)} />
                        <img src={Message} alt='message' />
                      </div>
                    </div>
                    <div className="room-description">
                      <p>Wifi Password: {room.wifiPass}</p>
                      <p>{room.breakfast === 'y' ? 'Breakfast Included' : 'Breakfast Excluded'}</p>
                    </div>
                  </div>
                </li>
              );
            } else {
              return (
                <li key={room.id} className='room-list1' onClick={() => openEditModal(room)}>
                  <div className="room-info">
                    <div className="room-column">
                      <h3>{room.roomNumber} ({room.type})</h3>
                      <div className="room-column1">
                        <img src={Qr} alt='qr' onClick={(e) => openQrModal(room.qrCode, e, room)} />
                        <img src={Message} alt='message' />
                      </div>
                    </div>
                    <div className="room-description">
                      <p>Wifi Password: {room.wifiPass}</p>
                      <p>{room.breakfast === 'y' ? 'Breakfast Included' : 'Breakfast Excluded'}</p>
                    </div>
                  </div>
                </li>
              );
            }
          })}
        
        </ul>
        
        {showAddModal && (
          <AddRoom
            onClose={() => setShowAddModal(false)}
            onAddRoom={handleAddRoomSubmit}
            onAddMultipleRooms={handleAddMultipleRoomsSubmit}
            formData={formData} setFormData={setFormData}
          />
        )}
        {showEditModal && editRoom && (
          <EditRoomPage
            room={editRoom}
            onClose={() => setShowEditModal(false)}
            onEditRoom={handleEditRoomSubmit}
            onDeleteRoom={handleDeleteRoom}
          />
        )}
         
         {showQrModal && selectedRoom && (
        <div className='modal' onClick={closeQrModal}>
          <div className='modal-content'>
            <span className='close-qr' onClick={() => setShowQrModal(false)}>&times;</span>
            <img src={`data:image/png;base64,${qrCode}`} alt='QR Code' />
            <p className="room-link">
              <a href={selectedRoom.roomLink} target="_blank" rel="noopener noreferrer">{selectedRoom.roomLink}</a>
            </p>
            <div className="qr-actions">
              <button onClick={handleDownloadQR}>Download QR</button>
              <button onClick={handlePrintQR}>Print QR</button>
            </div>
          </div>
        </div>
      )}
       <TopScrolling scrollContainerId="rooms-container"/>

    </div>
  );
};

export default Rooms;
