import React, { useState, useRef, useEffect, useCallback } from 'react';
import { IoSearchOutline } from "react-icons/io5";
import Arrow from '../ArrowB/Arrow';
import { Link } from 'react-router-dom';
import Foods from './Foods';
import './Resbar.css';
import axios from 'axios';
import { debounce } from 'lodash';

const Search = () => {
  const [searchText, setSearchText] = useState('');
  const [filteredFoodList, setFilteredFoodList] = useState([]);
  const searchInputRef = useRef(null);
  const [isInputFocused, setIsInputFocused] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const observer = useRef();
  const lastFoodElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  const fetchFoods = async (searchTerm, reset = false) => {
    if (loading) return;
    setLoading(true);
    try {
      const token = localStorage.getItem('guestToken');
      const hotelId = localStorage.getItem('hotelId');
      const guestId = localStorage.getItem('id');
      const newPage = reset ? 0 : page;

      const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/restaurant/search`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'hotelId': hotelId,
          'guestId': guestId,
        },
        params: {
          tag: 'guest',
          search: searchTerm,
          page: newPage,
          size: 10,
        }
      });

      const newFoods = response.data;
      setHasMore(newFoods.length > 0);
      setFilteredFoodList(prevFoods => reset ? newFoods : [...prevFoods, ...newFoods]);
    } catch (error) {
      console.error('Error fetching search results', error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce((searchTerm) => {
      if (searchTerm.trim() !== '') {
        setPage(0);
        fetchFoods(searchTerm, true);
      } else {
        setFilteredFoodList([]);
        setPage(0);
        setHasMore(true);
      }
    }, 500),
    []
  );

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchText(searchTerm);
    debouncedSearch(searchTerm);
  };

  useEffect(() => {
    if (searchText.trim() !== '' && page > 0) {
      fetchFoods(searchText);
    }
  }, [page, searchText]);

  useEffect(() => {
    const handleTouchMove = () => {
      if (isInputFocused) {
        document.activeElement.blur();
        setIsInputFocused(false);
      }
    };

    window.addEventListener('touchmove', handleTouchMove);

    return () => {
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, [isInputFocused]);

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  return (
    <div className="search-con">
      <Link to="/basket" style={{ textDecoration: 'none', color: 'var(--primaryColor)' }}>
        <Arrow />
      </Link>
      <div className="center-container">
        <div className="search-br">
          <p>
            <IoSearchOutline />
          </p>
          <input
            className="in-box"
            type="text"
            onFocus={handleInputFocus}
            ref={searchInputRef}
            value={searchText}
            onChange={handleInputChange}
            placeholder="Search"
            autoFocus
          />
        </div>
      </div>
      {loading && filteredFoodList.length === 0 ? (
        <div>Loading...</div>
      ) : (
        searchText.trim() !== '' && (
          <div className="food-ser">
            {filteredFoodList.map((item, index) => {
              if (filteredFoodList.length === index + 1) {
                return (
                  <div ref={lastFoodElementRef} key={item.id}>
                    <Foods
                      id={item.id}
                      image={item.imageLink}
                      title={item.title}
                      des={item.description}
                      price={item.price.toFixed(2)}
                      recom={item.recommend}
                      quantityInCart={item.quantityInCart} 
                    />
                  </div>
                );
              } else {
                return (
                  <Foods
                    key={item.id}
                    id={item.id}
                    image={item.imageLink}
                    title={item.title}
                    des={item.description}
                    price={item.price.toFixed(2)}
                    recom={item.recommend}
                    quantityInCart={item.quantityInCart} 
                  />
                );
              }
            })}
          </div>
        )
      )}
      {loading && filteredFoodList.length > 0 && <div>Loading more...</div>}
    </div>
  );
};

export default Search;