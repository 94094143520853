import React, { useState, useContext, useRef } from 'react';
import RoomTypeSelect from './RoomTypeSelect';
import axios from 'axios';
import { AuthContext } from '../../../Assets/MainAdminLAyout/Authorize/AuthContext';

const AddRoom = ({ onClose, onAddRoom, onAddMultipleRooms }) => {
  const token = localStorage.getItem('hotelToken');
  const { hotelId } = useContext(AuthContext);
  const [showForm, setShowForm] = useState('single');
  const [fromNumber, setFromNumber] = useState('');
  const [toNumber, setToNumber] = useState('');
  const [name, setName] = useState('');
  const [roomData, setRoomData] = useState({ name: '', capacity: '' });
  const [multipleRoomData, setMultipleRoomData] = useState({ fromNumber: '', toNumber: '', capacity: '' });
  const [breakfastChecked, setBreakfastChecked] = useState(false);
  const [roomType, setRoomType] = useState('Single');  // Changed initial value to 'Superior'
  const [wifi, setWifi] = useState('');
  const [loading, setLoading] = useState(false);
  const initialClickElement = useRef(null);

  const handleBreakfastChange = (e) => {
    setBreakfastChecked(e.target.checked);
  };

  const handleRoomTypeChange = (e) => {
    setRoomType(e.target.value);
  };

  const handleSingleChange = (e) => {
    setRoomData({ ...roomData, [e.target.name]: e.target.value });
  };

  const handleMultipleChange = (e) => {
    setMultipleRoomData({ ...multipleRoomData, [e.target.name]: e.target.value });
  };

  const handleInputChange = (e, setState) => {
    setState(e.target.value);
  };

  const handleClick = (inputName) => {
    document.getElementsByName(inputName)[0].focus();
  };

  const handleSingleSubmit = async (e) => {
    e.preventDefault();

    const newRoom = {
      hotelId: hotelId,
      roomNumber: name,
      wifiPass: wifi,
      breakfast: breakfastChecked ? 'y' : 'n',
      type: roomType,
    };

    try {
      setLoading(true);
      const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/register-room', newRoom, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      onAddRoom(response.data);
      setTimeout(() => {
        setLoading(false);
        onClose();
      }, 3000);
    } catch (error) {
      console.error('Failed to register room', error);
      setLoading(false);
    }
  };

  const handleMultipleSubmit = async (e) => {
    e.preventDefault();
    const newRooms = [];

    try {
      setLoading(true);
      for (let i = parseInt(fromNumber); i <= parseInt(toNumber); i++) {
        const newRoom = {
          hotelId: hotelId,
          roomNumber: i.toString(),
          wifiPass: wifi,
          breakfast: breakfastChecked ? 'y' : 'n',
          type: roomType,
        };

        const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/register-room', newRoom, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        newRooms.push(response.data);
      }
      onAddMultipleRooms(newRooms);
      setTimeout(() => {
        setLoading(false);
        onClose();
      }, 3000);
    } catch (error) {
      console.error('Failed to register rooms', error);
      setLoading(false);
    }
  };

  const handleBackgroundMouseDown = (e) => {
    initialClickElement.current = e.target;
  };

  const handleBackgroundMouseUp = (e) => {
    if (e.target.className === 'main-room' && initialClickElement.current === e.target) {
      onClose();
    }
    initialClickElement.current = null;
  };

  return (
    <div className='main-room' onMouseDown={handleBackgroundMouseDown} onMouseUp={handleBackgroundMouseUp}>
      <div className="add-room-options">
        <div className='added-box'>
          <h3>Add New Room</h3>
          <button className="close" onClick={onClose}>×</button>
        </div>
        <div className='add-room'>
          <button
            onClick={() => setShowForm('single')}
            className={showForm === 'single' ? 'active' : 'inactive'}
          >
            + Add One Room
          </button>
          <button
            onClick={() => setShowForm('multiple')}
            className={showForm === 'multiple' ? 'active' : 'inactive'}
          >
            + Add Many Rooms
          </button>
        </div>
        {showForm === 'single' && (
          <div className="room-form">
            <form onSubmit={handleSingleSubmit}>
              <div className="room-input-container">
                <input type="text" name="name" value={name} onChange={(e) => handleInputChange(e, setName)} style={{ width: '100%' }} required />
                <label
                  className={name ? 'floating' : ''}
                  onClick={() => handleClick('name')}
                >
                  Room Number
                </label>
              </div>

              <div className="date-picker-container">
                <RoomTypeSelect value={roomType} onChange={handleRoomTypeChange} label="Room Type" />
              </div>
              <div className='row-box1'>
                <div className='checkbox-container'>
                  <input
                    type="checkbox"
                    id="breakfast"
                    name="breakfast"
                    checked={breakfastChecked}
                    onChange={handleBreakfastChange}
                  />
                  <label htmlFor="breakfast">Breakfast</label>
                </div>
                <div className="room-input-container">
                  <input
                    type="text"
                    name="wifi"
                    value={wifi}
                    onChange={(e) => handleInputChange(e, setWifi)} required
                  />
                  <label
                    className={wifi ? 'floating' : ''}
                    onClick={() => handleClick('wifi')}>WiFi Password</label>
                </div>
              </div>

              <hr />
              <div className='button-bar'>
                <button type="submit" className='save-btn'>{loading ? 'Adding...' : 'Add Room'}</button>
              </div>
            </form>
          </div>
        )}
        {showForm === 'multiple' && (
          <div className="room-form">
            <form onSubmit={handleMultipleSubmit}>
              <div className='m2 room-multiple'>
                <div className="room-input-container">
                  <input type="text" name="fromNumber" value={fromNumber} onChange={(e) => handleInputChange(e, setFromNumber)} required />
                  <label
                    className={fromNumber ? 'floating' : ''}
                    onClick={() => handleClick('fromNumber')}
                  >
                    From Room Number
                  </label>
                </div>
                <div className="room-input-container">
                  <input type="text" name="toNumber" value={toNumber} onChange={(e) => handleInputChange(e, setToNumber)} required />
                  <label
                    className={toNumber ? 'floating' : ''}
                    onClick={() => handleClick('toNumber')}
                  >
                    To Room Number
                  </label>
                </div>
              </div>

              <div className="room-input-container">
                <RoomTypeSelect value={roomType} onChange={handleRoomTypeChange} label="Room Type" />
              </div>

              <div className='row-box1'>
                <div className='checkbox-container'>
                  <input
                    type="checkbox"
                    id="breakfast"
                    name="breakfast"
                    checked={breakfastChecked}
                    onChange={handleBreakfastChange}
                  />
                  <label htmlFor="breakfast">Breakfast</label>
                </div>
                <div className="room-input-container">
                  <input
                    type="text"
                    name="wifi"
                    value={wifi}
                    onChange={(e) => handleInputChange(e, setWifi)} required
                  />
                  <label
                    className={wifi ? 'floating' : ''}
                    onClick={() => handleClick('wifi')}>WiFi Password</label>
                </div>
              </div>

              <hr />
              <div className='button-bar'>
                <button type="submit" className='save-btn'>{loading ? 'Adding...' : 'Add Rooms'}</button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddRoom;