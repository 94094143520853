import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import axios from 'axios';
import { IoSearchOutline } from "react-icons/io5";
import AddGuests from './AddGuests';
import EditGuestPage from './EditGuestPage';
import { AuthContext } from '../../../Assets/MainAdminLAyout/Authorize/AuthContext';
import './Guests.css';
import Qr from '../../All pics/qr-code-svgrepo-com.png';
import Message from '../../All pics/message-square-lines-svgrepo-com (1).png';
import { debounce } from 'lodash';

const fetchHotelGuests = async (hotelId, page, size) => {
  const token = localStorage.getItem('hotelToken');

  if (!token || !hotelId) {
    throw new Error('No token or hotelId found');
  }

  try {
    const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/guests', {
      headers: {
        Authorization: `Bearer ${token}`,
        hotelId: hotelId
      },
      params: {
        page: page,
        size: size,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching hotel guests', error);
    throw error;
  }
};

const searchHotelGuests = async (hotelId, query) => {
  const token = localStorage.getItem('hotelToken');

  if (!token || !hotelId) {
    throw new Error('No token or hotelId found');
  }

  try {
    const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/guests/search', {
      headers: {
        Authorization: `Bearer ${token}`,
        hotelId: hotelId
      },
      params: {
        search: query,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error searching hotel guests', error);
    throw error;
  }
};

const Guests = () => {
  const [selectedRoom, setSelectedRoom] = useState(null);
  const { hotelId } = useContext(AuthContext);
  const [guests, setGuests] = useState([]);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    room: '',
    wifi: '',
    breakfast: '',
    checkIn: '',
    checkOut: ''
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showQrModal, setShowQrModal] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [editGuest, setEditGuest] = useState(null);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const observer = useRef();
  const lastGuestElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  const loadGuests = async (reset = false) => {
    if (loading) return;
    setLoading(true);
    try {
      const newPage = reset ? 0 : page;
      const guestsData = await fetchHotelGuests(hotelId, newPage, 50);
      const formattedGuestsData = guestsData.map(guest => ({
        ...guest,
        checkIn: guest.checkIn ? new Date(guest.checkIn) : '',
        checkOut: guest.checkOut ? new Date(guest.checkOut) : ''
      }));
      setGuests(prevGuests => reset ? formattedGuestsData : [...prevGuests, ...formattedGuestsData]);
      setHasMore(guestsData.length > 0);
    } catch (error) {
      console.error('Failed to fetch guests', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (hotelId) {
      loadGuests(true);
    }
  }, [hotelId]);

  useEffect(() => {
    if (page > 0) {
      loadGuests();
    }
  }, [page]);

  const handleAddGuestSubmit = () => {
    loadGuests(true);
    setShowAddModal(false);
  };

  const handleEditGuestSubmit = (updatedGuest) => {
    setGuests(guests.map(guest => (guest.id === updatedGuest.id ? updatedGuest : guest)));
    setShowEditModal(false);
  };

  const openEditModal = (guest) => {
    setEditGuest(guest);
    setShowEditModal(true);
    setFormData(guest);
  };

  const openQrModal = (qrCode, e, room) => {
    e.stopPropagation(); // Остановить всплытие события, чтобы не сработал другой обработчик
    setQrCode(qrCode);
    setShowQrModal(true);
    setSelectedRoom(room);
  };

  const debouncedSearch = useCallback(debounce(async (query) => {
    if (query) {
      setLoading(true);
      try {
        const searchResults = await searchHotelGuests(hotelId, query);
        const formattedGuestsData = searchResults.map(guest => ({
          ...guest,
          checkIn: guest.checkIn ? new Date(guest.checkIn) : '',
          checkOut: guest.checkOut ? new Date(guest.checkOut) : ''
        }));
        setGuests(formattedGuestsData);
        setHasMore(false);
      } catch (error) {
        console.error('Failed to search guests', error);
      }
      setLoading(false);
    } else {
      loadGuests(true);
    }
  }, 500), [hotelId]);

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const handleDeleteGuest = (guestId) => {
    setGuests((prevGuests) => prevGuests.filter(guest => guest.id !== guestId));
  };

  const closeQrModal = (e) => {
    if (e.target.className === 'modal') {
      setShowQrModal(false);
    }
  };

  const handleDownloadQR = () => {
    const link = document.createElement('a');
    link.href = `data:image/png;base64,${qrCode}`;
    link.download = 'qr-code.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePrintQR = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`<html><head><title>Print QR Code</title></head><body>`);
    printWindow.document.write(`<img src="data:image/png;base64,${qrCode}" style="max-width: 100%;" />`);
    printWindow.document.write(`</body></html>`);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  return (
    <div className='rooms-container'>
      <div className='room-container'>
        <div className='bar2'>
          <h1>Guests</h1>
          <div className="room-controls">
            <div className='search-br1'>
              <p><IoSearchOutline /></p>
              <input
                type="text"
                placeholder="Type Name Or Room"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
            <button onClick={() => setShowAddModal(true)}>+</button>
          </div>
        </div>

        <ul className="room-list">
          {guests.map((guest, index) => {
            const listItemContent = (
              <div className="room-info">
                <div className="room-column">
                  <h3>{guest.firstName} {guest.lastName}</h3>

                  <div className="room-column1">
                    <span className={`active-status ${guest.active === 'y' ? 'IN' : 'OUT'}`}>
                      {guest.active === 'y' ? 'IN' : 'OUT'}
                    </span>
                    <img src={Qr} alt='qr' onClick={(e) => openQrModal(guest.qrCode, e, guest)} />
                    <img src={Message} alt='message' />
                  </div>
                </div>
                <div className="room-description">
                  <p>Room: {guest.room}</p>
                  <p>{guest.breakfast === 'y' ? 'Breakfast Included' : 'Breakfast Excluded'}</p>
                </div>
              </div>
            );

            if (guests.length === index + 1) {
              return (
                <li ref={lastGuestElementRef} key={guest.id} className='room-list1' onClick={() => openEditModal(guest)}>
                  {listItemContent}
                </li>
              );
            } else {
              return (
                <li key={guest.id} className='room-list1' onClick={() => openEditModal(guest)}>
                  {listItemContent}
                </li>
              );
            }
          })}
        </ul>
        {showAddModal && (
          <AddGuests
            onClose={() => setShowAddModal(false)}
            onAddGuest={handleAddGuestSubmit}
            formData={formData} setFormData={setFormData}
          />
        )}
        {showEditModal && editGuest && (
          <EditGuestPage
            guest={editGuest}
            onClose={() => setShowEditModal(false)}
            onEditGuest={handleEditGuestSubmit}
            onDeleteGuest={handleDeleteGuest}
            formData={formData} setFormData={setFormData}
          />
        )}
        {showQrModal && (
          <div className='modal' onClick={closeQrModal}>
            <div className='modal-content'>
              <span className='close-qr' onClick={() => setShowQrModal(false)}>&times;</span>
              <img src={`data:image/png;base64,${qrCode}`} alt='QR Code' />
              <p className="room-link">
              <a href={selectedRoom.roomLink} target="_blank" rel="noopener noreferrer">{selectedRoom.roomLink}</a>
            </p>
              <div className="qr-actions">
                <button onClick={handleDownloadQR}>Download QR</button>
                <button onClick={handlePrintQR}>Print QR</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Guests;