import React from 'react'
import Bar from '../Assets/bar.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import './BarA.css'


const BarA = ({category}) => {
  return (
    <div className='bara-con'>
      <div className='bar-con'>
      <h2>Our Bar is located in  <FontAwesomeIcon icon={faLocationDot}  style={{color:'lightblue'}}/> 7th floor.</h2>
        <img src={Bar} alt="bar"/>
     </div> 
    </div>
  )
}

export default BarA