import React, { useState, useEffect } from 'react';
import './NavBar.css'
import { RiFileList3Fill } from "react-icons/ri";
import { useCart } from '../Cart/CartContext';
import { IoHome, IoMap, IoCart } from "react-icons/io5";
import { Link, useLocation } from 'react-router-dom';
import { BiSolidMessageDetail } from "react-icons/bi";
import { useOrderContext } from '../Cart/OrderContext';

const NavBar = () => {
  const [clicked, setClicked] = useState('');
  const location = useLocation();
  const { orderPlaced, handleOrderView } = useOrderContext();
  const { getTotalItems } = useCart();

  useEffect(() => {
    const path = location.pathname;
    setClicked(path);
  }, [location.pathname]);

  const handleClick = (path) => {
    setClicked(`/${path}`);
  };

  const handleOrdersClick = () => {
    handleClick('orders');
    if (handleOrderView) {
      handleOrderView();
    }
  };

  return (
    <nav className="navbar">
      <ul className="navbar-list">
        <li className={`navbar-item ${clicked === '/home' ? 'clicked' : ''}`}>
          <Link
            to="/home"
            style={{ textDecoration: 'none' }}
            className={`navbar-button ${clicked === '/home' ? 'clicked' : ''}`}
            onClick={() => handleClick('home')}
          >
            <p className={`nav-box ${clicked === '/home' ? 'clicked' : ''}`}></p>
            <IoHome className='icon' />
            <p className='text-p'>Home</p>
          </Link>
        </li>

        <li className={`navbar-item ${clicked === '/orders' ? 'clicked' : ''}`}>
          <Link 
            to="/orders" 
            style={{ textDecoration: 'none', color: 'var(--primaryColor)' }} 
            className={`navbar-button ${clicked === '/orders' ? 'clicked' : ''}`} 
            onClick={handleOrdersClick}
          >
            <p className={`nav-box ${clicked === '/orders' ? 'clicked' : ''}`}></p>
            <RiFileList3Fill className='icon' />
            <p className='text-p'>Orders</p>
            {orderPlaced && <span className="notification-dot"></span>}
          </Link>
        </li>

        <li className={`navbar-item ${clicked === '/nearus' ? 'clicked' : ''}`}>
          <Link 
            to="/nearus" 
            style={{ textDecoration: 'none' }} 
            className={`navbar-button ${clicked === '/nearus' ? 'clicked' : ''}`} 
            onClick={() => handleClick('nearus')}
          >
            <p className={`nav-box ${clicked === '/nearus' ? 'clicked' : ''}`}></p>
            <IoMap className='icon' />
            <p className='text-p'>NearUs</p>
          </Link>
        </li>

        <li className={`navbar-item ${clicked === '/cart' ? 'clicked' : ''}`}>
          <Link 
            to="/cart" 
            style={{ textDecoration: 'none' }} 
            className={`navbar-button ${clicked === '/cart' ? 'clicked' : ''}`} 
            onClick={() => handleClick('cart')}
          >
            <p className={`nav-box ${clicked === '/cart' ? 'clicked' : ''}`}></p>
            <IoCart className='icon cart-p' />
            <p className='text-p'>Cart</p>
            {getTotalItems() > 0 && <span className="notification-dot1">{getTotalItems()}</span>}
          </Link>
        </li>

        <li className={`navbar-item ${clicked === '/messages' ? 'clicked' : ''}`}>
          <Link 
            to="/messages" 
            style={{ textDecoration: 'none' }} 
            className={`navbar-button ${clicked === '/messages' ? 'clicked' : ''}`} 
            onClick={() => handleClick('messages')}
          >
            <p className={`nav-box ${clicked === '/messages' ? 'clicked' : ''}`}></p>
            <BiSolidMessageDetail className='icon' />
            <p className='text-p'>Messages</p>
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default NavBar;