import React, { useState, useRef, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';

const AddChat = ({ onClose, onAddGuest }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [room, setRoom] = useState('');
  const [breakfastChecked, setBreakfastChecked] = useState(false);
  const [checkInDate, setCheckInDate] = useState('');
  const [checkOutDate, setCheckOutDate] = useState('');
  const [guestsNumber, setGuestsNumber] = useState(1);
  const [roomOptions, setRoomOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const initialClickElement = useRef(null);
  const token = localStorage.getItem('hotelToken');

  const handleBreakfastChange = (e) => {
    setBreakfastChecked(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!roomOptions.some(option => option.roomNumber === room)) {
      setErrorMessage("This room doesn't exist");
      return;
    }

    const newGuest = {
      firstName,
      lastName,
      hotelId: parseInt(localStorage.getItem('hotelId')),
      room,
      breakfast: breakfastChecked ? 'y' : 'n',
      guestsNumber,
      checkIn: checkInDate,
      checkOut: checkOutDate,
    };

    try {
      await axios.post(process.env.REACT_APP_API_BASE_URL + '/register-guest', newGuest, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      onAddGuest(newGuest);
      onClose();
    } catch (error) {
      console.error('Failed to register guest', error);
    }
  };

  const handleInputChange = (e, setState) => {
    setState(e.target.value);
  };

  const handleDateChange = (date, setState) => {
    setState(date);
  };

  const handleBackgroundMouseDown = (e) => {
    initialClickElement.current = e.target;
  };

  const handleBackgroundMouseUp = (e) => {
    if (e.target.className === 'main-room' && initialClickElement.current === e.target) {
      onClose();
    }
    initialClickElement.current = null;
  };

  const fetchRoomOptions = useCallback(debounce(async (value) => {
    if (value.length > 0) {
      try {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/room/search', {
          headers: {
            Authorization: `Bearer ${token}`,
            hotelId: parseInt(localStorage.getItem('hotelId')),
          },
          params: {
            search: value,
          },
        });
        setRoomOptions(response.data);
        setShowDropdown(true);
      } catch (error) {
        console.error('Failed to fetch room options', error);
      }
    } else {
      setShowDropdown(false);
    }
  }, 500), []);

  const handleRoomNumberChange = (e) => {
    const { value } = e.target;
    setRoom(value);
    fetchRoomOptions(value);
    setErrorMessage(''); // Clear error message when typing
  };

  const handleRoomSelect = (selectedRoom) => {
    setRoom(selectedRoom.roomNumber);
    setBreakfastChecked(selectedRoom.breakfast === 'y');
    setShowDropdown(false);
    setErrorMessage('');
  };

  return (
    <div className="main-room" onMouseDown={handleBackgroundMouseDown} onMouseUp={handleBackgroundMouseUp}>
      <div className="add-room-options">
        <div className="added-box">
          <h3>Create New Chat</h3>
          <button className="close" onClick={onClose}>×</button>
        </div>
        <div className="room-form" style={{ paddingTop: '10px' }}>
          <form onSubmit={handleSubmit}>
            <div className="room-input-container cap1" style={{ position: 'relative' }}>
              <input
                type="text"
                name="room"
                value={room}
                onChange={handleRoomNumberChange}
                style={{ width: '100%' }}
                required
                onFocus={() => setShowDropdown(true)}
              />
              <label className={room ? 'floating' : ''}>Room Number</label>
              {showDropdown && roomOptions.length > 0 && (
                <ul className="dropdown-menu">
                  {roomOptions.map((room) => (
                    <li key={room.id} onClick={() => handleRoomSelect(room)}>
                      {room.roomNumber}
                    </li>
                  ))}
                </ul>
              )}
              {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>
            <div className="room-input-container">
                <input
                  type="text"
                  name="lastName"
                  value={lastName}
                  onChange={(e) => handleInputChange(e, setLastName)}
                  required
                />
                <label className={lastName ? 'floating' : ''}>Message</label>
              </div>

            <hr />
            <div className='button-bar'>
              <button type="submit" className='save-btn'>Send Message</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};


export default AddChat