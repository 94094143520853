import axios from 'axios';
import Swal from 'sweetalert2';


const addToCart = async (foodId, quantity) => {
  const token = localStorage.getItem('guestToken');
  const hotelId = localStorage.getItem('hotelId');
  const guestId = localStorage.getItem('id');

  if (!guestId) {
    console.error('Error: guestId is missing');
    Swal.fire('Error', 'Failed to change items in cart', 'error');
    return null;
  }

  try {
    const response = await axios.post(process.env.REACT_APP_API_BASE_URL + `/cart/add`, {
      foodId,
      quantity
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'hotelId': hotelId,
        'guestId': guestId
      }
    });

    return true; // Return true on success
  } catch (error) {
    console.error('Error adding to cart', error);
    Swal.fire('Error', 'Failed to change items in cart', 'error');
    return null;
  }
};

const getCartItems = async () => {
  const token = localStorage.getItem('guestToken');
  const hotelId = localStorage.getItem('hotelId');
  const guestId = localStorage.getItem('id');

  try {
    const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/cart/list`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'hotelId': hotelId,
        'guestId': guestId
      }
    });

    const cartId = response.data.id;
    localStorage.setItem('cartId', cartId);

    return response.data;
  } catch (error) {
    console.error('Error fetching cart items', error);
    return null;
  }
};

export { addToCart, getCartItems };
