import React, { useState } from 'react';
import Swal from 'sweetalert2';
import alarm from './alarmp.png'
import 'react-datepicker/dist/react-datepicker.css';
import './SerShow.css';
import checked from '../../Assets/All pics/checked_169780 1.png'

const WakeupService = ({ service, onSave }) => {
    const [selectedHour, setSelectedHour] = useState('');
    const [selectedMinute, setSelectedMinute] = useState('');
  
    const handleHourChange = (event) => {
      setSelectedHour(event.target.value);
    };
  
    const handleMinuteChange = (event) => {
      setSelectedMinute(event.target.value);
    };
  
    const handleSave = () => {
        Swal.fire({
          title: 'Confirm Selection',
          text: `Are you sure you want to set the wake-up call for ${selectedHour.padStart(2, '0')}:${selectedMinute.padStart(2, '0')}?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes, Confirm',
          cancelButtonText: 'Cancel',
          customClass: {
            confirmButton: 'custom-confirm-button',
            cancelButton: 'custom-cancel-button'
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            onSave(service.id, { hour: selectedHour, minute: selectedMinute });
    
            Swal.fire({
              title: 'Wake-Up Call Set',
              html: `<div>Your wake-up call for today at ${selectedHour.padStart(2, '0')}:${selectedMinute.padStart(2, '0')} has been set!</div>`,
              confirmButtonText: 'Close',
              iconHtml: `<img src="${checked}" style="width: 60px; height: 60px; border: none; box-shadow: none; border-radius: 0;"/>`,
              customClass: {
                icon: 'custom-icon',
                confirmButton: 'custom-confirm-button'
              },
              buttonsStyling: false,
            }).then(() => {
              setSelectedHour('');
              setSelectedMinute('');
            });
          }
        });
      };
    
    return (
      <div>
         < div className='title-box'>
        <h3 >{service.title}</h3>
        <p>Please Chose when you want to wake up.</p>
        </div>
        <div className='bed-box1'>
        <img src={alarm} alt='/alarm' className='alarm'/>
        <div className='time' >
          <div className="time-picker1">
          <label className=''>Today is:</label>
          <div className="formatted-date">Thu, {new Date().getDate()} {new Date().toLocaleString('en-US', { month: 'short' })}</div>
          </div>
  <div  className="time-picker1">
          <label >Select Time Here:</label>
          <div className="time-picker">
            <select value={selectedHour} onChange={handleHourChange}>
              <option value="" disabled>Hour</option>
              {Array.from({ length: 24 }, (_, i) => (
                <option key={i} value={String(i).padStart(2, '0')}>{String(i).padStart(2, '0')}</option>
              ))}
            </select>
            
            :
            <select value={selectedMinute} onChange={handleMinuteChange}>
  <option value="" disabled>Minute</option>
  {Array.from({ length: 60 / 5 }, (_, i) => (
    <option key={i * 5} value={String(i * 5).padStart(2, '0')}>
      {String(i * 5).padStart(2, '0')}
    </option>
  ))}
</select>
</div>
</div>
</div>
        
  
        
       
        <button
            className={`bed-button ${!selectedHour || !selectedMinute ? '' : 'active'}`}
            disabled={!selectedHour || !selectedMinute}
            onClick={handleSave}
          >
            Submit For Wake-up Call
          </button>
       
      </div>
      </div>
    );
  };
  

export default WakeupService;
