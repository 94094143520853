import React, { useState } from 'react';
import Sidebar from './Sidebar';
import ContactForm from './ContactForm';
import ThankYouMessage from './ThankYouMessage';
import './styles.css';

const MailNew = () => {
  const [showThankYou, setShowThankYou] = useState(false);

  return (
    <div className="container1">
      <Sidebar />
      <div className="san1">
        <strong>Sansara</strong>
      </div>
      {!showThankYou ? (
        <div className="content">
          <ContactForm setShowThankYou={setShowThankYou} />
        </div>
      ) : (
        <ThankYouMessage />
      )}
    </div>
  );
};

export default MailNew;