import React, { useState } from 'react';
import Swal from 'sweetalert2';
import './SerShow.css'
import { useOrderContext} from '../../Cart/OrderContext.jsx';
import checked from '../../Assets/All pics/checked_169780 1.png'
const BedService = ({ service, onSave }) => {
 
  const [selectedItems, setSelectedItems] = useState({});
  const [orderPlaced, setOrderPlaced] = useState(false);
  const { handlePlaceOrderBox } = useOrderContext();
  const handleCheckboxChange = (itemId) => {
    setSelectedItems(prev => ({
      ...prev,
      [itemId]: !prev[itemId],
    }));
  };
  const handleSave = () => {
    const selectedIds = Object.keys(selectedItems).filter(id => selectedItems[id]);

    // Show confirmation prompt
    Swal.fire({
      title: 'Confirm Order',
      text: 'Are you sure you want to place the order?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Place Order',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button'
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        // Proceed with placing the order
        onSave(service.id, selectedIds);
        setOrderPlaced(true);
        const totalAmount = calculateTotalAmount(selectedIds);
        const orderDetails = {
          id: service.id,
          name: service.title,
          items: selectedIds.map(id => {
            const item = service.items.find(item => item.id === id);
            return { id: item.id, title: item.name, price: item.price, quantity: 1 };
          }),
          totalItems: selectedIds.length,
          totalAmount: calculateTotalAmount(selectedIds),
          orderStatus: 'Pending',
          Name: 'Bed Service',
        };
        handlePlaceOrderBox(orderDetails);
      
        // Show success message
        Swal.fire({
          title: 'Order placed',
          html: `<div>Your order has been placed successfully! Selected items: ${selectedIds.join(', ')}</div>`,
          confirmButtonText: 'Close',
          iconHtml: `<img src="${checked}" style="width: 60px; height: 60px; border: none; box-shadow: none; border-radius: 0;"/>`,
          customClass: {
            icon: 'custom-icon',
            confirmButton: 'custom-confirm-button'
          },
          buttonsStyling: false,
        }).then(() => {
          setOrderPlaced(false);
          setSelectedItems({});

        });
      }
    });
  };

  const calculateTotalAmount = (selectedIds) => {
    return selectedIds.reduce((total, id) => {
      const item = service.items.find(item => item.id === id);
      return total + item.price;
    }, 0);
  };

  const selectedIds = Object.keys(selectedItems).filter(id => selectedItems[id]);
  const isButtonDisabled = selectedIds.length === 0;


  return (
    <div  >
      <h3 className='title-box'>{service.title}</h3>
      <div className='lan' >
        <div className='laundry'>
      {service.items.map(item => (
        <div key={item.id}  >
         <div className='lan-check' onClick={() => handleCheckboxChange(item.id)}>
         
            <input
            className='service-checkbox'
              type="checkbox"
              checked={!!selectedItems[item.id]}
              onChange={() => handleCheckboxChange(item.id)}
              onClick={(e) => e.stopPropagation()}
            />
            {item.name}
            <p>0</p>
      
          </div>
          <hr/>
        </div>
      ))}
      </div>
       < button className={`bed-button ${isButtonDisabled ? '' : 'active'}`} disabled={isButtonDisabled}  onClick={handleSave} >
        Place Order
      </button>
      </div>
    </div>
  );
};

export default BedService;
