import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

const placeholderImage = 'https://sansara-restaurant.s3.ap-southeast-1.amazonaws.com/hotel7/d3386c14-069d-4f0a-b65e-59ff6e20380e_dish-svgrepo-com.png';

const EditDish = ({ item, onClose, onEditItem, onDeleteItem }) => {
  const token = localStorage.getItem('hotelToken');
  const [formData, setFormData] = useState({
    id: item.id,
    title: item.title,
    description: item.description,
    category: item.category,
    price: item.price,
    currency: item.currency,
    photo: item.imageLink,
    availability: item.available,
    newPhotoFile: null, // To store the new photo file if uploaded
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const initialClickElement = useRef(null);
  const [isDeleting, setIsDeleting] = useState(false); // New state to prevent duplicate requests

  useEffect(() => {
    console.log('EditDish component mounted');
    return () => {
      console.log('EditDish component unmounted');
    };
  }, []);

  useEffect(() => {
    console.log('FormData changed', formData);
  }, [formData]);

  const compressImage = (file, maxWidth, maxHeight, quality) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            resolve(new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            }));
          }, 'image/jpeg', quality);
        };
      };
    });
  };

  const handleChange = async (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      const file = files[0];
      if (file) {
        try {
          const compressedFile = await compressImage(file, 1200, 1200, 0.7);
          setFormData((prevData) => ({
            ...prevData,
            photo: URL.createObjectURL(compressedFile),
            newPhotoFile: compressedFile,
          }));
        } catch (error) {
          console.error('Error compressing image:', error);
          // Fallback to original file if compression fails
          setFormData((prevData) => ({
            ...prevData,
            photo: URL.createObjectURL(file),
            newPhotoFile: file,
          }));
        }
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleDeleteImage = () => {
    setFormData((prevData) => ({
      ...prevData,
      photo: null,
      newPhotoFile: null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedItem = {
      id: formData.id,
      title: formData.title,
      description: formData.description,
      category: formData.category,
      price: parseFloat(formData.price),
      currency: formData.currency,
      imageLink: formData.photo === null ? null : item.imageLink,
      available: parseInt(formData.availability, 10),
      orderNumber: item.orderNumber,
    };

    const formDataToSend = new FormData();
    formDataToSend.append('foodDto', JSON.stringify(updatedItem));
    if (formData.newPhotoFile) {
      formDataToSend.append('file', formData.newPhotoFile);
    }

    try {
      console.log('Sending update request');
      await axios.put(process.env.REACT_APP_API_BASE_URL + '/restaurant/update', formDataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      const updatedImageLink = formData.photo === null ? placeholderImage : formData.photo;
      onEditItem({ ...updatedItem, imageLink: updatedImageLink });
      onClose();
    } catch (error) {
      console.error('Failed to update food item', error);
    }
  };

  const handleClick = (inputName) => {
    document.getElementsByName(inputName)[0].focus();
  };

  const handleBackgroundMouseDown = (e) => {
    initialClickElement.current = e.target;
  };

  const handleBackgroundMouseUp = (e) => {
    if (e.target.className === 'main-room' && initialClickElement.current === e.target) {
      onClose();
    }
    initialClickElement.current = null;
  };

  const handleDelete = async () => {
    if (isDeleting) return; // Prevent multiple requests
    setIsDeleting(true);

    try {
      await axios.delete(process.env.REACT_APP_API_BASE_URL + `/restaurant/${formData.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Delete request successful');
      onDeleteItem(formData.id);
      onClose(); // Close the modal after deleting
    } catch (error) {
      console.error('Failed to delete food item', error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleConfirmDelete = () => {
    console.log('Opening confirm delete modal');
    setShowConfirmModal(true);
  };

  const handleCancelDelete = () => {
    console.log('Cancel delete action');
    setShowConfirmModal(false);
  };

  const handleConfirmYes = () => {
    console.log('Confirming delete action');
    handleDelete();
    setShowConfirmModal(false);
  };

  return (
    <div className="main-room" onMouseDown={handleBackgroundMouseDown} onMouseUp={handleBackgroundMouseUp}>
      <div className="add-room-options">
        <div className="added-box">
          <h3>Edit Dish</h3>
          <button className="close" onClick={onClose}>×</button>
        </div>
        <div className="room-form m2 edit">
          <form onSubmit={handleSubmit}>
            <div className="room-input-container cap1">
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
                style={{ width: '100%' }}
              />
              <label
                className={formData.title ? 'floating' : ''}
                onClick={() => handleClick('title')}
              >
                Title
              </label>
            </div>
            <div className="room-input-container cap1">
              <input
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
                style={{ width: '100%' }}
              />
              <label
                className={formData.description ? 'floating' : ''}
                onClick={() => handleClick('description')}
              >
                Description
              </label>
            </div>

            <div className="room-input-container cap1">
              <input
                type="text"
                name="price"
                value={formData.price}
                onChange={handleChange}
                required
                style={{ width: '100%' }}
              />
              <label
                className={formData.price ? 'floating' : ''}
                onClick={() => handleClick('price')}
              >
                Price
              </label>
            </div>

            <div className="room-input-container cap1">
              <select
                name="currency"
                value={formData.currency}
                onChange={handleChange}
                required
              >
                <option value="USD">USD</option>
                <option value="AED">AED</option>
              </select>
              <label
                className={formData.currency ? 'floating' : ''}
                onClick={() => handleClick('currency')}
              >
                Currency
              </label>
            </div>

            <div className="room-input-container cap1">
              <select
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
              >
                <option value="Appetizer">Appetizer</option>
                <option value="Fast Food">Fast Food</option>
                <option value="Drinks">Drinks</option>
                <option value="Alcohol">Alcohol</option>
                <option value="Main Courses">Main Courses</option>
                <option value="Salads">Salads</option>
                <option value="Desserts">Desserts</option>
                <option value="Soups">Soups</option>
              </select>
              <label
                className={formData.category ? 'floating' : ''}
                onClick={() => handleClick('category')}
              >
                Category
              </label>
            </div>

            <div className="room-input-container date-picker-container cap1">
              <select
                name="availability"
                value={formData.availability}
                onChange={handleChange}
                required
              >
                <option value="1">Available</option>
                <option value="0">Not Available</option>
              </select>
              <label
                className={formData.availability ? 'floating' : ''}
                onClick={() => handleClick('availability')}
              >
                Availability
              </label>
            </div>

            <div className="">
              {formData.photo && (
                <div className="itemcon" style={{ position: 'relative' }}>
                  <img src={formData.photo} alt="Current Photo" className="photo-preview" />
                  <button
                    type="button"
                    className="delete-image-button"
                    onClick={handleDeleteImage}
                  >
                    &ndash;
                  </button>
                </div>
              )}
              <label htmlFor="file-input" className="file-input-label">
                {formData.photo ? 'Change Image' : 'Add Image'}
              </label>
              <input
                type="file"
                name="photo"
                accept="image/*"
                id="file-input"
                className="file-input"
                onChange={handleChange}
              />
            </div>
            <hr />
            <div className="button-bar">
              <button type="button" className="delete-button" onClick={handleConfirmDelete}>Delete</button>
              <button type="submit" className="save-btn">Save</button>
            </div>
          </form>
        </div>
      </div>

      {showConfirmModal && (
        <div className="modal" onClick={handleCancelDelete}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>Are you sure you want to delete this dish?</h3>
            <div className="button-bar">
              <button onClick={handleConfirmYes} className="delete-button">Yes</button>
              <button onClick={handleCancelDelete} className="save-btn">No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditDish;
