import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './MainBanner.css';
import img1 from '../Assets/baa.png';
import img2 from '../Assets/soups.png';
import img3 from '../Assets/salads.png';
import img4 from '../Assets/main dishes.png';
import img5 from '../Assets/baa.png';

const bannerData = [
  {
    id: 1,
    image: img1, // Replace with actual image path
    title: 'Disable Friendly',
    describe: 'wheelchair-accessible rooms, grab bars in bathrooms, and roll-in showers',
    style: {
      background: 'linear-gradient(135deg, #F98957, #F4D291)', // Gold to orange gradient
      color: '#FFF' // Black text
    },
    route: '/basket'
  },
  {
    id: 2,
    image: img2, // Replace with actual image path
    title: 'Bicycle Rent',
    describe: 'pedal through city sights with ease',
    style: {
      background: 'linear-gradient(135deg,#FFD300, #FFA500)', // Dodger blue to deep sky blue gradient
      color: '#FFF' // White text
    },
    route: '/facilities'
  },
  {
    id: 3,
    image: img3, // Replace with actual image path
    title: 'Salad',
    describe: 'with chicken, tomato, pepper, and special sauce',
    style: {
      background: 'linear-gradient(135deg,  #F98957,#FFA500)', // Lime green to chartreuse gradient
      color: '#FFF' // White text
    },
    route: '/service'
  },
  {
    id: 4,
    image: img4, // Replace with actual image path
    title: 'Bath Robes',
    describe: 'pool time is from 8am to 10pm luxurious comfort for everyday relaxation',
    style: {
      background: 'linear-gradient(135deg, #5D9AEA, #FF1480)', // Hot pink to deep pink gradient
      color: '#FFF' // White text
    },
    route: '/activities'
  },
  {
    id: 5,
    image: img5, // Replace with actual image path
    title: 'Free Parking',
    describe: 'parking at B1 and G floor',
    style: {
      background: 'linear-gradient(135deg, #5D9AEA, #69DEE5)', // Indigo to blue violet gradient
      color: '#FFF' // White text
    },
    route: '/activities'
  },
];

const MainBanner = () => {
  return (
    <Swiper
    modules={[Navigation, Pagination, Scrollbar, A11y]}
    spaceBetween={15}
    slidesPerView={1.5}
    centeredSlides={true}
    loop={true}
    pagination={{ clickable: true }}
    className="banner-container"
    breakpoints={{
      355:{
        slidesPerView: 1.14,
      },
      360: {
        slidesPerView: 1.15,
      },
      480: {
        slidesPerView: 1.15,
      },
      768: {
        slidesPerView: 1.15,
      },
      820: {
        slidesPerView: 1.3,
      },
    }}
  >
   
    {bannerData.map((item) => (
        <SwiperSlide key={item.id}>
      <Link 
        to={item.route} 
        key={item.id} 
        className="banner-item"
        style={item.style}
      >
        <img src={item.image} alt={item.title} />
        <div className="banner-content">
          <h2>{item.title}</h2>
          <p>{item.describe}</p>
        </div>
      </Link>

 
   </SwiperSlide>
  ))}
</Swiper>
  
  );
};

export default MainBanner;
