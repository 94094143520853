import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

const WakeUpCallForm = ({ serviceId, onSubmit, onClose, isEditing, initialFormData, onDeleteService }) => {
    const [formData, setFormData] = useState({
        name: 'Wake Up Call',
        description: '',
        available: 'Available',
        price: 0,
        currency: 'USD',
    });

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const initialClickElement = useRef(null);
    const token = localStorage.getItem('hotelToken');
    const hotelId = localStorage.getItem('hotelId');

    useEffect(() => {
        if (isEditing && initialFormData) {
            setFormData({
                ...initialFormData,
                available: initialFormData.available === 1 ? 'Available' : 'Not Available',
            });
        }
    }, [isEditing, initialFormData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            hotelId: hotelId,
            serviceId: serviceId,
            ...formData,
            available: formData.available === 'Available' ? 1 : 0,
        };

        try {
            let response;
            if (isEditing) {
                const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/hotel-service/update`;
                response = await axios.put(apiUrl, payload, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        hotelId: hotelId,
                    },
                });
            } else {
                const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/hotel-service/add`;
                response = await axios.post(apiUrl, payload, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        hotelId: hotelId,
                    },
                });
            }

            onSubmit(response.data);
            onClose();
        } catch (error) {
            console.error('Error adding/editing Wake Up Call service:', error);
        }
    };

    const handleBackgroundMouseDown = (e) => {
        initialClickElement.current = e.target;
    };

    const handleBackgroundMouseUp = (e) => {
        if (e.target.className === 'main-room' && initialClickElement.current === e.target) {
            onClose();
        }
        initialClickElement.current = null;
    };

    const handleDelete = async () => {
        if (isDeleting) return;
        setIsDeleting(true);

        try {
            await axios.delete(process.env.REACT_APP_API_BASE_URL + `/hotel-service/${formData.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            onDeleteService(formData.id);
            onClose();
        } catch (error) {
            console.error('Failed to delete amenity', error);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleConfirmDelete = () => {
        setShowConfirmModal(true);
    };

    const handleCancelDelete = () => {
        setShowConfirmModal(false);
    };

    const handleConfirmYes = () => {
        handleDelete();
        setShowConfirmModal(false);
    };

    return (
        <div className='main-room' onMouseDown={handleBackgroundMouseDown} onMouseUp={handleBackgroundMouseUp}>
            <div className="add-room-options">
                <div className='added-box'>
                    <h3>{isEditing ? 'Edit Wake Up Call' : 'Add Wake Up Call'}</h3>
                    <button className="close" onClick={onClose}>×</button>
                </div>
                <div className="room-form m2 edit">
                    <form onSubmit={handleSubmit}>
                        <div className="room-input-container cap1">
                            <input type="text" name="name" value={formData.name} readOnly />
                            <label className="floating">Name</label>
                        </div>
                        <div className="room-input-container cap1">
                            <textarea type="text" name="description" value={formData.description} onChange={handleChange} required />
                            <label className={formData.description ? 'floating' : ''}>Notice For Guests</label>
                        </div>
                        <div className="room-input-container cap1">
                            <select name="available" value={formData.available} onChange={handleChange} required>
                                <option value="Available">Available</option>
                                <option value="Not Available">Not Available</option>
                            </select>
                            <label className="floating">Availability</label>
                        </div>
                        <hr />
                        <div className="button-bar">
                            {isEditing && ( // Conditionally render the "Delete" button
                                <button type="button" className="delete-button" onClick={handleConfirmDelete}>
                                    Delete
                                </button>
                            )}
                            <button type="submit" className="save-btn">
                                {isEditing ? 'Save' : 'Add Service'} {/* Change button text based on isEditing */}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {showConfirmModal && (
                <div className="modal" onClick={handleCancelDelete}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <h3>Are you sure you want to delete this amenity?</h3>
                        <div className="button-bar">
                            <button onClick={handleConfirmYes} className="delete-button">Yes</button>
                            <button onClick={handleCancelDelete} className="save-btn">No</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default WakeUpCallForm;