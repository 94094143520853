
import React, { useState } from 'react';
import initialActivities from '../../Activities.js';
import EditActivityPage from './EditActivityPage';
import ActivityForm from './ActivityForm.jsx';
import { IoSearchOutline } from "react-icons/io5";
import '../MainActivities/MainActivities.css'
const MainActivities = () => {
    const [activities, setActivities] = useState(initialActivities);
    const [searchQuery, setSearchQuery] = useState('');
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editActivity, setEditActivity] = useState(null);


    const initialDates = activities.reduce((acc, activity) => {
        if (Array.isArray(activity.availableDates)) {
          return [...acc, ...activity.availableDates];
        }
        return acc;
      }, []);
      
    const handleSaveDates = (dates) => {
        // Implement your logic to save dates here
        console.log('Saved dates:', dates);
      };
    
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleAddActivitySubmit = (newActivity) => {
        setActivities([...activities, newActivity]);
        setShowAddModal(false);
    };

    const handleEditActivitySubmit = (updatedActivity) => {
        setActivities(activities.map(activity => activity.id === updatedActivity.id ? updatedActivity : activity));
        setShowEditModal(false);
    };

    const handleDeleteActivity = (activityId) => {
        setActivities(activities.filter(activity => activity.id !== activityId));
        setShowEditModal(false);
    };

    const toggleActivityStatus = (activityId) => {
        setActivities(activities.map(activity => 
            activity.id === activityId ? { ...activity, status: activity.status === 'ON' ? 'OFF' : 'ON' } : activity
        ));
    };

    const openEditModal = (activity) => {
        setEditActivity(activity);
        setShowEditModal(true);
    };

    const filteredActivities = activities.filter(activity => 
        activity.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className='rooms-container'>
          
        <div className='room-container'>
         <div  className='bar2'> 
                <h1>Activities</h1>
                <div className="room-controls">
                    <div className='search-br1'>
                        <p><IoSearchOutline /></p>
                        <input
                            type="text"
                            placeholder="Type Activity Title"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </div>
                    <button onClick={() => setShowAddModal(true)}>+</button>
                </div>
            </div>

            <ul className="room-list">
                {filteredActivities.map(activity => (
                    <li key={activity.id} className='room-list1' onClick={() => openEditModal(activity)}>
                       <div className="room-info">
                              <div className="room-column">
                                <h4>{activity.title}</h4>
                                <div 
                                    className={`activity-status ${activity.status.toLowerCase()}`} 
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent triggering the parent click event
                                        toggleActivityStatus(activity.id);
                                    }}
                                >
                                    {activity.status}
                                </div>
                            </div>
                            <div className="room-description">
                                <p>{activity.title2}</p>
                                <p>Price: {activity.price}</p>
                                <p>Rating: {activity.star}</p>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>

            {showAddModal && (
                <ActivityForm
                    onClose={() => setShowAddModal(false)}
                    onAddActivity={handleAddActivitySubmit}
                    initialDates={initialDates} 
                    onSaveDates={handleSaveDates}
                />
            )}
            {showEditModal && editActivity && (
                <EditActivityPage
                    activity={editActivity}
                    onClose={() => setShowEditModal(false)}
                    onEditActivity={handleEditActivitySubmit}
                    onDeleteActivity={handleDeleteActivity}
                    initialDates={initialDates}
                     onSaveDates={handleSaveDates}
                />
            )}
            </div>
        </div>
    );
};

export default MainActivities