import React, { useState, useRef, useEffect, useCallback } from 'react';
import { BiSend } from 'react-icons/bi';
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { IoMdCheckmark } from "react-icons/io";
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import './Chat.css'

const FocusedChat = ({ guestId }) => {
    const token = localStorage.getItem('hotelToken');
    const [newMessage, setNewMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [guestDetails, setGuestDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const messageListRef = useRef(null);
    const inputRef = useRef(null);
    const loadingRef = useRef(false);

    const parseGuestDetails = (fullName) => {
        const match = fullName.match(/(\d+),\s*(.*)/);
        if (match) {
            return {
                roomNumber: match[1],
                name: match[2]
            };
        }
        return { name: fullName, roomNumber: 'Unknown' };
    };

    const formatGuestName = (name, roomNumber) => {
        return `${name}`;
    };

    const loadMessages = useCallback(async (initialLoad = false) => {
        if (!guestId || loadingRef.current || isLoading) return;

        setIsLoading(true);
        loadingRef.current = true;

        const hotelId = localStorage.getItem('hotelId');

        console.log(`Loading messages for guest ID: ${guestId}, Page: ${page}`);

        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/chat/messages`, {
                params: {
                    userType: 'HOTEL',
                    page,
                    size: 50
                },
                headers: {
                    'Authorization': `Bearer ${token}`,
                    hotelId,
                    guestId,
                }
            });

            console.log('API response:', response.data);

            const newMessages = response.data.content.map(message => {
                const details = parseGuestDetails(message.senderName);
                return {
                    id: message.id,
                    text: message.content,
                    sent: message.messageSender === 'HOTEL',
                    timestamp: format(parseISO(message.created), 'MM.dd.yyyy hh:mm a'),
                    delivered: message.readByGuest,
                    name: message.messageSender === 'HOTEL' ? message.senderName : formatGuestName(details.name, details.roomNumber)
                };
            });

            console.log('Loaded messages:', newMessages.length);

            setMessages(prevMessages => {
                const uniqueMessages = newMessages.filter(
                    newMsg => !prevMessages.some(prevMsg => prevMsg.id === newMsg.id)
                );
                return initialLoad ? uniqueMessages.reverse() : [...uniqueMessages.reverse(), ...prevMessages];
            });

            setHasMore(response.data.totalPages > page + 1);
            setPage(prevPage => prevPage + 1);

            if (!guestDetails && newMessages.length > 0) {
                const guestMessage = newMessages.find(msg => msg.sent === false);
                if (guestMessage) {
                    setGuestDetails(parseGuestDetails(guestMessage.name));
                }
            }
        } catch (error) {
            console.error('Error loading messages:', error);
        } finally {
            setIsLoading(false);
            loadingRef.current = false;
        }
    }, [guestId, page, process.env.REACT_APP_API_BASE_URL, guestDetails, isLoading]);

    const handleScroll = useCallback(() => {
        if (messageListRef.current && !isLoading && hasMore) {
            const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
            if (scrollTop === 0) {
                const currentScrollHeight = scrollHeight;
                loadMessages().then(() => {
                    if (messageListRef.current) {
                        messageListRef.current.scrollTop = messageListRef.current.scrollHeight - currentScrollHeight;
                    }
                });
            }
        }
    }, [isLoading, hasMore, loadMessages]);

    useEffect(() => {
        const messageList = messageListRef.current;
        if (messageList) {
            messageList.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (messageList) {
                messageList.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);

    useEffect(() => {
        if (guestId) {
            setMessages([]);
            setPage(0);
            setHasMore(true);
            setGuestDetails(null);
            loadingRef.current = false;
            setIsLoading(false);
        }
    }, [guestId]);

    useEffect(() => {
        if (guestId && page === 0 && !isLoading && !loadingRef.current) {
            loadMessages(true);
        }
    }, [guestId, page, loadMessages, isLoading]);

    useEffect(() => {
        if (messageListRef.current && page === 1) {
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
    }, [messages, page]);

    const handleInputChange = (e) => {
        setNewMessage(e.target.value);
    };

    const handleSendClick = async () => {
        if (newMessage.trim() !== '') {
            const hotelId = localStorage.getItem('hotelId');

            try {
                const response = await axios.post(process.env.REACT_APP_API_BASE_URL + `/chat/send`, {
                    hotelId,
                    guestId,
                    content: newMessage,
                    userType: 'HOTEL'
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                const sentMessage = {
                    id: response.data.id,
                    text: newMessage,
                    sent: true,
                    timestamp: format(new Date(), 'MM.dd.yyyy hh:mm a'),
                    delivered: false,
                    name: localStorage.getItem('name'),
                };

                setMessages(prevMessages => [...prevMessages, sentMessage]);
                setNewMessage('');

                if (messageListRef.current) {
                    messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
                }
            } catch (error) {
                console.error('Error sending message:', error);
            }
        }
    };

    return (
        <div className="focused-chat">
            <div className="chat-header">
                <h2>{guestDetails ? `${guestDetails.name}` : 'Loading...'}</h2>
            </div>

            <div className="chat-container">
                <div className="chat-messages" ref={messageListRef}>
                    {isLoading && <div className="loading">Loading messages...</div>}
                    {messages.map((message) => (
                        <div 
                            key={message.id} 
                            className={`message-container ${message.sent ? 'sent' : 'received'}`}
                        >
                            <div className={`message-item ${message.sent ? 'sent' : 'received'}`}>
                                <div className="message-name">{message.name}</div>
                                <div className="message-text">{message.text}</div>
                                <div className="message-timestamp">
                                    {message.timestamp}
                                    {message.sent && (
                                        message.delivered ? <IoCheckmarkDoneOutline className='sent-icon' /> : <IoMdCheckmark className="sent-icon" />
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="chat-input-container">
                    <textarea
                        value={newMessage}
                        onChange={handleInputChange}
                        ref={inputRef}
                        placeholder="Drop your message here..."
                        className="chat-input"
                        rows={1}
                        style={{ height: 'auto', overflowY: 'hidden' }}
                        onInput={(e) => {
                            e.target.style.height = 'auto';
                            e.target.style.height = `${e.target.scrollHeight}px`;
                        }}
                    />
                    <button onClick={handleSendClick} className="send-button">
                        <BiSend />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FocusedChat;
