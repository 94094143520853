import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const EditOrderModal = ({ order, onClose, onEditOrder }) => {
    const [editedOrder, setEditedOrder] = useState({ ...order, orderDetailsDto: order.orderDetailsDto || [] });
    const [editingItemId, setEditingItemId] = useState(null);
    const [tempEditItem, setTempEditItem] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const modalRef = useRef(null);
    const itemInputRef = useRef(null);
    const initialClickElement = useRef(null);
    const token = localStorage.getItem('hotelToken');

    useEffect(() => {
        setEditedOrder({ ...order, orderDetailsDto: order.orderDetailsDto || [] });
    }, [order]);

    const handleStatusChange = (e) => {
        setEditedOrder({ ...editedOrder, orderStatus: e.target.value });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedOrder({ ...editedOrder, [name]: value });
    };

    const handleReduceItem = (itemId) => {
        if (editedOrder.title === 'Activity') return;
    
        const updatedItems = editedOrder.orderDetailsDto.filter(item => item.title !== itemId);
        updateOrderDetails(updatedItems);
    };

    const handleItemClick = (item) => {
        if (editingItemId === item.title) return;
        
        if (editingItemId) {
            saveItemChanges();
        }
        
        setEditingItemId(item.title);
        setTempEditItem({ ...item });
    };

    const handleItemInputChange = (field, value) => {
        setTempEditItem(prev => ({ ...prev, [field]: value }));
    };

    const saveItemChanges = () => {
        if (tempEditItem) {
            const updatedItems = editedOrder.orderDetailsDto.map(item =>
                item.title === editingItemId ? tempEditItem : item
            );
            updateOrderDetails(updatedItems);
            setEditingItemId(null);
            setTempEditItem(null);
        }
    };

    const updateOrderDetails = (updatedItems) => {
        const totalQuantity = updatedItems.reduce((acc, item) => acc + Number(item.itemQuantity), 0);
        const totalAmount = updatedItems.reduce((acc, item) => acc + Number(item.amount), 0).toFixed(2);
        setEditedOrder(prev => ({
            ...prev,
            orderDetailsDto: updatedItems,
            totalQuantity,
            totalAmount
        }));
    };

    const handleItemKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            saveItemChanges();
        }
    };

    const handleAddNewItem = () => {
        const newItem = {
            title: 'Title',
            itemQuantity: 1,
            amount: '0.00'
        };
        const updatedItems = [...editedOrder.orderDetailsDto, newItem];
        updateOrderDetails(updatedItems);
        handleItemClick(newItem);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (editingItemId && 
                modalRef.current &&
                modalRef.current.contains(event.target) &&
                (!itemInputRef.current || !itemInputRef.current.contains(event.target))
            ) {
                saveItemChanges();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [editingItemId, tempEditItem]);

    const handleSave = async () => {
        setIsSaving(true);

        try {
            if (!token) throw new Error('Token not found');

            await axios.put(process.env.REACT_APP_API_BASE_URL + `/order/update`, editedOrder, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            onEditOrder(editedOrder);
            onClose();
        } catch (error) {
            console.error('Failed to update order:', error);
            // handle error, e.g., show an alert
        } finally {
            setIsSaving(false);
        }
    };

    const handleBackgroundMouseDown = (e) => {
        initialClickElement.current = e.target;
    };

    const handleBackgroundMouseUp = (e) => {
        if (e.target.className === 'main-room' && initialClickElement.current === e.target) {
            saveItemChanges();
            onClose();
        }
        initialClickElement.current = null;
    };

    return (
        <div className="main-room" ref={modalRef} onMouseDown={handleBackgroundMouseDown} onMouseUp={handleBackgroundMouseUp}>
            <div className="add-room-options">
                <div className='added-box'>
                    <h3>Edit {order.title} Order</h3>
                    <button className="close" onClick={() => { saveItemChanges(); onClose(); }}>×</button>
                </div>
                <div className="room-form edit">
                    <form onSubmit={(e) => { e.preventDefault(); saveItemChanges(); handleSave(); }}>
                        <div className="room-input-container cap1">
                            <input
                                type="text"
                                name="title"
                                value={editedOrder.title}
                                onChange={handleInputChange}
                                required
                            />
                            <label className={editedOrder.title ? 'floating' : ''}>
                                Order Type
                            </label>
                        </div>

                        {editedOrder.title !== 'Activity' && (
                            <>
                                <div className="room-input-container cap1">
                                    <input
                                        type="text"
                                        name="guestName"
                                        value={editedOrder.guestName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <label className={editedOrder.guestName ? 'floating' : ''}>
                                        Guest Name
                                    </label>
                                </div>
                                <div className="room-input-container cap1">
                                    <input
                                        type="text"
                                        name="roomNumber"
                                        value={editedOrder.roomNumber}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <label className={editedOrder.roomNumber ? 'floating' : ''}>
                                        Room Number
                                    </label>
                                </div>
                                <div className="room-input-container cap1">
                                    <input
                                        type="text"
                                        name="totalQuantity"
                                        value={editedOrder.totalQuantity}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <label className={editedOrder.totalQuantity ? 'floating' : ''}>
                                        Total Items
                                    </label>
                                </div>
                            </>
                        )}

                        {editedOrder.title === 'Activity' && (
                            <>
                                <div className="room-input-container cap1">
                                    <input
                                        type="date"
                                        name="date"
                                        value={editedOrder.date}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <label className={editedOrder.date ? 'floating' : ''}>
                                        Date
                                    </label>
                                </div>
                                <div className="room-input-container cap1">
                                    <input
                                        type="number"
                                        name="totalPeople"
                                        value={editedOrder.totalPeople}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <label className={editedOrder.totalPeople ? 'floating' : ''}>
                                        Total People
                                    </label>
                                </div>
                            </>
                        )}

                        <div className="room-input-container cap1">
                            <input
                                type="text"
                                name="totalAmount"
                                value={editedOrder.totalAmount}
                                onChange={handleInputChange}
                                required
                            />
                            <label className={editedOrder.totalAmount ? 'floating' : ''}>
                                Total Price
                            </label>
                        </div>

                        <div className="room-input-container">
                            <select
                                value={editedOrder.orderStatus}
                                onChange={handleStatusChange}
                            >
                                <option value="Pending">Pending</option>
                                <option value="Preparing">Preparing</option>
                                <option value="Approve" style={{ color: '#1A7242' }}>Approve</option>
                                <option value="Ready" style={{ color: '#1A7242' }}>Ready</option>
                                <option value="Declined" style={{ color: '#ff0000' }}>Declined</option>
                            </select>
                            <label className="floating">
                                Status
                            </label>
                        </div>

                        {(editedOrder.title === 'Service' || editedOrder.title === 'Restaurant') && (
                            <div className='item-row'>
                                {editedOrder.orderDetailsDto.map(item => (
                                    <div key={item.title} className='item-box'>
                                        <div className="item-content" onClick={() => handleItemClick(item)}>
                                            {editingItemId === item.title ? (
                                                <div ref={itemInputRef}>
                                                    <input
                                                        type="text"
                                                        value={tempEditItem.title}
                                                        onChange={(e) => handleItemInputChange('title', e.target.value)}
                                                        onKeyDown={handleItemKeyDown}
                                                    />
                                                    <input
                                                        type="text"
                                                        value={tempEditItem.itemQuantity}
                                                        onChange={(e) => handleItemInputChange('itemQuantity', e.target.value)}
                                                        onKeyDown={handleItemKeyDown}
                                                    />
                                                    <input
                                                        type="text"
                                                        value={tempEditItem.amount}
                                                        onChange={(e) => handleItemInputChange('amount', e.target.value)}
                                                        onKeyDown={handleItemKeyDown}
                                                    />
                                                </div>
                                            ) : (
                                                <p>
                                                    {item.title}
                                                    <span className='hr-divider'> | </span>
                                                    {item.itemQuantity} {item.itemQuantity === 1 ? 'item' : 'items'}
                                                    <span className='hr-divider'> | </span>
                                                    ${parseFloat(item.amount).toFixed(2)}
                                                </p>
                                            )}
                                        </div>
                                        <div className="item-delete" onClick={() => handleReduceItem(item.title)}>
                                            –
                                        </div>
                                    </div>
                                ))}
                                <div className="add-item-button">
                                    <div className="item-content add-item" onClick={handleAddNewItem}>
                                        <p>+</p>
                                    </div>
                                </div>
                            </div>
                        )}

                        <hr />
                        <div className="button-bar">
                            <button type="submit" className="save-btn" disabled={isSaving}>
                                {isSaving ? 'Saving...' : 'Save'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditOrderModal;