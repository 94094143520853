import React, { useState, useRef } from 'react';
import axios from 'axios';

const placeholderImage = 'https://sansara-restaurant.s3.ap-southeast-1.amazonaws.com/dish-svgrepo-com.png';

const AddDish = ({ onClose, onAddItem }) => {
  const token = localStorage.getItem('hotelToken');
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    availability: 'available',
    price: '',
    currency: 'USD',
    category: '',
    photo: null,
  });
  const initialClickElement = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const compressImage = (file, maxWidth, maxHeight, quality) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            resolve(new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            }));
          }, 'image/jpeg', quality);
        };
      };
    });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const compressedFile = await compressImage(file, 1200, 1200, 0.7);
        setFormData((prevData) => ({
          ...prevData,
          photo: compressedFile,
        }));
      } catch (error) {
        console.error('Error compressing image:', error);
        // Fallback to original file if compression fails
        setFormData((prevData) => ({
          ...prevData,
          photo: file,
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const hotelId = localStorage.getItem('hotelId');
  
    const form = new FormData();
    form.append('foodDto', JSON.stringify({
      title: formData.title,
      description: formData.description || null,
      category: formData.category,
      price: formData.price,
      currency: formData.currency,
      available: formData.availability === 'available' ? 1 : 0,
    }));
  
    if (formData.photo) {
      form.append('file', formData.photo);
    }
  
    try {
      console.log('Sending request to backend...');
      setLoading(true);
      const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/restaurant/create', form, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          hotelId: hotelId,
        },
      });
      console.log('Response:', response.data);
  
      const newItem = {
        id: response.data.id,
        title: response.data.title,
        description: response.data.description,
        category: response.data.category,
        price: response.data.price,
        currency: response.data.currency,
        imageLink: response.data.imageLink || (formData.photo ? URL.createObjectURL(formData.photo) : placeholderImage),
        available: response.data.available,
        orderNumber: response.data.orderNumber,
      };
  
      onAddItem(newItem); // Add the new item to the list
      onClose(); // Close the modal
    } catch (error) {
      console.error('Failed to add dish', error);
    }
  };

  const handleClick = (inputName) => {
    document.getElementsByName(inputName)[0].focus();
  };

  const handleBackgroundMouseDown = (e) => {
    initialClickElement.current = e.target;
  };

  const handleBackgroundMouseUp = (e) => {
    if (e.target.className === 'main-room' && initialClickElement.current === e.target) {
      onClose();
    }
    initialClickElement.current = null;
  };

  return (
    <div className='main-room' onMouseDown={handleBackgroundMouseDown} onMouseUp={handleBackgroundMouseUp}>
      <div className="add-room-options">
        <div className='added-box'>
          <h3>Add Dish</h3>
          <button className="close" onClick={onClose}>×</button>
        </div>
        <div className="room-form m2 edit">
          <form onSubmit={handleSubmit}>
            <div className="room-input-container cap1">
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
                style={{ width: '100%' }}
              />
              <label className={formData.title ? 'floating' : ''} onClick={() => handleClick('title')}>Title</label>
            </div>
            <div className="room-input-container cap1">
              <input
                type="text"
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                style={{ width: '100%' }}
              />
              <label className={formData.description ? 'floating' : ''} onClick={() => handleClick('description')}>Description</label>
            </div>
            <div className="room-input-container cap1">
              <input
                type="text"
                id="price"
                name="price"
                value={formData.price}
                onChange={handleChange}
                required
                style={{ width: '100%' }}
              />
              <label className={formData.price ? 'floating' : ''} onClick={() => handleClick('price')}>Price</label>
            </div>
            <div className="room-input-container cap1">
              <select
                id="currency"
                name="currency"
                value={formData.currency}
                onChange={handleChange}
                required
                style={{ width: '100%' }}
              >
                <option value="USD">USD</option>
                <option value="AED">AED</option>
              </select>
              <label className={formData.currency ? 'floating' : ''} onClick={() => handleClick('currency')}>Currency</label>
            </div>
            <div className="room-input-container cap1">
              <select
                id="category"
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
                style={{ width: '100%' }}
              >
                <option value="" disabled hidden></option>
                <option value="Appetizer">Appetizer</option>
                <option value="Fast Food">Fast Food</option>
                <option value="Drinks">Drinks</option>
                <option value="Alcohol">Alcohol</option>
                <option value="Main Courses">Main Courses</option>
                <option value="Salads">Salads</option>
                <option value="Desserts">Desserts</option>
                <option value="Soups">Soups</option>
              </select>
              <label className={formData.category ? 'floating' : ''} onClick={() => handleClick('category')}>Category</label>
            </div>
            <div className="room-input-container date-picker-container cap1"> 
              <select
                id="availability"
                name="availability"
                value={formData.availability}
                onChange={handleChange}
                required
              >
                <option value="available">Available</option>
                <option value="not-available">Not Available</option>
              </select>
              <label className={formData.availability ? 'floating' : ''} onClick={() => handleClick('availability')}>Availability</label>
            </div>
            <div className=" ">
              {formData.photo && (
                <div className="itemcon">
                  <img src={URL.createObjectURL(formData.photo)} alt="Current Photo" />
                </div>
              )}
              <label htmlFor="file-input-edit" className="file-input-label">+ Add Image</label>
              <input
                type="file"
                id='file-input-edit'
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </div>
            <hr/>
            <div className="button-bar">
            <button type="submit" className='save-btn'>{loading ? 'Adding...' : 'Add Dish'}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddDish;