import React, { useState, useEffect, useCallback } from 'react';
import './HotelProfile.css';
import EditHotelProfile from './EditHotelProfile';

const HotelProfile = () => {
    const [hotelDetails, setHotelDetails] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const token = localStorage.getItem('hotelToken');
    const hotelId = localStorage.getItem('hotelId');

    const fetchprofile = useCallback(async () => {
        try {
            if (!token || !hotelId) {
                throw new Error('No token or hotelId found');
            }

            const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/hotel-details`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'hotelId': hotelId,
                },
            });

            if (!response.ok) {
                throw new Error(`Error fetching hotel details: ${response.status}`);
            }

            const data = await response.json();
            setHotelDetails(data);
        } catch (error) {
            console.error('Error fetching hotel details:', error);
        }
    }, [token, hotelId]);

    useEffect(() => {
        fetchprofile();
    }, [fetchprofile]);

    const formatTime = (timeString) => {
        if (!timeString) return '';

        const timeWithoutZ = timeString.slice(0, -1);
        const [hours, minutes] = timeWithoutZ.split(':').map(Number);

        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

        return `${formattedHours}:${formattedMinutes} ${ampm}`;
    };

    const truncateText = (text, maxLength) => {
        if (text != null) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    }
    };

    return (
        <div className='rooms-container' id='rooms-container'>
            <div className='bar2'>
                <h1>Hotel Profile</h1>
            </div>
            {hotelDetails ? (
                <div
                    className="hotel-details-container"
                    onClick={() => setShowEditModal(true)}
                >
                    <div className="hotel-details-item">
                        <div className="hotel-field">
                            <strong>Hotel Name:</strong> {truncateText(hotelDetails.name, 30)}
                        </div>
                        <div className="hotel-field">
                            <strong>Email:</strong> {truncateText(hotelDetails.email, 30)}
                        </div>
                        <div className="hotel-field">
                            <strong>Description:</strong> {truncateText(hotelDetails.description, 30)}
                        </div>
                        <div className="hotel-field">
                            <strong>Number of Rooms:</strong> {hotelDetails.numberOfRooms}
                        </div>
                        <div className="hotel-field">
                            <strong>Phone Number:</strong> {hotelDetails.phoneNumber}
                        </div>
                        <div className="hotel-field">
                            <strong>Website:</strong> {truncateText(hotelDetails.website, 30)}
                        </div>
                        <div className="hotel-field">
                            <strong>Address:</strong> {truncateText(hotelDetails.address, 30)}
                        </div>
                        <div className="hotel-field">
                            <strong>Link to Map:</strong> {truncateText(hotelDetails.linkToMap, 30)}
                        </div>
                        <div className="hotel-field">
                            <strong>Check-in Time:</strong> {formatTime(hotelDetails.checkInTime)}
                        </div>
                        <div className="hotel-field">
                            <strong>Check-out Time:</strong> {formatTime(hotelDetails.checkOutTime)}
                        </div>
                    </div>
                </div>
            ) : (
                <p>Loading hotel details...</p>
            )}

            {showEditModal && (
      <div className="modal" onClick={() => setShowEditModal(false)}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <EditHotelProfile 
            hotelDetails={hotelDetails} 
            onClose={() => setShowEditModal(false)} 
            setShowEditModal={setShowEditModal} 
            fetchprofile={fetchprofile}  // Передаем fetchprofile как пропс
          />
          <button onClick={() => setShowEditModal(false)}>Close</button>
        </div>
      </div>
    )}
        </div>
    );
};

export default HotelProfile;