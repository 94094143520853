import React, { useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ConversationList from './ConversationList';
import FocusedChat from './FocusedChat';
import './Chat.css'
import { useNotification } from './NotificationContext';

const Chat = () => {
    const { setConversations } = useNotification();
    const { guestId } = useParams();
    const navigate = useNavigate();

    const handleChatSelect = useCallback((chat) => {
        navigate(`/chat/${chat.id}`);
    }, [navigate]);

    const handleEscKey = useCallback((event) => {
        if (event.key === 'Escape') {
            navigate('/chat');
        }
    }, [navigate]);

    useEffect(() => {
        document.addEventListener('keydown', handleEscKey);
        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [handleEscKey]);

    return (
        <div className="chat-page">
            <ConversationList
                onChatSelect={handleChatSelect}
                selectedChatId={guestId}
            />
            {guestId && <FocusedChat guestId={guestId} />}
        </div>
    );
};

export default Chat;