import React from 'react'
import './Arrow.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const arrow = () => {
  const hotelName = localStorage.getItem('hotelName');
  return (
    <div className='arrow_container'>
      <div className='back-container'>
         <FontAwesomeIcon icon={faArrowLeft}  className='arrow'/>
         <p>Back</p>
         </div>
         <div className="title-container">
         <h2>{hotelName}</h2>
         </div>
         
    </div>
  )
}

export default arrow