import React, { useState, useEffect } from 'react';
import { Area,LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './Dashboard.css';

const Chart = ({ data }) => {
  const [selectedPeriod, setSelectedPeriod] = useState('daily');
  const [formattedData, setFormattedData] = useState([]);

  useEffect(() => {
    const chartData = data[selectedPeriod] || [];
    let formatted = [];

    switch (selectedPeriod) {
      case 'monthly':
        formatted = chartData.map((entry, index) => ({
          ...entry,
          date: (index + 1).toString().padStart(2, '0'), // Months "01", "02", ..., "12"
        }));
        break;
      case 'weekly':
        formatted = chartData.map((entry, index) => ({
          ...entry,
          date: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][index % 7],
        }));
        break;
      case 'yearly':
        formatted = chartData.map((entry) => ({
          ...entry,
          date: entry.date, // Years as they are
        }));
        break;
      default:
        formatted = chartData;
    }

    setFormattedData(formatted);
  }, [data, selectedPeriod]);

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  return (
    <div style={{ padding: '10px', borderRadius: '10px', backgroundColor: '#fff' }} >
      <div className="sales-details-container" width="100%">
  <h2>Sales Details</h2>
  <div className='chart-extra'></div>
  <div className="dropdown-container">
    <select onChange={handlePeriodChange} value={selectedPeriod}>
      <option value="daily">Daily</option>
      <option value="weekly">Weekly</option>
      <option value="monthly">Monthly</option>
      <option value="yearly">Yearly</option>
    </select>
  </div>
     </div>
      <svg width="0" height="0">
      <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#8884d8" stopOpacity={0.4} />
            <stop offset="100%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
        </defs>
      </svg>
      <ResponsiveContainer width="100%" height={350}>
        
        <LineChart data={formattedData}>
          <CartesianGrid strokeDasharray="3 3" stroke="#ccc"  vertical={false}/>
          <XAxis 
            dataKey="date" 
            stroke="#8884d8" 
            tick={{ fill: '#8884d8' }} 
          />
          <YAxis 
            stroke="#8884d8" 
            tick={{ fill: '#8884d8' }} 
          />
          <Tooltip 
            contentStyle={{ backgroundColor: '#f5f5f5', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}
            labelStyle={{ color: '#666' }}
            itemStyle={{ color: '#8884d8' }}
          />
          <Legend />
          <Area
            type="monotone"
            dataKey="sales"
            stroke="none"
            fill="url(#gradient)"
            fillOpacity={1}
          />
          <Line
            type="monotone"
            dataKey="sales"
            stroke="#8884d8"
            strokeWidth={2}
            dot={{ r: 6, strokeWidth: 2, stroke: '#8884d8', fill: '#fff' }}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
