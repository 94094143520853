import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const LoginGuest = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token) {
      axios.get(process.env.REACT_APP_API_BASE_URL + `/login-guest?token=${token}`)
        .then(response => {
          const guestData = response.data;
          localStorage.setItem('id', guestData.id);
          localStorage.setItem('hotelId', guestData.hotelId);
          localStorage.setItem('hotelName', guestData.hotelName);
          localStorage.setItem('room', guestData.room);
          localStorage.setItem('breakfast', guestData.breakfast);
          localStorage.setItem('guestsNumber', guestData.guestsNumber);
          console.log("Navigating to /home...");
          navigate('/home');
        })
        .catch(error => {
          console.error('Guest authentication failed', error);
          console.log("Navigating to /login...");
          navigate('/login');
        });
    } else {
      console.log("Token not found in URL, navigating to /login...");
      navigate('/login');
    }
  }, [location, navigate]);

  return <div>Loading...</div>;
};

export default LoginGuest;
