import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const TimePicker = ({ value, onChange, label }) => {
  const [selectedTime, setSelectedTime] = useState(value ? new Date('1970-01-01T' + value.slice(0, -1)) : null); // Изменено

  const handleTimeChange = (date) => {
    setSelectedTime(date);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}Z`;
    onChange(formattedTime);
  };

  return (
    <div className="room-input-container">
      <label className={value ? 'floating' : ''}>{label}</label>
      <DatePicker
        selected={selectedTime} 
        onChange={handleTimeChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
      />
    </div>
  );
};

export default TimePicker;