import React, { useState } from 'react';
import Swal from 'sweetalert2';
import './SerShow.css';
import checked from '../../Assets/All pics/checked_169780 1.png'

const BreakfastService = ({ service, onSave }) => {
  const [selectedBreakfastType, setSelectedBreakfastType] = useState('');
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedDrinks, setSelectedDrinks] = useState([]);

  const handleBreakfastTypeChange = (event) => {
    setSelectedBreakfastType(event.target.value);
    setSelectedItems({});
    setSelectedDrinks([]);
  };

  const handleItemChange = (section, item, max) => {
    setSelectedItems((prevSelectedItems) => {
      const sectionItems = prevSelectedItems[section] || [];
      const isAlreadySelected = sectionItems.includes(item);
        
      if (isAlreadySelected) {
        return {
          ...prevSelectedItems,
          [section]: sectionItems.filter(i => i !== item),
        };
      } else if (sectionItems.length < max) {
        return {
          ...prevSelectedItems,
          [section]: [...sectionItems, item],
        };
      } else {
        return prevSelectedItems;
      }
    });
  };


  const handleSave = () => {
    const selectedSections = Object.keys(selectedItems);
    const hasSelectedItems = selectedSections.some(section => selectedItems[section].length > 0);

    if (!hasSelectedItems) {
      Swal.fire({
        title: 'Error',
        text: 'Please select at least one item.',
        icon: 'error',
        confirmButtonText: 'Close'
      });
      return;
    }

    Swal.fire({
      title: 'Confirm Order',
      text: `Are you sure you want to order items from ${selectedBreakfastType} breakfast?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Confirm',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button'
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        onSave(service.id, { breakfastType: selectedBreakfastType, items: selectedItems });

        Swal.fire({
          title: 'Order Confirmed',
          html: `<div>Your order for ${selectedBreakfastType} breakfast has been confirmed!</div>`,
          confirmButtonText: 'Close',
          iconHtml: `<img src="${checked}" style="width: 60px; height: 60px; border: none; box-shadow: none; border-radius: 0;"/>`,
          customClass: {
            icon: 'custom-icon',
            confirmButton: 'custom-confirm-button'
          },
          buttonsStyling: false,
        }).then(() => {
          setSelectedBreakfastType('');
          setSelectedItems({});
        });
      }
    });
  };

  const isButtonDisabled = !selectedBreakfastType || !Object.keys(selectedItems).some(section => selectedItems[section].length > 0);

  const breakfastOptions = service.breakfastOptions.reduce((acc, curr) => {
    const key = Object.keys(curr)[0];
    acc[key] = curr[key];
    return acc;
  }, {});

  return (
    <div className='break-con'>
               
        <div className='break-box'>
      <h3 >{service.title}</h3>
      <div className='breakfast-box' >
       
        <label htmlFor="breakfastType"></label>
        <select
          id="breakfastType"
          value={selectedBreakfastType}
          onChange={handleBreakfastTypeChange}
        >
          <option value="" disabled>Select type</option>
          {Object.keys(breakfastOptions).map((type, index) => (
            <option key={index} value={type}>{type}</option>
          ))}
        </select>
        </div>
        </div>

        {!selectedBreakfastType && (
        <div className='default-content'>
          <p>Please select a breakfast type to see the options.</p>
          <p>All available breakfast options will be displayed here once you make a selection.</p>
          <h3>Please Order between 7am-10am </h3>
        </div>
      )}
      <div className='bed-box'> 
        {selectedBreakfastType && (
       <div className='breakfast-con'>
            {Object.keys(breakfastOptions[selectedBreakfastType]).map((section, index) => (
              <div key={index}>
                <label className='br-title'><h3>{section} </h3><p>(Optional, Max {breakfastOptions[selectedBreakfastType][section].max}) </p></label>
                <div className='laundry' >
                {breakfastOptions[selectedBreakfastType][section].items.map((item, idx) => (
                <div key={idx}>
                  <div className='lan-check' onClick={() => handleItemChange(section, item, breakfastOptions[selectedBreakfastType][section].max)}>
                    <input
                      type="checkbox"
                      checked={selectedItems[section] && selectedItems[section].includes(item)}
                      onChange={() => handleItemChange(section, item, breakfastOptions[selectedBreakfastType][section].max)}
                      onClick={(e) => e.stopPropagation()} // Prevent triggering the div's onClick
                    />
                    {item}
                    <p>0</p>
                                  
                      </div>
                      <hr className='inner-hr' />
                    </div>
                  ))}
             
                </div>
              </div>
            ))}
                <div className='extra'> </div>
          </div>
        )}
        <div className='overlay-lan'>
      <button
          className={`bed-button ${isButtonDisabled ? '' : 'active'}`}
          disabled={isButtonDisabled}
          onClick={handleSave}
        >
          Place Order
        </button>
        </div>
    </div>
    </div>
  );
};

export default BreakfastService;