import React, { useState } from 'react';

const AdminCleaningHours = ({ hoursData, onHoursChange, onClose }) => {
  const [hours, setHours] = useState(hoursData || []);

  const handleHoursChange = (e) => {
    const { value } = e.target;
    const newHours = value.split('\n').map(line => {
      const [label, price] = line.split(':');
      return { label: label ? label.trim() : '', price: price ? price.trim() : '' };
    });
    setHours(newHours);
    onHoursChange(newHours);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onHoursChange(hours);
  };

  return (
    <form onSubmit={handleFormSubmit} style={{ width: '100%' }}>
      <div className="room-input-container cap1">
        <textarea
           style={{ width: '97%' }}
          name="hours"
          value={hours.map(hour => `${hour.label}:${hour.price}`).join('\n')}
          onChange={handleHoursChange}
        />
        <label className={hours.length > 0 ? 'floating' : ''}>
          Hours and Prices
        </label>
      </div>
      <hr />
      <div className="button-bar">
        <button type="submit" className="save-btn">Save</button>
        <button type="button" onClick={onClose} className="delete-button">Cancel</button>
      </div>
    </form>
  );
};

export default AdminCleaningHours;
