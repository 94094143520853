import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './LoginRoom.css';

const LoginRoom = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [roomLoginData, setRoomLoginData] = useState(null);
  const [lastNameInput, setLastNameInput] = useState('');
  const [error, setError] = useState('');
  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const roomNumber = params.get('roomNumber');
    const hotelId = params.get('hotelId');

    const storedToken = localStorage.getItem(`roomToken-${roomNumber}`);

    if (storedToken) {
      navigate(`/login-guest?token=${storedToken}`);
    } else if (roomNumber && hotelId) {
      axios.get(process.env.REACT_APP_API_BASE_URL + `/login-room?hotelId=${hotelId}&roomNumber=${roomNumber}`)
        .then(response => {
          setRoomLoginData(response.data);
          // Start the intro animation
          setTimeout(() => {
            setShowIntro(false);
          }, 2800); // Show intro for 1 second
        })
        .catch(error => {
          console.error('Room authentication failed', error);
          setError('Room authentication failed. Please try again.');
        });
    } else {
      navigate('/login');
    }
  }, [location, navigate, process.env.REACT_APP_API_BASE_URL]);

  const handleLastNameSubmit = () => {
    if (!roomLoginData) {
      setError('Room login data is not available.');
      return;
    }

    if (lastNameInput.trim().toLowerCase() === roomLoginData.lastName.toLowerCase()) {
      axios.get(process.env.REACT_APP_API_BASE_URL + `/login-guest?token=${roomLoginData.guestToken}`)
        .then(response => {
          const guestData = response.data;
          localStorage.setItem(`roomToken-${guestData.room}`, roomLoginData.guestToken);
          localStorage.setItem('guestToken', guestData.guestToken);
          localStorage.setItem('firstName', guestData.firstName);
          localStorage.setItem('lastName', guestData.lastName);
          localStorage.setItem('id', guestData.id);
          localStorage.setItem('hotelId', guestData.hotelId);
          localStorage.setItem('hotelName', guestData.hotelName);
          localStorage.setItem('checkIn', guestData.checkIn);
          localStorage.setItem('checkOut', guestData.checkOut);
          localStorage.setItem('room', guestData.room);
          localStorage.setItem('breakfast', guestData.breakfast);
          localStorage.setItem('guestsNumber', guestData.guestsNumber);
          navigate('/home');
        })
        .catch(error => {
          console.error('Guest authentication failed', error);
          setError('Authentication failed. Please try again.');
        });
    } else {
      setError('Incorrect last name. Please try again.');
    }
  };

  if (!roomLoginData) {
    return <div className="loading">Loading...</div>;
  }

  const hiddenLastName = '*'.repeat(roomLoginData.lastName.length);

  return (
    <div className="login-room-container">
      <div className={`intro-section ${showIntro ? 'show' : 'hide'}`}>
        <div className="intro-content">
        <h1 className="luxury-text"> Welcome to {roomLoginData.hotelName}</h1>
        <p className="luxury-subtext">Experience comfort and style with us.</p>
        </div>
      </div>
      <div className={`welcome-container ${showIntro ? 'hide' : 'show'}`}>
      
          <h1 className="welcome-text">
            <span>Hi, {roomLoginData.firstName} {hiddenLastName}</span>
          </h1>
     
        <p className="security-text">For your security, please enter your last name to continue</p>
        <input
          type="text"
          value={lastNameInput}
          placeholder="Last Name"
          onChange={(e) => setLastNameInput(e.target.value)}
          className="lastname-input"
        />
        <button onClick={handleLastNameSubmit} className="continue-button">Continue</button>
        {error && <p className="error-text">{error}</p>}
      </div>
    </div>
  );
};

export default LoginRoom;
