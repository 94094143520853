import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import checked from '../../Assets/All pics/checked_169780 1.png';
import axios from 'axios';
import { useOrderContext } from '../../Cart/OrderContext';

const RoomCleaningService = ({ service, onSave, checkInDate, checkOutDate }) => {
  const { handlePlaceOrder } = useOrderContext();
  const [selectedHour, setSelectedHour] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [additionalRequests, setAdditionalRequests] = useState('');
  const [availableTimeOptions, setAvailableTimeOptions] = useState([]);
  const [selectedServices, setSelectedServices] = useState({
    cleaning: false,
    towel: false,
    linenChange: false,
  });

  const handleServiceChange = (event) => {
    const { name, checked } = event.target;
    setSelectedServices(prevServices => ({
      ...prevServices,
      [name]: checked,
    }));
  };

  const handleTimeChange = (event) => {
    setSelectedHour(event.target.value);
  };

  const handleSave = async () => {
    Swal.fire({
      title: 'Confirm Order',
      text: `Are you sure you want to place the order?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button',
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formattedDate = `${new Date().getFullYear()}/${selectedDate}`;
        const formattedTime = `${selectedHour}`;
        const timeToSend = `${formattedDate} ${formattedTime}`;

        const token = localStorage.getItem('guestToken');
        const hotelId = localStorage.getItem('hotelId');
        const guestId = localStorage.getItem('id');

        try {
          const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/order/place-service', {
            totalAmount: 0,
            totalQuantity: 1,
            orderStatus: "Pending",
            title: "Room Cleaning",
            additionalOptions: {
              time: timeToSend,
              cleaning: selectedServices.cleaning,
              towelChange: selectedServices.towel,
              linenChange: selectedServices.linenChange,
              additionalRequests: additionalRequests,
            }
          }, {
            headers: {
              'accept': '*/*',
              'hotelId': hotelId,
              'guestId': guestId,
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });

          if (response.status === 200) {
            handlePlaceOrder();
            Swal.fire({
              title: 'Order Placed',
              html: `<div>You can track the status of your order in the orders section</div>`,
              confirmButtonText: 'Close',
              iconHtml: `<img src="${checked}" style="width: 60px; height: 60px; border: none; box-shadow: none; border-radius: 0;"/>`,
              customClass: {
                icon: 'custom-icon',
                confirmButton: 'custom-confirm-button',
              },
              buttonsStyling: false,
            }).then(() => {
              setSelectedHour('');
              setSelectedDate('');
              setAdditionalRequests('');
              setSelectedServices({ cleaning: false, towel: false, linenChange: false });
            });
          } else {
            console.error('Error placing order:', response.status, response.statusText);
            Swal.fire({
              title: 'Error',
              text: 'Failed to place room cleaning order. Please try again later.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
        } catch (error) {
          console.error('Error placing order:', error);
          Swal.fire({
            title: 'Error',
            text: 'Failed to place room cleaning order. Please try again later.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      }
    });
  };

  const isButtonDisabled = selectedDate === '' || selectedHour === '' ||
    !selectedServices.cleaning &&
    !selectedServices.towel &&
    !selectedServices.linenChange;

  const generateTimeOptions = () => {
    const timeOptions = [];
    const [startHour, startMinute, startPeriod] = service.additionalOptions.serviceStartTime.split(/[:\s]/);
    const [endHour, endMinute, endPeriod] = service.additionalOptions.serviceEndTime.split(/[:\s]/);

    let currentHour = parseInt(startHour);
    let currentMinute = 0;
    let currentPeriod = startPeriod;

    const endTime = `${endHour.padStart(2, '0')}:${endMinute} ${endPeriod}`;

    while (true) {
      const formattedHour = currentHour % 12 || 12;
      const timeString = `${formattedHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')} ${currentPeriod}`;

      timeOptions.push(timeString);

      if (timeString === endTime) break;

      currentMinute += 30;
      if (currentMinute >= 60) {
        currentMinute = 0;
        currentHour++;
        if (currentHour > 12) {
          currentHour = 1;
          currentPeriod = currentPeriod === 'AM' ? 'PM' : 'AM';
        }
      }
    }

    return timeOptions;
  };

  const filterPastTimes = () => {
    const now = new Date();
    const today = now.toISOString().split('T')[0];

    if (selectedDate === today) {
      const filteredTimes = availableTimeOptions.filter(time => {
        const [hours, minutes, period] = time.split(/[:\s]/);
        const timeHour = parseInt(hours) + (period === 'PM' && parseInt(hours) !== 12 ? 12 : 0); 
        const timeMinutes = parseInt(minutes);

        return (timeHour > now.getHours() || (timeHour === now.getHours() && timeMinutes >= now.getMinutes()));
      });
      return filteredTimes;
    } else {
      return availableTimeOptions;
    }
  };

  useEffect(() => {
    setAvailableTimeOptions(generateTimeOptions());
  }, [service]); 

  const today = new Date().toISOString().split('T')[0];
  const filteredTimeOptions = filterPastTimes(); 

  return (
    <div className='lan'>
      <div>
        <h3 className='title-box'>{service.name}</h3>
        <p className='lan-p'>{service.description}</p>
      </div>
      <div className='date-picker time-picker2'>
        <label htmlFor='date'> Date:</label>
        <input
          type='date'
          id='date'
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          className='bed-con'
          min={today}
        />
      </div>

      <div className=' date-picker  time-picker2'>
        <label htmlFor='time'> Time:</label>
        <select
          id='time'
          value={selectedHour}
          onChange={handleTimeChange}
          className='bed-con'
          disabled={selectedDate === ''}
        >
          <option value="">Select Time</option>
          {filteredTimeOptions.map((time) => ( 
            <option key={time} value={time}>
              {time}
            </option>
          ))}
        </select>
      </div>

      <div className='laundry'>
        <h4>Services</h4>
        <div className="lan-check">
          <input
            type='checkbox'
            name='cleaning'
            checked={selectedServices.cleaning}
            onChange={handleServiceChange}
          />
          <label>
            Cleaning
          </label>
          <p></p>
        </div>
        <hr className="inner-hr" />
        <div className="lan-check">
          <input
            type='checkbox'
            name='towel'
            checked={selectedServices.towel}
            onChange={handleServiceChange}
          />
          <label>
            Towel Change
          </label>
          <p></p>
        </div>
        <hr className="inner-hr" />
        <div className="lan-check">
          <input
            type='checkbox'
            name='linenChange'
            checked={selectedServices.linenChange}
            onChange={handleServiceChange}
          />
          <label>
            Bed Linen Change
          </label>
          <p></p>
        </div>
        <hr className="inner-hr" />
      </div>

      <div className='additional-notes'>
        <label htmlFor='requests'>Additional Requests:</label>
        <textarea
          id='requests'
          value={additionalRequests}
          onChange={(e) => setAdditionalRequests(e.target.value)}
          placeholder='Any additional requests...'
        />
      </div>

      <button
        className={`bed-button ${isButtonDisabled ? '' : 'active'}`}
        disabled={isButtonDisabled}
        onClick={handleSave}
      >
        Place Order
      </button>
    </div>
  );
};

export default RoomCleaningService;
