import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Arrow from '../../ArrowB/Arrow.jsx';
import { Link } from 'react-router-dom';
import './Facilities.css';
import BarA from '../BarA.jsx';

const Facilities = () => {
  const [amenities, setAmenities] = useState([]);
  const [loading, setLoading] = useState(true);
  const hasFetched = useRef(false); // Ref to control the first fetch

  useEffect(() => {
    const fetchAmenities = async () => {
      const token = localStorage.getItem('guestToken');
      const hotelId = localStorage.getItem('hotelId');
      try {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/amenity/list`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'hotelId': hotelId
          },
          params: {
            tag: 'guest',
            page: 0,
            size: 50
          }
        });
        setAmenities(response.data);
      } catch (error) {
        console.error('Error fetching amenities', error);
      } finally {
        setLoading(false);
      }
    };

    if (!hasFetched.current) { // Check if fetch has already been done
      fetchAmenities();
      hasFetched.current = true; // Mark fetch as done
    }
  }, []); // Empty dependency array to run only once

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Link to='/home' style={{ textDecoration: 'none', color: 'var(--primaryColor)' }}>
        <Arrow />
      </Link>
      <div className='fac'>
      <div className='faci-grid'>
        {amenities.map((item) => (
          <div key={item.id} className='faci-item'>
            <div className='faci-img'>
            <img src={item.imageLink} alt={item.title}  />
            </div>
            <div className='faci'>
              <h5>{item.title}</h5>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
         <div className='extra-big'></div>
        </div>  
       
      </div>
    </div>
  );
};

export default Facilities;
