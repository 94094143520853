import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hotelId, setHotelId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const guestToken = localStorage.getItem('guestToken');
    const hotelToken = localStorage.getItem('hotelToken');
    const storedHotelId = localStorage.getItem('hotelId');

    if ((guestToken || hotelToken) && storedHotelId) {
      setIsLoggedIn(true);
      setHotelId(storedHotelId);
    }
    setLoading(false);
  }, []);

  const login = (guestToken, hotelToken, hotelId) => {
    console.log("Saving token and hotelId to localStorage...");
    localStorage.setItem('guestToken', guestToken);
    localStorage.setItem('hotelToken', hotelToken);
    localStorage.setItem('hotelId', hotelId);
    console.log("Token set in localStorage: ", guestToken);
    console.log("HotelId set in localStorage: ", hotelId);
    setIsLoggedIn(true);
    setHotelId(hotelId);
  };

  const logout = () => {
    localStorage.removeItem('guestToken');
    localStorage.removeItem('hotelToken');
    localStorage.removeItem('hotelId');
    localStorage.removeItem('name');
    localStorage.removeItem('linkToMap');
    localStorage.removeItem('numberOfRooms');
    localStorage.removeItem('description');
    localStorage.removeItem('phoneNumber');
    localStorage.removeItem('email');
    localStorage.removeItem('website');
    setIsLoggedIn(false);
    setHotelId(null);
    navigate('/authorize');
  };

  const isAuthenticated = () => {
    const guestToken = localStorage.getItem('guestToken');
    const hotelToken = localStorage.getItem('hotelToken');
    const hotelId = localStorage.getItem('hotelId');
    console.log("Checking if authenticated...");
    return !!((guestToken || hotelToken) && hotelId);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, loading, login, logout, hotelId, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
