import img1 from '../Assets/All pics/Cleaning.png'
import img2 from '../Assets/All pics/Cleaner.png'
import img3 from '../Assets/All pics/Laundry.png'
import img4 from '../Assets/All pics/Breakfast.png'
import img5 from '../Assets/All pics/Spa.png'
import img6 from '../Assets/All pics/alarm.png'
const Service =[
  {
    id: '1',
    category:'bed',
    img:img1,
    des:'Your clothes, our care. Prompt service, premium quality. Fresh, clean garments delivered to your door with a smile.',
    type: '1',
    status: 'ON',
    title:'Chaining BedSheets/Towel ',
    notice:'',
    items: [
        { id: 'bedSheets', name: "BedSheets" },
        { id: 'towel', name: "Towel" },
        // Add more cleaning items here
      ]
    },
    {
    id: '2',
    category: 'clean',
    type: '2',
    img:img2,
    des:'Your clothes, our care. Prompt service, premium quality. Fresh, clean garments delivered to your door with a smile.',
    status: 'ON',
    notice:'please choose depend on your time',
    title:'Cleaning Service',
    hours: [
      { label: 'Select an hour', value: '', disabled: true },
      { label: 'Free Of Charge', disabled: true },
      { label: 'In 15 minutes', value: '15min' },
      { label: '8:00 AM - 8:59 AM', value: '08:00' },
      { label: '9:00 AM - 9:59 AM', value: '09:00' },
      { label: '10:00 AM - 10:59 AM', value: '10:00' },
      { label: '11:00 AM - 11:59 AM', value: '11:00' },
      { label: '12:00 PM - 12:59 PM', value: '12:00' },
      { label: '1:00 PM - 1:59 PM', value: '13:00' },
      { label: 'Payment Required', disabled: true },
      { label: '2:00 PM - 2:59 PM', value: '14:00' },
      { label: '3:00 PM - 3:59 PM', value: '15:00' },
      { label: '4:00 PM - 4:59 PM', value: '16:00' },
      { label: '5:00 PM - 5:59 PM', value: '17:00' },
      { label: '6:00 PM - 6:59 PM', value: '18:00' },
      { label: '7:00 PM - 7:59 PM', value: '19:00' },
      { label: '8:00 PM - 8:59 PM', value: '20:00' },
      { label: '9:00 PM - 10:00 PM', value: '21:00' },
    ],
  },
  {
    id: '3',
    category:'laundry',
    img:img3,
    notice:'please chose both of Types and Guess weights',
    type: '3',
    des:'Your clothes, our care. Prompt service, premium quality. Fresh, clean garments delivered to your door with a smile.',
    status: 'ON',
    title:'Laundry Service',
    types: [
      { label: 'Shirts', value: 'shirts' },
      { label: 'Pants', value: 'pants' },
      { label: 'Towels', value: 'towels' },
      { label: 'Children Clothes', value: 'child' },
      { label: 'Underwear', value: 'underwear' },
      { label: 'Shoe Dry Wash', value: 'shoe' },
      { label: 'Others..', value: 'others' },
    ],
    weights: [
      { label: 'Less 1kg', value: '1' ,price:'3'},
      { label: '1kg-2kg', value: '2',price:'5' },
      { label: '2kg - 4kg', value: '3',price:'7' },
      { label: 'More than 4kg', value: '4',price:'10' }
    ]
    },
    {
      id: '4',
      category:'breakfast',
      img:img4,
      type: '4',
      notice:'This Service is between 7am to 10:30 am',
      status: 'ON',
      des:'Your clothes, our care. Prompt service, premium quality. Fresh, clean garments delivered to your door with a smile.',
      title:'Breakfast Room Dining',
      breakfastOptions: [
        { 
          American: {
            Drinks: { items: ['Coffee', 'Orange Juice', 'Milk'], max: 2 },
            Breads: { items: ['Toast', 'Bagel', 'Muffin'], max: 3 },
            Proteins: { items: ['Eggs', 'Bacon', 'Sausage'], max: 1 },
            Fruits: { items: ['Apple', 'Banana', 'Grapes'], max: 1 }
          }
        },
        { 
          Continental: {
            Drinks: { items: ['Tea', 'Orange Juice', 'Water'], max: 2 },
            Breads: { items: ['Croissant', 'Baguette', 'Pastry'], max: 3 },
            Fruits: { items: ['Apple', 'Banana', 'Grapes'], max: 1 }
          }
        },
        { 
          European: {
            Drinks: { items: ['Green Tea', 'Soy Milk', 'Juice'], max: 2 },
            Breads: { items: ['Steamed Bun', 'Rice Cake', 'Naan'], max: 3 },
            MainDishes: { items: ['Congee', 'Noodles', 'Dim Sum'], max: 1 },
            Fruits: { items: ['Apple', 'Banana', 'Grapes'], max: 1 },

          }
        },
        { 
          Asian: {
            Drinks: { items: ['Green Tea', 'Soy Milk', 'Juice'], max: 2 },
            Breads: { items: ['Steamed Bun', 'Rice Cake', 'Naan'], max: 3 },
            MainDishes: { items: ['Congee', 'Noodles', 'Dim Sum'], max: 1 },
            Fruits: { items: ['Apple', 'Banana', 'Grapes'], max: 1 },

          }
        }
      ]
      },
      {
        id: '5',
        category:'spa',
        notice:'Spa opens at 9 AM and closes at 9 PM.',
        img:img5,
        des:'Your clothes, our care. Prompt service, premium quality. Fresh, clean garments delivered to your door with a smile.',
        status: 'ON',
        type: '5',
        title:'Spa Booking',
        
        },
        {
          id: '6',
          category:'wake-up',
          img:img6,
          notice:'please Submit one at the moment of you choose',
          status: 'ON',
          des:'Your clothes, our care. Prompt service, premium quality. Fresh, clean garments delivered to your door with a smile.',
          type: '6',
          title:'Wake up call',
         
          },
]
export default Service;