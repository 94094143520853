import React from 'react'
import { useCart } from './CartContext'
import './CartItemBtn.css'
import { Link ,useNavigate,useLocation} from 'react-router-dom'
const CartItemsBtn = () => {
const { getTotalItems, getTotalAmount} = useCart();
  const totalCart = getTotalItems();
  const itemCountText = totalCart === 1 ? 'item' : 'items';
  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = () => {
    navigate('/cart');
  }
    // List of paths where you want to display the cart information
    const allowedPaths = ['/basket','/appetizer', '/fast-food', '/drinks', '/alcohol', '/main-dishes', '/salads', '/desserts', '/soups', '/all','/search'];

    // Check if the current path is in the allowedPaths array
    const isAllowedPath = allowedPaths.includes(location.pathname);

    return (
       
        <div className={`cart-items-btn ${isAllowedPath && totalCart > 0 ? 'show' : 'hide'}`}>
           <Link to='/cart' className='cart-link'  style={{textDecoration:'none'}} onClick={handleClick} >
           {totalCart > 0 && (
           <div className='item-show'>
            <p>Cart {totalCart} {itemCountText}</p>
            <p>${getTotalAmount()}</p></div>
            )}
           </Link>
        </div>
       
  );
}

export default CartItemsBtn;