import React from 'react';

const Sidebar = () => (
  <div className="sidebar">
    <div className="san">
      <strong>Sansara</strong>
    </div>
    <div className="all">
      <h1>All Hotel Services in One Place</h1>
    </div>
  </div>
);

export default Sidebar;