import React, { forwardRef } from 'react';
import './ActivityDetail.css'
const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <button
    type="button"
    className="custom-date-input"
    onClick={onClick}
    ref={ref}
  >
    {value || "Choose Date"}
  </button>
));

export default CustomInput;
