import React, { useState } from 'react';
import './MainAdmin.css';
import Qr from './qr-code 1.png';
import AddRoom from '../../AdminLayout/AddRoom';

const MainAdmin = () => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const handleAddRoomClick = () => {
    setIsAddModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsAddModalVisible(false);
  };

  const handleAddRoomSubmit = (roomData) => {
    // Handle adding a single room
    console.log('Single room added:', roomData);
    handleCloseModal();
  };

  const handleAddMultipleRoomsSubmit = (roomsData) => {
    // Handle adding multiple rooms
    console.log('Multiple rooms added:', roomsData);
    handleCloseModal();
  };

  return (
    
    <div className='container-qr'>
      <div className='admin-qr'>
        <div className='admin-qr1'>
          <h1> Let's create a QR code for room</h1>
          <button onClick={handleAddRoomClick}>
            <span>+</span> Add new room
          </button>
          <p>After you have created it, print the QR code and let the guest scan it to access the web app</p>
        </div>
      </div>

      
      <div className='main-qr'>
        <div className='main-qr1'>
          <img src={Qr} alt='/' />
          <p>Scan this QR code and see how your hotel app looks (this QR code is only for hotel staff)</p>
        </div>
      </div>

      {isAddModalVisible && (
            
            <AddRoom
              onClose={handleCloseModal}
              onAddRoom={handleAddRoomSubmit}
              onAddMultipleRooms={handleAddMultipleRoomsSubmit}
            />
          )}
    </div>
  );
};

export default MainAdmin;
