import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import './EditHotelProfile.css';
import TimePicker from './TimePicker';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';

const EditHotelProfile = ({ hotelDetails, onClose, setShowEditModal, fetchprofile }) => {
    const token = localStorage.getItem('hotelToken');
    const hotelId = localStorage.getItem('hotelId');
    const email = localStorage.getItem('email');
    const initialClickElement = useRef(null);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [changePasswordResponse, setChangePasswordResponse] = useState(null);
    const [showResponseModal, setShowResponseModal] = useState(false);
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

    const [updatedHotelData, setUpdatedHotelData] = useState({
        id: hotelDetails.id,
        name: hotelDetails.name,
        linkToMap: hotelDetails.linkToMap,
        numberOfRooms: hotelDetails.numberOfRooms,
        description: hotelDetails.description,
        phoneNumber: hotelDetails.phoneNumber,
        email: hotelDetails.email,
        website: hotelDetails.website,
        address: hotelDetails.address,
        checkInTime: hotelDetails.checkInTime,
        checkOutTime: hotelDetails.checkOutTime,
    });

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            await axios.put(process.env.REACT_APP_API_BASE_URL + '/hotel/edit', updatedHotelData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            console.log('Hotel info updated successfully');
            setShowEditModal(false);
            fetchprofile();
        } catch (error) {
            console.error('Error updating hotel info:', error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUpdatedHotelData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleTimeChange = (time, fieldName) => {
        setUpdatedHotelData((prevData) => ({
            ...prevData,
            [fieldName]: time,
        }));
    };

    const handleBackgroundMouseDown = (e) => {
        initialClickElement.current = e.target;
    };

    const handleBackgroundMouseUp = (e) => {
        if (e.target.className === 'main-room' && initialClickElement.current === e.target) {
            onClose();
        }
        initialClickElement.current = null;
    };

    const handleChangePassword = async (event) => {
        event.preventDefault();

        if (newPassword !== confirmNewPassword) {
            setPasswordsMatch(false);
            return;
        }

        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/hotel/change-password', {
                currentPassword: currentPassword,
                newPassword: newPassword,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    hotelId: hotelId,
                },
            });

            // Устанавливаем ответ от бэкенда в состояние
            setChangePasswordResponse(response.data);
        } catch (error) {
            console.error('Error changing password:', error);
            setChangePasswordResponse('Your current password does not matches with the password you provided. Please try again.');
        }
    };

    const handleCurrentPasswordChange = (event) => {
        setCurrentPassword(event.target.value);
    };

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmNewPasswordChange = (event) => {
        setConfirmNewPassword(event.target.value);
        setPasswordsMatch(event.target.value === newPassword);
    };

    const closeResponseModal = () => {
        setShowResponseModal(false);
        setChangePasswordResponse(null);

        // Если код ответа 200 (OK), закрываем все модальные окна
        if (changePasswordResponse === "Your password has been successfully changed") {
            setShowChangePasswordModal(false);
            setShowEditModal(false);
        }
    };

    useEffect(() => {
        // Если есть ответ от бэкенда, показываем модальное окно
        if (changePasswordResponse !== null) {
            setShowResponseModal(true);
        }
    }, [changePasswordResponse]);

    const handleForgotPassword = async () => {
        try {
          await axios.post(process.env.REACT_APP_API_BASE_URL + `/hotel/forgot-password?email=${email}`,);
          setChangePasswordResponse('Password reset email sent successfully!');
        } catch (error) {
          console.error('Error sending password reset email:', error);
          setChangePasswordResponse('Error sending password reset email. Please try again.');
        }
      };

    return (
        <div className="main-room" onMouseDown={handleBackgroundMouseDown} onMouseUp={handleBackgroundMouseUp}>
            {!showChangePasswordModal && (
                <div className="add-room-options">
                    <div className='added-box'>
                        <h3>Edit Hotel Profile</h3>
                        <button className="close" onClick={() => setShowEditModal(false)}>×</button>
                    </div>
                    <div className="room-form edit" style={{ paddingTop: '10px' }}>
                        <form onSubmit={handleSubmit}>
                            <div className='room-input-container'>
                                <input
                                    type="text"
                                    name="name"
                                    defaultValue={updatedHotelData.name}
                                    onChange={handleInputChange}
                                />
                                <label className={updatedHotelData.name ? 'floating' : ''}>Hotel Name</label>
                            </div>
                            <div className='room-input-container'>
                                <input
                                    type="email"
                                    name="email"
                                    defaultValue={updatedHotelData.email}
                                    onChange={handleInputChange}
                                />
                                <label className={updatedHotelData.email ? 'floating' : ''}>Email</label>
                            </div>
                            <div className='room-input-container'>
                                <textarea
                                    name="description"
                                    defaultValue={updatedHotelData.description}
                                    onChange={handleInputChange}
                                />
                                <label className={updatedHotelData.description ? 'floating' : ''}>Description</label>
                            </div>
                            <div className='room-input-container'>
                                <input
                                    type="text"
                                    name="linkToMap"
                                    defaultValue={updatedHotelData.linkToMap}
                                    onChange={handleInputChange}
                                />
                                <label className={updatedHotelData.linkToMap ? 'floating' : ''}>Link to Map</label>
                            </div>
                            <div className='room-input-container'>
                                <input
                                    type="text"
                                    name="address"
                                    defaultValue={updatedHotelData.address}
                                    onChange={handleInputChange}
                                />
                                <label className={updatedHotelData.address ? 'floating' : ''}>Address</label>
                            </div>
                            <div className='room-input-container'>
                                <input
                                    type="number"
                                    name="numberOfRooms"
                                    defaultValue={updatedHotelData.numberOfRooms}
                                    onChange={handleInputChange}
                                />
                                <label className={updatedHotelData.numberOfRooms ? 'floating' : ''}>Number of Rooms</label>
                            </div>
                            <div className='room-input-container'>
                                <input
                                    type="tel"
                                    name="phoneNumber"
                                    defaultValue={updatedHotelData.phoneNumber}
                                    onChange={handleInputChange}
                                />
                                <label className={hotelDetails.phoneNumber ? 'floating' : ''}>Phone Number</label>
                            </div>
                            <div className='room-input-container'>
                                <input
                                    type="text"
                                    name="website"
                                    defaultValue={updatedHotelData.website}
                                    onChange={handleInputChange}
                                />
                                <label className={updatedHotelData.website ? 'floating' : ''}>Website</label>
                            </div>

                            <div className='m2 room-multiple'>
                                <TimePicker
                                    value={updatedHotelData.checkInTime}
                                    onChange={(time) => handleTimeChange(time, 'checkInTime')}
                                    label="Check-in Time"
                                />
                                <TimePicker
                                    value={updatedHotelData.checkOutTime}
                                    onChange={(time) => handleTimeChange(time, 'checkOutTime')}
                                    label="Check-out Time"
                                />
                            </div>

                            <button type="button" className='change-password-btn' onClick={() => setShowChangePasswordModal(true)}>
                                Change Password
                            </button>
                            <hr />
                            <div className='button-bar'>
                                <button type="submit" className='save-btn'>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {showChangePasswordModal && (
                <div className="add-room-options">
                    <div className='added-box'>
                        <h3>Change Password</h3>
                        <button className="close" onClick={() => setShowChangePasswordModal(false)}>×</button>
                    </div>
                    <div className="room-form edit" style={{ paddingTop: '10px' }}>
                        <form onSubmit={handleChangePassword}>
                            <div className='room-input-container'>
                                <input
                                    type={showCurrentPassword ? 'text' : 'password'}
                                    name="currentPassword"
                                    value={currentPassword}
                                    onChange={handleCurrentPasswordChange}
                                    required
                                />
                                <label className={currentPassword ? 'floating' : ''}>Current Password</label>
                                <span className="password-toggle-icon" onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                                    {showNewPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                                </span>
                            </div>

                            <div className='room-input-container'>
                                <input
                                    type={showNewPassword ? 'text' : 'password'}
                                    name="newPassword"
                                    value={newPassword}
                                    onChange={handleNewPasswordChange}
                                    required
                                />
                                <label className={newPassword ? 'floating' : ''}>New Password</label>
                                <span className="password-toggle-icon" onClick={() => setShowNewPassword(!showNewPassword)}>
                                    {showNewPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                                </span>
                            </div>

                            <div className='room-input-container'>
                                <input
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    name="confirmNewPassword"
                                    value={confirmNewPassword}
                                    onChange={handleConfirmNewPasswordChange}
                                    required
                                />
                                <label className={confirmNewPassword ? 'floating' : ''}>Confirm New Password</label>
                                <span className="password-toggle-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                    {showConfirmPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                                </span>
                            </div>

                            {!passwordsMatch && (
                                <div className="error-message">Passwords do not match!</div>
                            )}

                            {/* Выводим ответ от бэкенда, если он есть */}
                            {showResponseModal && (
                                <div className="modal">
                                    <div className="modal-content">
                                        <p>{changePasswordResponse}</p>
                                        <button onClick={closeResponseModal}>Close</button>
                                    </div>
                                </div>
                            )}

                            <div className="forgot-password-link">
                                <a href="#" onClick={() => setShowForgotPasswordModal(true)}>Forgot password?</a>
                            </div>

                            {showForgotPasswordModal && (
                                <div className="modal">
                                    <div className="modal-content">
                                        <p>Your password will be reset, and a new password will be sent to your email - <b>{email}</b>. Do you confirm this action?</p>
                                        <div className='button-bar'>
                                            <button className='not-btn' onClick={() => setShowForgotPasswordModal(false)}>No</button>
                                            <button className='yes-btn' onClick={() => { setShowForgotPasswordModal(false); handleForgotPassword(); }}>Yes</button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <hr />
                            <div className='button-bar'>
                                <button type="submit" className='confirm-change-password-btn' disabled={newPassword !== confirmNewPassword}>
                                    Change Password
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EditHotelProfile;