import React, { useState, useEffect, useRef } from 'react';

const CategoryDropdown = ({ categories, selectedCategory, setSelectedCategory }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Close dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen); // Toggle the isOpen state
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setIsOpen(false); // Close the dropdown after selection
  };

  return (
    <div className={`category-dropdown-container ${isOpen ? 'open' : ''}`} ref={dropdownRef}>
      <div className="dropdown-header" onClick={toggleDropdown}>
        <span>
          {selectedCategory
            ? selectedCategory.charAt(0).toUpperCase() + selectedCategory.slice(1).toLowerCase()
            : 'All Categories'}
        </span>
        <span className='dropdown-icon'>▼</span>
      </div>
      {isOpen && (
        <div className="dropdown-menu">
          {categories.map((category, index) => (
            <div key={index} onClick={() => handleCategorySelect(category)}>
              {category.charAt(0).toUpperCase() + category.slice(1).toLowerCase()}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CategoryDropdown;
